import * as React from 'react'
import { ElementType } from '../../../interfaces'

export interface IOmniElementSpacerProps {
  height: string
}
export interface IProps {
  data: IOmniElementSpacerProps
}

const IOmniElementSpacer = (props: IProps) => {
  return (
    <div
      style={{
        height: props.data.height ?? '15px',
      }}
    ></div>
  )
}

export default {
  type: ElementType.SPACER,
  component: IOmniElementSpacer,
}
