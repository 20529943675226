import * as React from 'react'
import { IOption } from '../../interfaces'
import { IPageComponentPlugin, IPluginRenderProps } from '../FrontendPageComponentPluginsInterface'
import bootstrapCss from '../../../Common/Layout/Layout.module.scss'
import { PAGE_COMPONENT_PLUGIN_TYPE } from '../FrontendPageComponentPluginTypes'

/**
 * we are going to generate class names dynamically depending on what breakpoints are set
 * hidden-sm, hidden-md, hidden-lg, hidden-xs, visible-xs, visible-sm, visible-md, visible-lg
 * @param visibilityBreakpoint
 */
export const applyVisibilityBreakpointClasses = (visibilityBreakpoint: IOption, editor?: boolean): string => {
  const visibilityBreakpointClasses: string = visibilityBreakpoint !== undefined ? visibilityBreakpoint.value : ''
  if (visibilityBreakpointClasses) {
    const items: string[] = visibilityBreakpointClasses.split(' ')
    if (editor) {
      return ''
    }
    const classes: string[] = items.map((s: string) => {
      return bootstrapCss[`${s}${editor ? '-editor' : ''}`]
    })
    return classes.join(' ')
  }
  return ''
}

const renderPluginWrapperHook = (props: IPluginRenderProps): JSX.Element => {
  const wrapperClass: string = applyVisibilityBreakpointClasses(
    props.componentData.visibilityBreakpoint,
    props.contextProps?.studioEditor ?? false,
  )
  if (wrapperClass) {
    return <div className={`${wrapperClass} visibility-plugin-wrapper`}>{props.children}</div>
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

const plugin: IPageComponentPlugin = {
  enabled: true,
  type: PAGE_COMPONENT_PLUGIN_TYPE.VISIBILITY_BREAKPOINT_PLUGIN,
  hooks: {
    renderPluginWrapperHook,
  },
}

export default plugin
