import * as React from 'react'

import { ISlideProps } from './interfaces'
import css from './Slide.module.scss'

const Slide = ({ show, children }: ISlideProps) => (
  <div className={`${css.slide} ${css.fade} ${show ? css.shown : css.hidden}`}>{children}</div>
)

export default Slide
