import * as React from 'react'
import { ElementType } from '../../../interfaces'
import { IImageTransformationProps } from '../../../Plugins/ImageTransformation/imageTransformationInterface'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import VerticalCarousel, { ISliderItem } from './VerticalCarousel/VerticalCarousel'

export interface IImageGalleryItem extends ISliderItem {
  id: string
  content: {
    html: string
    css: string
  }
}
export interface IOmniComponentImageGalleryProps extends IImageTransformationProps {
  imagegalleryelements: IImageGalleryItem[]
  thumbnailWidth?: string
  thumbnailHeight?: string
  thumbnailSizing?: 'cover' | 'contain' | 'initial'
  maxMainImageWidth?: string
  thumbnailRightMargin?: string
  thumbnailSpacing?: string
  horizontal?: boolean
}

export interface IImageGalleryProps {
  data: IOmniComponentImageGalleryProps
  contextProps: IOmniPageSharedContextGlobalProps
  screenMode?: string
}

class ImageGallery extends React.PureComponent<IImageGalleryProps> {
  // public goToImage = (index: number) => {
  //   this.setState({
  //     currentImage: index,
  //   })
  // }
  public render() {
    const { data } = this.props
    if (!data) {
      return <div>Loading...</div>
    }
    return (
      <React.Fragment>
        <VerticalCarousel
          sliderData={data.imagegalleryelements}
          data={this.props.data}
          contextProps={this.props.contextProps}
          screenMode={this.props.screenMode || ''}
        />
      </React.Fragment>
    )
  }
}

export default {
  type: ElementType.IMAGE_GALLERY,
  component: ImageGallery,
}
