import * as React from 'react'
import MerceGrid from '../../Common/Grid/Grid'
import { ICatalogConfig } from '../Catalog'
import css from './CategoryThumbnails.scss'
import { ICategory } from '..'

export interface ICategoryThumbnailsContainerProps {
  categories: ICategory[]
  configuration: ICatalogConfig
  columns?: string
  customRenderHook?: string
}

const CategoryThumbnails = (props: ICategoryThumbnailsContainerProps) => {
  const emptyClick = () => {
    // do nothing for now
  }
  const renderThumbnail = (item: any) => {
    const category: ICategory = item as ICategory
    const click: any =
      props.configuration.events && props.configuration.events.goToCategory
        ? props.configuration.events.goToCategory
        : emptyClick
    return (
      <React.Fragment>
        <div className={`${css.container} fadeIn`} onClick={click.bind(null, category)}>
          <img className={css.articleImage} src={category.thumbnail || 'https://via.placeholder.com/300x200'} alt=" " />
        </div>
        <p>{category.title}</p>
      </React.Fragment>
    )
  }
  const {
    customRenderHook,
    configuration: { renderProps },
  } = props
  const customMethod = renderProps && customRenderHook ? (renderProps as any)[customRenderHook] : undefined

  return (
    <MerceGrid
      items={props.categories}
      columns={props.columns || '3'}
      renderProps={{
        renderThumbnail: customMethod ? item => customMethod({ item, props }) : renderThumbnail,
      }}
    />
  )
}

export default CategoryThumbnails
