import * as React from 'react'
import MobileSlide from './MobileSlide'
import { ISlideShowElement, ISlideShowProps } from './SlideShow'
import css from './SlideShowV2.module.scss'

interface ISlideShowState {
  currentSlide: number
  containerWidth: number
  movement: any
  transitionDuration: string
  containerHeight: any
  allowTouchEnd: boolean
  slideInterval: any
}
class MobileSlideShow extends React.PureComponent<ISlideShowProps, ISlideShowState> {
  private transitionTimeout: any
  private slides: any = this.props.data.slideshowelements
  private isSwipeable: boolean = this.slides.length > 1
  private currentSlideRef: any = null
  private horizontalTouch: number = 0
  private verticalTouch: number = 0
  private lastTouch: number = 0
  private interval = this.props.data.interval
  private ref = React.createRef()

  constructor(props: ISlideShowProps) {
    super(props)
    this.state = {
      currentSlide: 0,
      containerWidth: 0,
      containerHeight: 0,
      movement: null,
      transitionDuration: '0s',
      allowTouchEnd: true,
      slideInterval: null,
    }
  }

  /**
   * On mount all we need to do is initialize the slideInerval and set the resize listener
   */
  public componentDidMount() {
    this.setSlideInterval()
    /**
     * The resize listener is required for when a user rotates their mobile device.
     *
     * The containerWidth & height are dependant on the slide ref. The ref will
     * have updated but the state needs to change to reflect that.
     */
    if (this.isSwipeable) {
      window.addEventListener('touchstart', this.touchStart, { passive: false })
      window.addEventListener('touchmove', this.preventTouch, { passive: false })
    }

    window.addEventListener('resize', this.handleResize.bind(this))
  }

  /**
   * Cleanup interval and resize listener
   */
  public componentWillUnmount = () => {
    clearTimeout(this.transitionTimeout)
    clearInterval(this.state.slideInterval)
    window.removeEventListener('resize', this.handleResize, false)
    window.removeEventListener('touchmove', this.preventTouch)
  }

  // ----------------------------------------------  HELPER METHODS ------------------------------------------

  /**
   * A method for grabbing the image width & height on load - used to set the containerWidth and
   * containerHeight state variables.
   * @param ev - Image load event
   */
  private handleImageLoad = (ev: any) => {
    this.setState({
      containerWidth: ev.target.getBoundingClientRect().width,
      containerHeight: ev.target.getBoundingClientRect().height,
      movement: this.isSwipeable ? ev.target.getBoundingClientRect().width : 0,
    })
  }

  /**
   * As the slides transition, either manually or via the interval, this
   * method updates the state with the new containerWidth & height values.
   * @param slideRef A ref to the currently displayed slide
   */
  private setSlideRef = (slideRef: any) => {
    this.currentSlideRef = slideRef
    if (slideRef) {
      this.setState({
        containerWidth: slideRef?.getBoundingClientRect()?.width,
        containerHeight: slideRef?.getBoundingClientRect()?.height,
      })
    }
  }

  /**
   * Method to set interval for slide transitions.
   */
  private setSlideInterval = () => {
    if (this.interval !== undefined && this.interval > 0 && this.isSwipeable) {
      let intervalId = setInterval(this.incrementSlide, this.interval)
      this.setState({ slideInterval: intervalId })
    }
  }

  /**
   * The infamous handleResize - needed to update the state width & height values
   */
  private handleResize = () => {
    if (this.currentSlideRef?.getBoundingClientRect()?.width && this.currentSlideRef?.getBoundingClientRect()?.height) {
      clearInterval(this.state.slideInterval)
      this.setState({
        containerWidth: this.currentSlideRef?.getBoundingClientRect()?.width,
        containerHeight: this.currentSlideRef?.getBoundingClientRect()?.height,
        movement: this.isSwipeable
          ? this.currentSlideRef?.getBoundingClientRect()?.width * (this.state.currentSlide + 1)
          : 0,
      })
      this.setSlideInterval()
    }
  }

  /**
   * Method name says it all
   * @param index The index of the slide to transition to
   * @param duration Duration of transition
   */
  private transitionTo = (index: number, duration: number) => {
    this.setState({
      currentSlide: index,
      movement: (index + 1) * this.state.containerWidth,
      transitionDuration: `${duration}s`,
    })

    this.transitionTimeout = setTimeout(() => {
      this.setState({ transitionDuration: '0s' })
    }, duration * 100)
  }

  /**
   * This method is used by the interval method and the navigation arrows to transition
   * between slide.
   * @param by Number passed in through nav arrow click events
   */
  private incrementSlide = (by: number) => {
    const { current } = this.ref as any
    let { currentSlide } = this.state
    const len = this.slides.length

    currentSlide += by !== undefined ? by : 1

    if (currentSlide < 0) {
      currentSlide = len - 1
    }
    if (currentSlide >= len) {
      currentSlide = 0
    }

    this.transitionTo(currentSlide, 0.4)

    if (current?.clientWidth) {
      if (currentSlide === len) {
        setTimeout(() => {
          this.setState({
            movement: this.state.containerWidth,
            currentSlide: 0,
          })
        }, 300)
      }
      if (currentSlide === -1) {
        setTimeout(() => {
          this.setState({
            movement: this.state.containerWidth * len - 1,
            currentSlide: len - 1,
          })
        }, 300)
      }
    }
  }

  /**
   *
   * @param e touchStart event
   */
  private handleTouchStart = (e: any) => {
    this.lastTouch = e.nativeEvent.touches[0].clientX
    this.horizontalTouch = e.nativeEvent.touches[0].clientX
    this.verticalTouch = e.nativeEvent.touches[0].clientY
  }

  /**
   * Start IOS vertical scroll blocking logic
   */
  private firstClientX: number = 0

  private touchStart(e: any) {
    this.firstClientX = e.touches[0].clientX
  }

  private preventTouch(e: any) {
    const minValue = 10 // threshold

    const clientX = e.touches[0].clientX - this.firstClientX

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      // e.preventDefault()
      if (e.cancelable) {
        e.returnValue = false
      }

      return false
    }
    return
  }
  /**
   * End IOS vertical scroll blocking logic
   */

  /**
   * This method tracks vertical and horizontal swiping and determines if the user is
   * interacting with the slideshow or just scrolling vertically.
   * @param e touchMove event
   */
  private handleTouchMove = (e: any) => {
    const horizontalDelta = this.horizontalTouch - e.nativeEvent.touches[0].clientX
    const verticalDelta = this.verticalTouch - e.nativeEvent.touches[0].clientY

    const lastTouchDelta = this.lastTouch - e.nativeEvent.touches[0].clientX
    this.lastTouch = e.nativeEvent.touches[0].clientX

    if (Math.abs(verticalDelta) > Math.abs(horizontalDelta)) {
      this.setState({
        allowTouchEnd: false,
      })
    } else {
      clearInterval(this.state.slideInterval)
      this.setState({
        allowTouchEnd: true,
      })
      this.handleMovement(lastTouchDelta)
    }
  }

  /**
   * Based on the resulting logic from handleTouchMove, this method will be called if
   * it is determined that the user is interacting with the slideshow (i.e. swiping horizontally).
   * @param delta
   */
  private handleMovement = (delta: any) => {
    clearTimeout(this.transitionTimeout)
    const { movement } = this.state
    let nextMovement = movement + delta

    if (nextMovement < 0) {
      nextMovement = 0
    }

    this.setState({
      movement: nextMovement,
      transitionDuration: '0s',
    })
  }

  /**
   * This method runs once the user stops touching/swiping the slideshow and contains all of logic to
   * determine if the user swiped far enough to the left or right and will either transition to the next
   * slide or the previous slide.
   *
   * If the user does not move the slide far enough in either direction the slideshow will remain on the current slide.
   */
  private handleMovementEnd = () => {
    this.lastTouch = 0
    const { movement, currentSlide, containerWidth } = this.state
    const endPosition = movement / containerWidth
    const endPartial = endPosition % 1
    const endingIndex = endPosition - endPartial
    const deltaInteger = endingIndex - currentSlide

    let nextIndex = endingIndex

    if (deltaInteger < 0) {
      nextIndex = currentSlide - Math.abs(deltaInteger)
      if (endPartial > 0.9) {
        nextIndex += 1
      }
    }
    if (deltaInteger === 0) {
      nextIndex -= 1
    }

    this.transitionTo(nextIndex, 0.4)

    if (nextIndex === this.slides.length) {
      setTimeout(() => {
        this.setState({
          movement: this.state.containerWidth,
          currentSlide: 0,
        })
      }, 300)
    }
    if (nextIndex === -1) {
      setTimeout(() => {
        this.setState({
          movement: this.state.containerWidth * this.slides.length - 1,
          currentSlide: this.slides.length - 1,
        })
      }, 300)
    }

    this.setSlideInterval()
  }

  /**
   * This method is used but the dots as an additional way to navigate between slide
   * @param index Target slide index
   */
  private goToSlide = (index: number) => {
    this.transitionTo(index, 0.4)
  }

  /// ----------------------------------------------- RENDER METHODS -----------------------------------------
  public render() {
    const { data } = this.props
    const { enableNavigation } = data
    if (!data) {
      return <div>Loading...</div>
    }
    const { currentSlide, movement, transitionDuration, containerHeight } = this.state

    return (
      <React.Fragment>
        <div className={css.mobileSliderContainer} ref={this.ref as any}>
          <div
            className={css.slideContainer}
            style={{
              transform: `translateX(${movement * -1}px)`,
              transitionDuration: transitionDuration,
              height: containerHeight ? containerHeight + 'px' : '',
            }}
            onTouchStart={this.isSwipeable ? this.handleTouchStart : undefined}
            onTouchMove={this.isSwipeable ? this.handleTouchMove : undefined}
            onTouchEnd={this.isSwipeable && this.state.allowTouchEnd ? this.handleMovementEnd : undefined}
          >
            <React.Fragment>
              <div
                className={`${css.slide} ${css.fade} ${css.shown}`}
                ref={currentSlide === -1 ? this.setSlideRef : null}
                key={-1}
              >
                <MobileSlide
                  item={
                    movement === null
                      ? data.slideshowelements[0]
                      : data.slideshowelements[data.slideshowelements.length - 1]
                  }
                  show={false}
                  data={this.props.data}
                  contextProps={this.props.contextProps}
                />
              </div>

              {data.slideshowelements.map((item: ISlideShowElement, index: number) => {
                return (
                  <div
                    className={`${css.slide} ${css.fade} ${css.shown}`}
                    ref={currentSlide === index ? this.setSlideRef : null}
                    key={index}
                  >
                    <MobileSlide
                      item={item}
                      index={index + 1}
                      show={currentSlide === index}
                      data={this.props.data}
                      contextProps={this.props.contextProps}
                      onLoad={this.handleImageLoad}
                    />
                  </div>
                )
              })}
              <div
                className={`${css.slide} ${css.fade} ${css.shown}`}
                ref={currentSlide === data.slideshowelements.length ? this.setSlideRef : null}
                key={data.slideshowelements.length}
              >
                <MobileSlide
                  item={data.slideshowelements[0]}
                  show={false}
                  data={this.props.data}
                  contextProps={this.props.contextProps}
                />
              </div>
            </React.Fragment>
          </div>
          {data.slideshowelements.length > 1 && enableNavigation && (
            <React.Fragment>
              <a className={css.prev} onClick={this.incrementSlide.bind(this, -1)}>
                &#10094;
              </a>
              <a className={css.next} onClick={this.incrementSlide.bind(this, 1)}>
                &#10095;
              </a>
            </React.Fragment>
          )}
        </div>
        {data.slideshowelements.length > 1 && enableNavigation && (
          <React.Fragment>
            <div className={css.dotContainer}>
              {data.slideshowelements.map((_: ISlideShowElement, index: number) => {
                const activeClass = index === currentSlide ? css.active : ''
                return (
                  <span
                    key={index}
                    data-index={index}
                    className={`${css.mobileDot} ${activeClass}`}
                    onClick={() => this.goToSlide(index)}
                  />
                )
              })}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default MobileSlideShow
