import * as React from 'react'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import { has } from '../../../../Common/Utils/lodash'
import { IAdWidget, IDynamicProduct } from '../DynamicAdWidget/DynamicAdWidget'
import PriceDisplay from './PriceDisplay'
import ThemedButton from '../../../../Common/Button/Button'
import generateProductLink from './generateProductLink'
import appendImageTransformationProxyUrl from '../../../Plugins/ImageTransformation/imageTransformationUtils'
import css from './DynamicAdWidgetV2.module.scss'

export interface ICarouselProps {
  title: string
  enableNavigation: boolean
  carouselElements: IDynamicProduct[]
  contextProps: IOmniPageSharedContextGlobalProps
  hideATC?: boolean
  data?: IAdWidget
}

interface ICarouselState {
  currentSlide: number
  firstSlide: boolean
  lastSlide: boolean
  touchStart: number | null
  touchMove: number | null
  stepFromWindowWidth: number
}
export default class DynamicAdWidget extends React.PureComponent<ICarouselProps, ICarouselState> {
  constructor(props: ICarouselProps) {
    super(props)
    this.state = {
      currentSlide: 0,
      firstSlide: true,
      lastSlide: false,
      touchStart: null,
      touchMove: null,
      stepFromWindowWidth: typeof window !== 'undefined' && window.innerWidth > 767 ? 3 : 0,
    }
    typeof window !== 'undefined' ? window.addEventListener('resize', () => this.handleResize()) : null
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.handleResize())
  }
  private goToSlide(index: number) {
    this.setState({ currentSlide: index })
  }
  private goToPrevSlide(e: React.FormEvent | null) {
    if (e) {
      e.preventDefault()
    }
    let { currentSlide } = this.state
    const { firstSlide, stepFromWindowWidth } = this.state
    const { carouselElements } = this.props
    if (firstSlide) {
      return
    }
    if (currentSlide < 1) {
      currentSlide = currentSlide + 1
    }

    --currentSlide
    this.setState({
      currentSlide: currentSlide,
      firstSlide: currentSlide === 0 ? true : false,
      lastSlide: currentSlide + stepFromWindowWidth === carouselElements.length ? true : false,
    })
  }

  private addItemToCart = (e: React.FormEvent, index: number) => {
    e.preventDefault()
    e.stopPropagation()
    const onAddItemToCart = has(this.props, ['contextProps', 'events', 'onAddItemToCart'])
    if (onAddItemToCart) {
      onAddItemToCart(this.props.carouselElements[index] as any)
    }
  }

  private navigateToProduct = (product: IDynamicProduct) => {
    if (this.isGetURLToProductMethodDefined()) return
    const goToProduct = has(this.props, ['contextProps', 'events', 'goToProduct'])
    if (goToProduct) {
      goToProduct(product)
    }
  }

  private getURLToProduct = (product: IDynamicProduct): string => {
    const getURLToProduct = has(this.props, ['contextProps', 'events', 'getURLToProduct'])
    if (getURLToProduct) {
      return getURLToProduct(product)
    } else {
      return generateProductLink(product, 'product')
    }
  }

  private isGetURLToProductMethodDefined(): boolean {
    const getURLToProduct = has(this.props, ['contextProps', 'events', 'getURLToProduct'])
    return !!getURLToProduct
  }

  private goToNextSlide(e: React.FormEvent | null) {
    if (e) {
      e.preventDefault()
    }
    let { currentSlide } = this.state
    const { lastSlide, stepFromWindowWidth } = this.state
    const { carouselElements } = this.props
    if (lastSlide || currentSlide + stepFromWindowWidth + 1 === carouselElements.length) {
      this.setState({ lastSlide: true })
      return
    }
    if (currentSlide + stepFromWindowWidth + 1 > carouselElements.length) {
      currentSlide -= 1
    }

    ++currentSlide

    this.setState({
      currentSlide: currentSlide,
      firstSlide: currentSlide === 0 ? true : false,
      lastSlide: currentSlide + stepFromWindowWidth + 1 === carouselElements.length ? true : false,
    })
  }

  private handleResize = () => {
    this.setState({ stepFromWindowWidth: typeof window !== 'undefined' && window.innerWidth > 767 ? 3 : 0 })
  }

  private handleTouchStart(e: any) {
    const { touches } = e
    if (touches.length === 1) {
      this.setState({ touchStart: touches[0].clientX })
    } else {
      this.setState({ touchStart: null })
    }
  }

  private handleTouchMove(e: any) {
    const { touches } = e
    if (touches.length === 1) {
      this.setState({ touchMove: touches[0].clientX })
    }
  }

  private handleTouchEnd(product: IDynamicProduct) {
    const { touchMove, touchStart } = this.state

    if (!touchMove) {
      this.navigateToProduct(product)
    } else if (touchStart && Math.abs(touchStart - touchMove) > 100) {
      if (touchStart - touchMove < 0) {
        this.goToPrevSlide(null)
      } else {
        this.goToNextSlide(null)
      }
      this.setState({ touchMove: null, touchStart: null })
    }
  }

  private formatBackgroundImage = (url: string) => {
    return appendImageTransformationProxyUrl({
      targetUrl: url,
      context: this.props.contextProps,
      data: this.props.data as any,
    })
  }

  /// ----------------------------------------------- RENDER METHODS -----------------------------------------
  public render() {
    const { carouselElements } = this.props
    const { currentSlide, stepFromWindowWidth, firstSlide, lastSlide } = this.state
    const translate = {
      transform: `translateX(-${currentSlide * 100}%) translateX(-${
        stepFromWindowWidth === 0 ? 0 : currentSlide * 30
      }px)`,
    }
    if (!carouselElements) {
      return <div>Loading...</div>
    }
    const renderAdWidgetItemThumbnail: (product: IDynamicProduct) => JSX.Element = has(this.props, [
      'contextProps',
      'renderHooks',
      'renderAdWidgetItemThumbnail',
    ])

    const doHideATCButton: boolean = this.props.hideATC !== undefined ? this.props.hideATC : false

    return (
      <div className={css.carouselContainer}>
        <div className={css.carousel}>
          {stepFromWindowWidth + 1 < carouselElements.length && !firstSlide ? (
            <a href="#" className={css.carouselArrowLeft} onClick={e => this.goToPrevSlide(e)}>
              <i className="fas fa-chevron-left" />
            </a>
          ) : null}

          <ul className={css.carouselList}>
            {carouselElements.map((product: IDynamicProduct, index: number) => (
              <li className={`${css.productDisplay} productSlide`} style={translate} key={index}>
                <a
                  onTouchStart={e => this.handleTouchStart(e)}
                  onTouchMove={e => this.handleTouchMove(e)}
                  onTouchEnd={() => this.handleTouchEnd(product)}
                  href={this.getURLToProduct(product)}
                  onClick={() => this.navigateToProduct(product)}
                  className={css.productContainer}
                >
                  {renderAdWidgetItemThumbnail ? (
                    renderAdWidgetItemThumbnail(product)
                  ) : (
                    <React.Fragment>
                      <div className={`${css.containerPad}`}>
                        {product.marketPromoTitle && (
                          <h3 className={`${css.martketPromo}`}>{product.marketPromoTitle}</h3>
                        )}
                        <div
                          className={css.backgroundImage}
                          style={{
                            backgroundImage: `url('${this.formatBackgroundImage(product.thumbnail)}')`,
                          }}
                        />
                        <div className={css.detailContainer}>
                          {product.brand && <div className={css.brand}>{product.brand}</div>}
                          {product.name && (
                            <p className={css.productName}>
                              <b>{product.name}</b>
                            </p>
                          )}
                          {product.modelnumber && (
                            <div className={css.thumbnailModel}>Model: {product.modelnumber}</div>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: '-15px',
                          }}
                        >
                          <PriceDisplay product={product as any} />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </a>
                {!doHideATCButton && (
                  <div className={css.cartAction}>
                    <ThemedButton onClick={(e: React.FormEvent) => this.addItemToCart(e, index)}>
                      ADD TO CART
                    </ThemedButton>
                  </div>
                )}
              </li>
            ))}
          </ul>
          {stepFromWindowWidth + 1 < carouselElements.length && !lastSlide ? (
            <a href="#" aria-label="Right" className={css.carouselArrowRight} onClick={e => this.goToNextSlide(e)}>
              <i className="fas fa-chevron-right" />
            </a>
          ) : null}
          <ul className={css.carouselIndicatorContainer}>
            {carouselElements.map((_slide, index) => (
              <li className={currentSlide === index ? css.carouselIndicatorActive : css.carouselIndicator} key={index}>
                <a onClick={() => this.goToSlide(index)} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}
