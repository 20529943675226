import * as React from 'react'
import { ElementType, IOmniComponent } from '../../../interfaces'
import {
  composeUserGeneratedClasses,
  composeAndChainClasses,
  composeIdentifier,
  composeResponsiveClasses,
  // composeStylisticClassesByForce,
  composeStylisticClassesByType,
  setPluginStyleClass,
  applyTextAlignClasses,
  // appendCssToHead,
} from '../../../Plugins/Styles/indexV2'

import { minifyCSS } from '../../../Shared.context'
import { generateStyleSheet } from '../../../Utils/classUtils'
import ComponentFactory from '../OmniFactoryV2'
import css from './ColumnV2.module.scss'

export const Col = (props: any) => {
  const comp: any = props.data
  const context: any = props.contextProps
  const span: string = props.widthStyle.sm
  const width: string = props.widthStyle.width

  return (
    <React.Fragment>
      <props.contextProps.RenderHead>
        <style
          dangerouslySetInnerHTML={{
            __html: minifyCSS(generateStyleSheet(setPluginStyleClass(comp, context))),
          }}
        />
      </props.contextProps.RenderHead>
      <div
        {...composeIdentifier(comp)}
        {...composeAndChainClasses(
          [
            `flex-col`,
            css[`flex-col`],
            `${span && css[`omnimerse-column-sm-${span}`]}`,
            composeUserGeneratedClasses,
            composeResponsiveClasses,
          ],
          comp,
          context,
        )}
        style={{
          width: width && width,
        }}
      >
        <div
          {...composeAndChainClasses(
            ['flex-col-inner', css['flex-col-inner'], composeStylisticClassesByType, applyTextAlignClasses],
            comp,
            context,
          )}
        >
          {props?.data?.children?.map((c: IOmniComponent, index: number) => {
            return <ComponentFactory data={c} key={index} />
          })}
        </div>
      </div>
    </React.Fragment>
  )
}
export default {
  type: ElementType.COLUMN,
  component: Col,
}
