import * as React from 'react'
import { ElementType } from '../../../interfaces'
import { generateClassName } from '../../../Utils/classUtils'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import { IImageTransformationProps } from '../../../Plugins/ImageTransformation/imageTransformationInterface'
import appendImageTransformationProxyUrl from '../../../Plugins/ImageTransformation/imageTransformationUtils'
import imageCss from './SingleImageV2.module.scss'

export interface IOmniComponentSingleImage extends IImageTransformationProps {
  id: string
  url: string
  targetUrl?: string
  caption: {
    css: string
    html: string
  }
  richText: {
    html: string
    css: string
  }
  widthType?: string
  heightType?: string
  objectFit?: string
  realHeight?: string
  autoHeight?: boolean
  width: string
  height: string
  maxHeight: string
  alt: string
  imageClass: string
  css: string
  border: string
  captionPlacement?: 'above' | 'below'
  realWidth?: string
  useBackgroundImage:
    | {
        value: string
        label: any
      }
    | undefined
  imageAlignment:
    | {
        value: string
        label: any
      }
    | undefined
  backgroundImageSize:
    | {
        value: any
        label: any
      }
    | undefined
  backgroundPositionX:
    | {
        value: any
        label: any
      }
    | undefined
  backgroundPositionY:
    | {
        value: any
        label: any
      }
    | undefined
}
export interface IProps {
  data: IOmniComponentSingleImage
  contextProps: IOmniPageSharedContextGlobalProps
}

const OmniComponentSingleImage = (props: IProps) => {
  const { alt, backgroundPositionX, backgroundPositionY } = props.data

  // ------------------------------------------BACKGROUND POSITION ------------------------------------------------------
  const targetBackgroundPosition: {
    x: string
    y: string
  } = { x: 'left', y: 'top' }
  if (backgroundPositionX && backgroundPositionX.value) {
    targetBackgroundPosition.x = backgroundPositionX.value
  }
  if (backgroundPositionY && backgroundPositionY.value) {
    targetBackgroundPosition.y = backgroundPositionY.value
  }
  // ----------------------------------------- ALIGNMENT ---------------------------------------------------------------
  const targetAlignment: string = props.data.imageAlignment ? props.data.imageAlignment.value : 'left'

  // ---------------------------------------- GENERATE DYNAMIC CLASSES --------------------------------------------------
  const inlineStyle: any = {}
  const alignmentClassName = generateClassName(inlineStyle, `${props.data.id}-singleImageAlignment`, {
    textAlign: targetAlignment as any,
  })
  const formatBackgroundImage = (url: string) => {
    return appendImageTransformationProxyUrl({
      targetUrl: url,
      context: props.contextProps,
      data: props.data,
    })
  }

  const ImageWrapper = (props: any) => {
    if (props.targetUrl) {
      return (
        <div className={props.className}>
          <a href={`${props.targetUrl}`}>{props.children}</a>
        </div>
      )
    } else {
      return <div className={props.className}>{props.children}</div>
    }
  }

  let captionPlacement = props.data.captionPlacement ?? 'below'
  // ---------------------------------------------- RENDER ------------------------------------------------------------------------

  let widthType = props.data.widthType ?? 'initial'
  let heightType = props.data.heightType ?? 'auto'
  let targetWidth = widthType
  let targetHeight = heightType;
  if (!props.data.widthType) {
    if (props.data.realWidth) {
      targetWidth = props.data.realWidth
    }
  }else{
    if(widthType === 'custom'){
      targetWidth = props.data.realWidth ?? '100%' 
    }
  }
  // height
  if (!props.data.heightType) {
    if (props.data.realHeight) {
      targetHeight = props.data.realHeight
    }
  }else{
    if(heightType === 'custom'){
      targetHeight = props.data.realHeight ?? '100%' 
    }
  }
 
  return (
    <React.Fragment>
      <ImageWrapper
        className={`${imageCss.singleImage} ${alignmentClassName}`}
        targetUrl={`${props.data.targetUrl || ''}`}
      >
        {props.data.caption && captionPlacement === 'above' && (
          <div className={props.data.caption?.html ? imageCss.captionTop : ''}>
            <div dangerouslySetInnerHTML={{ __html: props.data.caption?.html ?? '' }} />
          </div>
        )}
        <img
          src={formatBackgroundImage(props.data.url)}
          alt={alt}
          title={alt}
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
            width: targetWidth,
            height: targetHeight,
            objectFit: (props.data.objectFit as any) || 'initial',
            verticalAlign: 'middle',
          }}
        />
        {props.data.caption && captionPlacement === 'below' && (
          <div className={props.data.caption?.html ? imageCss.captionBottom : ''}>
            <div dangerouslySetInnerHTML={{ __html: props.data.caption?.html ?? '' }} />
          </div>
        )}
      </ImageWrapper>
    </React.Fragment>
  )
}

export default {
  type: ElementType.SINGLE_IMAGE,
  component: OmniComponentSingleImage,
}
