import * as React from 'react'

export interface IIntersect {
  active?: boolean
  onHide?: any
  onShow: any
  onIntersect: any
  className?: string
  options?: {
    root: any
    rootMargin: any
    threshold: any
  }
}

export default class IntersectionVisible extends React.Component<IIntersect> {
  public node: any = null
  public observer: any = null

  /**
   * Start the observer when the component is mounted
   */
  public componentDidMount() {
    const { options, active } = this.props
    const isActive: boolean = active !== undefined ? active : true
    this.observer = new IntersectionObserver(this.handleObserverUpdate, options)
    if (isActive) {
      this.startObserving()
    }
  }

  /**
   * Update observer state on prop changes
   */
  public UNSAFE_componentWillReceiveProps(prevProps: IIntersect) {
    const { active } = this.props
    const isActive: boolean = active !== undefined ? active : true
    if (isActive && !prevProps.active) {
      this.startObserving()
    }

    if (!isActive && prevProps.active) {
      this.stopObserving()
    }
  }

  /**
   * Stop the observer on unmounting
   */
  public componentWillUnmount() {
    this.observer?.disconnect?.()
  }

  /**
   * Handles the visibility changes
   *
   * @param {array} entries
   */
  public handleObserverUpdate = (entries: any[]) => {
    const { onIntersect, onShow, onHide } = this.props
    const { intersectionRect } = entries[0]
    const { top, left, bottom, right } = intersectionRect

    if ([top, bottom, left, right].some(Boolean) && onShow) {
      onShow(entries)
    } else if (onHide) {
      onHide(entries)
    }

    onIntersect(entries)
  }

  /**
   * Starts the observer
   */
  public startObserving() {
    this.observer.observe(this.node)
  }

  /**
   * Stops the observer
   */
  public stopObserving() {
    this.observer.unobserve(this.node)
  }

  /**
   * Render component
   *
   * @returns {JSX.Element}
   */
  public render() {
    const { className } = this.props
    return (
      <div className={className} ref={node => (this.node = node)}>
        {this.props.children}
      </div>
    )
  }
}
