import * as React from 'react'
import { ElementType } from '../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { find, findIndex } from '../../../Common/Utils/lodash'
import { CMS_API_CALLS } from '../../../Common/Services/API/CMS'
import { ICategory } from '../../../Catalog'
import CategoryThumbnails from '../../../Catalog/CategoryThumbnails/CategoryThumbnails'

interface IOption {
  value: any
  label: string
  slug: string
}

export interface IOmniElementCategoryThumbnailsProps {
  category?: IOption
  priorityOrder?: IOption[]
  exclude?: IOption[]
  customRenderHook: string
  rowCount: IOption | undefined
}
export interface IProps {
  data: IOmniElementCategoryThumbnailsProps
  contextProps: IOmniPageSharedContextGlobalProps
}

const IOmniElementCategoryThumbnails = (props: IProps) => {
  const [blocks, setBlocks] = React.useState<ICategory[]>([])
  const [loaded, setLoaded] = React.useState(false)
  const { events, renderHooks } = props.contextProps
  const filterCategories = (categories: ICategory[]) => {
    const { priorityOrder, exclude } = props.data
    const copiedCategories = [...categories]
    const orderedCategories: ICategory[] = priorityOrder !== undefined ? [] : copiedCategories
    let fileredCategories: any[] = []
    if (priorityOrder) {
      for (const order of priorityOrder) {
        const targetIndex: number = findIndex(copiedCategories, {
          id: order.value,
        })
        if (targetIndex !== -1) {
          orderedCategories.push(copiedCategories[targetIndex])
          copiedCategories.splice(targetIndex, 1)
        }
      }
      fileredCategories = [...orderedCategories, ...copiedCategories]
    }

    const returnedCategories: any[] = []
    if (exclude) {
      for (const attr of fileredCategories) {
        const isExcluded: IOption | undefined = find(exclude, {
          value: attr.id,
        })
        if (!isExcluded) {
          returnedCategories.push(attr)
        }
      }
    }
    return returnedCategories
  }

  const loadCategories = async () => {
    const { category } = props.data
    const { transport } = props.contextProps
    if (category && category.value && transport) {
      const subs: {
        data?: ICategory[]
        error?: string
      } = await CMS_API_CALLS.CATEGORIES.getSubCategories(transport, category.value)
      if (!subs.error && subs.data) {
        return subs.data
      }
    }
    return []
  }

  const initialize = async () => {
    setLoaded(true)
    if (props.contextProps.cache) {
      if (props.contextProps.cache.subcategories) {
        setBlocks(filterCategories(props.contextProps.cache.subcategories))
        return
      }
    }
    const categories: ICategory[] = await loadCategories()
    const filtered: ICategory[] = filterCategories(categories)
    setBlocks(filtered)
  }

  React.useEffect(() => {
    if (!loaded) {
      initialize()
    }
  })

  if (!blocks || !blocks.length) {
    return <div />
  }

  return (
    <div>
      <CategoryThumbnails
        categories={blocks}
        columns={props.data.rowCount ? props.data.rowCount.value : '3'}
        customRenderHook={props.data.customRenderHook}
        configuration={{
          events: {
            ...events,
          } as any,
          renderProps: {
            ...renderHooks,
          } as any,
        }}
      />
    </div>
  )
}

export default {
  type: ElementType.CATEGORY_THUMBNAILS,
  component: IOmniElementCategoryThumbnails,
}
