import * as React from 'react'
import IntersectionVisible from './IntersectionVisible/IntersectionVisible'

export interface IAnimationWrapper {
  onIntersect: any
  onShow: any
  children: any
  viewed: boolean
  hasAnimation: boolean
}

const AnimationWrapper = (props: IAnimationWrapper) => {
  if (!props.hasAnimation) {
    return <React.Fragment>{props.children}</React.Fragment>
  }
  return (
    <IntersectionVisible active={!props.viewed} {...props}>
      {props.children}
    </IntersectionVisible>
  )
}

export default AnimationWrapper
