import * as React from 'react'
import { ElementType } from '../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import CatalogProducts from '../../../Catalog/Products/Products'
import { IProduct } from '../../../PDP'
import { isEmpty } from '../../../Common/Utils/lodash'
import CACHE_CONTSTANT from '../../../Common/Utils/cache/constants'

interface IOption {
  value: any
  label: string
}

export interface IOmniElementProductListingProps {
  category?: IOption
}
export interface IProps {
  data: IOmniElementProductListingProps
  contextProps: IOmniPageSharedContextGlobalProps
}

const getQueryStringsAsObject = () => {
  try {
    const search = location.search.substring(1)
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}',
    )
  } catch (e) {
    return {}
  }
}

const IOmniElementProductListing = (props: IProps) => {
  const [products, setProducts] = React.useState<IProduct[]>([])
  const [loading, setLoading] = React.useState(false)
  const { category } = props.data
  const { events } = props.contextProps

  const filterProducts = (targetProducts: IProduct[]) => {
    setProducts(targetProducts)
  }

  // only get the cache if we don't have search parameters
  const getCacheKey = (targetKey: any) => {
    const query = getQueryStringsAsObject()
    if (!isEmpty(query)) {
      return {}
    }
    return {
      cacheKey: `${CACHE_CONTSTANT.FRONTEND.PIM.CATEGORY}-${targetKey}-association-products`,
    }
  }

  const loadProducts = async () => {
    const { transport } = props.contextProps
    if (category && category.value) {
      if (transport) {
        const productAssociations = await transport.post('/categories/products', {
          ...getCacheKey(category.value),
          categoryQuery: {
            _id: category.value,
          },
          showFields: true,
          matchingFields: {
            ...getQueryStringsAsObject(),
          },
        })
        if (productAssociations.data) {
          filterProducts(productAssociations.data.products)
        }
      }
    }
    setLoading(false)
  }

  React.useEffect(() => {
    let hasPreloadedProducts: boolean = false
    if (props.contextProps.cache) {
      if (props.contextProps.cache.products) {
        hasPreloadedProducts = true
        filterProducts(props.contextProps.cache.products)
      }
    }
    if (!hasPreloadedProducts) {
      setLoading(true)
      loadProducts()
    }
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }
  if (!products || !products.length) {
    return <div />
  }
  return (
    <CatalogProducts
      products={products}
      query={{ title: 'Any' }}
      configuration={
        {
          events,
        } as any
      }
    />
  )
}

export default {
  type: ElementType.PRODUCT_LISTING,
  component: IOmniElementProductListing,
}
