import * as React from 'react'
import { ElementType } from '../../interfaces'
import { IImageTransformationProps } from '../../Plugins/ImageTransformation/imageTransformationInterface'
import appendImageTransformationProxyUrl from '../../Plugins/ImageTransformation/imageTransformationUtils'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import css from './Banner.module.scss'

export interface IOmniElementBannerProps extends IImageTransformationProps {
  url: string
  parallax: boolean
  verticalPadding: string
  backgroundSize: string
  overlayOpacity: boolean
  title: string
  backgroundPosition: string
  body: string
  alt: string
}

interface IProps {
  data: IOmniElementBannerProps
  contextProps: IOmniPageSharedContextGlobalProps
}

const OmniElementBanner = (props: IProps) => {
  const { title, body, alt } = props.data
  const getBackgroundSize = () => {
    const targetSize: string = props.data.backgroundSize !== undefined ? props.data.backgroundSize : 'cover'
    return targetSize
  }
  const hasOpacity = () => {
    const giveOpacity: boolean = props.data.overlayOpacity !== undefined ? props.data.overlayOpacity : false
    return giveOpacity ? css.masked : css.unmasked
  }
  const hasParallax = () => {
    const { parallax } = props.data
    const giveParallax: boolean = parallax !== undefined ? parallax : true
    return giveParallax ? css.parallax : ''
  }
  const hasPadding = () => {
    const { verticalPadding } = props.data
    const doesHavePadding: boolean = verticalPadding !== undefined
    return {
      padding: `${doesHavePadding ? verticalPadding : '150px'} 0`,
    }
  }
  const getBackgroundPosition = () => {
    const { backgroundPosition } = props.data
    const hasPosition: boolean = backgroundPosition !== undefined
    return hasPosition ? backgroundPosition : 'initial'
  }
  const formatBackgroundImage = () => {
    return appendImageTransformationProxyUrl({
      targetUrl: props.data.url,
      context: props.contextProps,
      data: props.data,
    })
  }
  return (
    <div
      className={`${hasOpacity()} ${hasParallax()}`}
      role="img"
      aria-label={alt}
      title={alt}
      style={{
        backgroundImage: `url('${formatBackgroundImage()}')`,
        backgroundPosition: getBackgroundPosition(),
        backgroundSize: getBackgroundSize(),
      }}
    >
      <div className={css.opener} style={hasPadding()}>
        <div className={css.inner}>
          {title && <h1 className={css.white}>{title}</h1>}
          {body && <p className={css.bodyText}>{body}</p>}
        </div>
      </div>
    </div>
  )
}

export default {
  type: ElementType.BANNER,
  component: OmniElementBanner,
}
