import * as React from 'react'
import {
  applyTextAlignClasses,
  composeAndChainClasses,
  composeIdentifier,
  composeResponsiveClasses,
  composeStylisticClassesByType,
  composeUserGeneratedClasses,
  setPluginStyleClass,
} from '../../../Plugins/Styles/indexV2'
import { minifyCSS } from '../../../Shared.context'
import { generateStyleSheet } from '../../../Utils/classUtils'

export const Element = (props: any) => {
  const comp: any = props.data
  const context = props.contextProps
  return (
    <React.Fragment>
      <props.contextProps.RenderHead>
        <style
          dangerouslySetInnerHTML={{
            __html: minifyCSS(generateStyleSheet(setPluginStyleClass(comp, context))),
          }}
        />
      </props.contextProps.RenderHead>
      <div
        {...composeIdentifier(comp)}
        {...composeAndChainClasses(
          [
            'element',
            composeUserGeneratedClasses,
            composeStylisticClassesByType,
            composeResponsiveClasses,
            applyTextAlignClasses,
            // composeUserGeneratedStyles,
          ],
          comp,
          context,
        )}
      >
        {props.children}
      </div>
    </React.Fragment>
  )
}
