import * as React from 'react'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import appendImageTransformationProxyUrl from '../../../Plugins/ImageTransformation/imageTransformationUtils'
import css from './ImagesWithOverlayV2.module.scss'
import { IImagesWithOverlayElement, IOmniComponentImagesOverlayProps } from './ImagesWithOverlay'
import { convertRemToPx } from '../../../Utils/classUtils'

export interface IImageOverlayItemProps {
  item: IImagesWithOverlayElement
  textAlignHoriz: {
    value: any
    label: any
  }
  textAlignVert: {
    value: any
    label: any
  }
  data: IOmniComponentImagesOverlayProps
  contextProps: IOmniPageSharedContextGlobalProps
  screenMode?: string
}

const ImagesWithOverlayItem = (props: IImageOverlayItemProps) => {
  const { item } = props
  const textAlignHoriz =
    props.textAlignHoriz.value === 'left'
      ? css.textLeft
      : props.textAlignHoriz.value === 'center'
      ? css.textCenter
      : css.textRight
  const textAlignVert =
    props.textAlignVert.value === 'top'
      ? css.textTop
      : props.textAlignVert.value === 'middle'
      ? css.textMiddle
      : css.textBottom
  const {
    buttonBackgroundColor,
    buttonFontColor,
    buttonBorder,
    padding,
    imageWidth,
    height,
    backgroundSize,
    backgroundImagePosition,
  } = item
  const renderButton = () => {
    return (
      <div
        className={css.imageCta}
        style={{
          background: buttonBackgroundColor,
          color: buttonFontColor,
          border: buttonBorder,
        }}
      >
        {item.buttonText}
      </div>
    )
  }

  const formatBackgroundImage = (url: string) => {
    return appendImageTransformationProxyUrl({
      targetUrl: url,
      context: props.contextProps,
      data: props.data,
    })
  }

  const studioScreenMode = props.screenMode && props.screenMode === 'PHONE' ? css.studioScreenMode : ''

  const renderItem = () => {
    return (
      <div className={`${css.imageOverlayItem}`}>
        <div
          className={css.backgroundImage}
          style={{
            backgroundImage: `url('${formatBackgroundImage(item.imageUrl)}')`,
            padding,
            width: imageWidth,
            height,
            backgroundSize: backgroundSize ? backgroundSize.value : '',
            backgroundPosition: backgroundImagePosition,
          }}
          role="img"
          aria-label={item.alt}
        />
        <div className={`${css.alignContainer} ${textAlignHoriz} ${textAlignVert} ${studioScreenMode}`}>
          {item.text.css && (
            <style
              dangerouslySetInnerHTML={{
                __html: convertRemToPx(item.text.css, props.contextProps),
              }}
            />
          )}
          <div>
            <div
              style={{ textAlign: 'start' }}
              dangerouslySetInnerHTML={{
                __html: item.text.html,
              }}
            />
          </div>
          <div>
            {item.buttonText !== '' && (
              <React.Fragment>
                {item.url && <a href={item.url}>{renderButton()}</a>}
                {!item.url && renderButton()}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
  return (
    <React.Fragment>
      {item.url && !item.buttonText && (
        <a href={item.url} aria-label={item.alt} className={css.imageWithOverlayItemAnchor}>
          {renderItem()}
        </a>
      )}
      {(!item.url || item.buttonText) && renderItem()}
    </React.Fragment>
  )
}

export default ImagesWithOverlayItem
