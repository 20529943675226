import * as React from 'react'
import css from './Error404.scss'

const Error404Image = '../../../../../static/mor-404.jpg'

const Error404 = (): JSX.Element => {
  return (
    <div className={css.notFound}>
      <div className={css.notFoundContainer}>
        <h1 className={css.header}>Sorry, the page you are looking for can't be found.</h1>
        <p className={css.subheader}>
          Please check spelling & try again or go back to our <a href="/">homepage</a>.
        </p>
        <img src={Error404Image} className={css.errorImg} />
      </div>
    </div>
  )
}

export default Error404
