import * as React from 'react'
// import { IOmniComponent, ElementType } from '../../interfaces'
import ComponentFactory from '../OmniFactory'
import { ElementType, IOmniComponent } from '../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'

interface IOption {
  value: string
  label: string
}
export interface IOmniElementColumnProps extends IOmniComponent {
  backgroundColor:
    | string
    | {
        value: string
        label: string
      }
  useColumnAnimation: IOption | undefined
  customBackgroundColor?: string
  v2?: boolean
}

interface IProps {
  data: IOmniElementColumnProps
  contextProps: IOmniPageSharedContextGlobalProps
}

const IOmniColumn = (props: IProps) => {
  const children = props.data.children
  if (!children.length) {
    return <div />
  }
  return (
    <React.Fragment>
      <div className="merce-column-container">
        {children.map((c: IOmniComponent, index: number) => {
          return <ComponentFactory data={c} key={index} />
        })}
      </div>
    </React.Fragment>
  )
}

export default {
  type: ElementType.COLUMN,
  component: IOmniColumn,
}
