import * as React from 'react'

import ThemedButton from '../Button/Button'
import { FIELD_TYPES } from '../Interfaces/Strapi/Product'
import css from './RangeFilter.scss'

export interface IProps {
  min: number
  minValue?: number | undefined
  placeholderMin?: string
  max: number
  maxValue?: number | undefined
  placeholderMax?: string
  onRangeFilter?: (rangeFilter: IRangeFilterOption) => void
  fieldFilter: string
  labelButton?: string
}

export interface IRangeFilterOption {
  minValue: number | string
  maxValue: number | string
  fieldFilter: string
}

const RangeFilter = (props: IProps) => {
  const [minValue, setMinValue] = React.useState<number | string>(props.minValue || '')
  const [maxValue, setMaxValue] = React.useState<number | string>(props.maxValue || '')
  const [invalidFieldMin, setInvalidFieldMin] = React.useState<boolean>(false)
  const [invalidFieldMax, setInvalidFieldMax] = React.useState<boolean>(false)

  const onClickRangeFilter = (): void => {
    if (props.onRangeFilter && minValue <= maxValue && maxValue !== 0) {
      const targetOption: IRangeFilterOption = {
        minValue,
        maxValue,
        fieldFilter: props.fieldFilter,
      }
      props.onRangeFilter(targetOption)
    }
  }

  const renderErrorLabel = () => {
    return <div className={css.labelMessage}>Invalid Range</div>
  }

  const renderError = () => {
    if (minValue && maxValue) {
      if (minValue > maxValue) {
        return renderErrorLabel()
      }
      if (maxValue > props.max) {
        return renderErrorLabel()
      }
    }

    if (minValue < 0 || maxValue < 0) {
      return renderErrorLabel()
    }

    if (invalidFieldMax || invalidFieldMax) {
      return renderErrorLabel()
    }

    return ''
  }

  const preventNotDesiredCharacters = (e: React.KeyboardEvent) => {
    const { keyCode } = e
    if (
      (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || // numbers between 0 - 9
      keyCode === 8 || // backspace
      keyCode === 9 || // tab
      keyCode === 37 || // move back
      keyCode === 39 || // move forward
      keyCode === 190 // point
    ) {
      return true
    }
    e.preventDefault()
    return false
  }

  return (
    <React.Fragment>
      <div className={css.RangeFilter}>
        <div className={css.container}>
          <input
            className={`${invalidFieldMin ? css.invalid : ''}`}
            placeholder={props.placeholderMin}
            title={props.placeholderMin}
            type={FIELD_TYPES.NUMBER}
            value={minValue}
            min={props.min}
            max={props.max}
            onKeyDown={preventNotDesiredCharacters}
            onPaste={e => e.preventDefault()}
            onChange={e => {
              e.preventDefault()
              const min = Number(e?.target?.value)
              setMinValue(min || '')
              if (min > props.max || min < props.min) {
                setInvalidFieldMin(true)
              } else {
                setInvalidFieldMin(false)
              }
            }}
            autoComplete="off"
          />
          <span>-</span>
          <input
            className={`${invalidFieldMax ? css.invalid : ''}`}
            placeholder={props.placeholderMax}
            title={props.placeholderMax}
            type={FIELD_TYPES.NUMBER}
            value={maxValue}
            min={0}
            max={props.max}
            onPaste={e => e.preventDefault()}
            onKeyDown={preventNotDesiredCharacters}
            onChange={e => {
              e.preventDefault()
              const max = Number(e?.target?.value)
              setMaxValue(max || '')
              if (max && (max <= Number(minValue) || max > props.max || max < props.min)) {
                setInvalidFieldMax(true)
              } else {
                setInvalidFieldMax(false)
              }
            }}
            autoComplete="off"
          />
          <ThemedButton
            className={css.goButton}
            disabled={minValue == 0 || maxValue == 0 || invalidFieldMin || invalidFieldMax}
            onClick={onClickRangeFilter}
          >
            {props.labelButton}
          </ThemedButton>
        </div>

        {renderError()}
      </div>
    </React.Fragment>
  )
}

RangeFilter.defaultProps = {
  placeholderMin: '$MIN',
  placeholderMax: '$MAX',
  min: 0,
  max: 9999999999,
  labelButton: 'Go',
} as Partial<IProps>

export default RangeFilter
