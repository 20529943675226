import * as React from 'react'
import { IPluginRenderProps } from '../../FrontendPageComponentPluginsInterface'
import { generateClassName, generateStyleSheet } from '../../../Utils/classUtils'
import appendImageTransformationProxyUrl from '../../ImageTransformation/imageTransformationUtils'

/**
 * If the user wishes to have a background image with parallax effect
 * @param props
 */
const Parallax = (props: IPluginRenderProps) => {
  const {
    useBackgroundImage,
    backgroundImage,
    backgroundImagePositionX,
    backgroundImagePositionY,
    backgroundImageAttachment,
    backgroundImageRepeat,
    backgroundImageSize,
  } = props.componentData
  const formatBackgroundImage = (url: string) => {
    return appendImageTransformationProxyUrl({
      targetUrl: url as string,
      context: props.contextProps,
      data: props.componentData as any,
    })
  }
  if (useBackgroundImage && backgroundImage) {
    if (useBackgroundImage.value) {
      const inlineStyle: any = {}
      let parallaxOuterClass = generateClassName(inlineStyle, `${props.componentData.id}-parallax-outer`, {
        backgroundImage: `url('${formatBackgroundImage(backgroundImage)}')`,
        backgroundSize: backgroundImageSize?.value ?? 'initial',
        backgroundPositionX: backgroundImagePositionX?.value ?? 'initial',
        backgroundPositionY: backgroundImagePositionY?.value ?? 'initial',
        backgroundAttachment: backgroundImageAttachment?.value ?? 'initial',
        backgroundRepeat: backgroundImageRepeat?.value ?? 'initial',
      })
      return (
        <React.Fragment>
          {props.contextProps ? (
            <props.contextProps.RenderHead>
              <style
                dangerouslySetInnerHTML={{
                  __html: generateStyleSheet(inlineStyle),
                }}
              />
            </props.contextProps.RenderHead>
          ) : (
            <React.Fragment>
              <style
                dangerouslySetInnerHTML={{
                  __html: generateStyleSheet(inlineStyle),
                }}
              />
            </React.Fragment>
          )}

          <div className={`${parallaxOuterClass}`}>{props.children}</div>
        </React.Fragment>
      )
    }
  }
  return props.children
}

export default Parallax
