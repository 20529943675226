import * as React from 'react'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import appendImageTransformationProxyUrl from '../../../Plugins/ImageTransformation/imageTransformationUtils'
import css from './SlideShowV2.module.scss'
import { IOmniComponentSlideShowProps, ISlideShowElement } from './SlideShow'

export interface ISlideProps {
  item: ISlideShowElement
  onChange?: any
  onDelete?: any
  show: boolean
  width?: number
  index?: number
  data?: IOmniComponentSlideShowProps
  contextProps?: IOmniPageSharedContextGlobalProps
  onLoad?: (ev: any) => void
}

const MobileSlide = (props: ISlideProps) => {
  const { item, data } = props
  const linkHotmapProps: any = item.hotmap || {
    width: 100,
    height: 100,
    left: 0,
    top: 0,
  }

  const formatBackgroundImage = (url: string) => {
    return appendImageTransformationProxyUrl({
      targetUrl: url,
      context: props.contextProps as any,
      data: props.data as any,
    })
  }

  const injectCustomAttributes = () => {
    const { item, index } = props
    if (!item.customAttributes) {
      return {}
    }
    try {
      let lines = item.customAttributes?.split('\n') ?? ''
      let attrs: Record<string, string | number> = {}
      for (const line of lines) {
        let [key, value] = line.split('=')
        if (key && value) {
          let targetValue: string = value
            .replace(/\"/g, '')
            .replace(/{title}/g, item.title)
            .replace(/{index}/g, index ?? ('' as any))

          if (targetValue) {
            attrs[key] = targetValue
          }
        }
      }
      return attrs
    } catch (e) {
      console.warn(e)
      return {}
    }
  }

  const onImageLoad = (e: any) => {
    if (props.onLoad && props.show) {
      props.onLoad(e)
    }
  }

  return (
    <div>
      {!!item.url && (
        <a
          href={item.url}
          rel="noopener noreferrer"
          target={data?.target?.value || '_blank'}
          aria-label={item.title}
          style={{
            width: linkHotmapProps.width + '%',
            height: linkHotmapProps.height + '%',
            left: linkHotmapProps.left + '%',
            top: linkHotmapProps.top + '%',
            position: 'absolute',
          }}
          {...injectCustomAttributes()}
        />
      )}
      <img
        src={formatBackgroundImage(item.imageUrl)}
        alt={item.alt}
        title={item.title}
        className={css.mobileImage}
        onLoad={onImageLoad}
      />
    </div>
  )
}

export default MobileSlide
