import * as React from 'react'
import { ElementType, IOmniComponent } from '../../../interfaces'
import { appendArrayOfElements, extractElementsByTagName } from './utils'

export interface IProps {
  data: IOmniComponentCodeBlockProps
}

export interface IOmniComponentCodeBlockProps extends IOmniComponent {
  codeSnippet: string
  includeInHead: boolean
}

const CodeBlock = (props: IProps) => {
  const { codeSnippet } = props.data
  const codeBlockRef: any = React.useRef(null)
  // Stores how many external scripts are present
  const [externalScriptsCount, setExternalScriptsCount] = React.useState(0)
  // Stores how many extenral scripts finished loading
  const [loadedScriptsCount, setLoadedScriptsCount] = React.useState(0)
  // Stores all the scripts that need to be lazy appended to the code block
  const [lazyScriptElements, setLazyScriptElements] = React.useState<HTMLElement[]>([])
  const [isReadyForLazyScripts, setIsReadyForLazyScripts] = React.useState(false)

  // if (includeInHead) {
  //   return (
  //     <props.contextProps.RenderHead>
  //       <style
  //         dangerouslySetInnerHTML={{
  //           __html: generateStyleSheet(inlineStyles),
  //         }}
  //       />
  //     </props.contextProps.RenderHead>
  //   )
  // }

  const handleOnScriptLoad = () => {
    setLoadedScriptsCount(loadedScriptsCount + 1)
  }

  React.useEffect(() => {
    if (typeof window !== undefined) {
      // We create a transition node that won't be added to the dom as is but will let us extract each tag individually
      let transitionNode: any = document.createElement('div')
      transitionNode.innerHTML = codeSnippet

      // Extract each tag and update the transition node with those tags out
      const scriptElements = extractElementsByTagName({
        transitionNode,
        tagName: 'script',
        onScriptLoad: handleOnScriptLoad,
      })
      setLazyScriptElements(scriptElements.extractedElements)
      setExternalScriptsCount(scriptElements.vipExtractedElements.length)

      transitionNode = scriptElements.transitionNode

      const styleElements = extractElementsByTagName({ transitionNode, tagName: 'style' })
      transitionNode = styleElements.transitionNode

      const linkElements = extractElementsByTagName({ transitionNode, tagName: 'link' })
      transitionNode = linkElements.transitionNode

      // Very important elements that needs to load asap
      appendArrayOfElements({ parentNode: codeBlockRef.current, elements: scriptElements.vipExtractedElements })
      appendArrayOfElements({ parentNode: codeBlockRef.current, elements: linkElements.vipExtractedElements })

      // With everything extracted, we can add the transition node to the real div now
      codeBlockRef.current.append(transitionNode)

      // Append styles because they are processed correctly
      appendArrayOfElements({ parentNode: codeBlockRef.current, elements: styleElements.extractedElements })

      // All the previous elements have been added, we are ready to wait for the external scripts to append the rest of the scripts
      setIsReadyForLazyScripts(true)
    }
  }, [])

  React.useEffect(() => {
    if (isReadyForLazyScripts && (loadedScriptsCount === externalScriptsCount || externalScriptsCount === 0)) {
      appendArrayOfElements({ parentNode: codeBlockRef.current, elements: lazyScriptElements })
    }
  }, [loadedScriptsCount, externalScriptsCount, isReadyForLazyScripts])

  return <div ref={codeBlockRef} />
}

export default {
  type: ElementType.CODE_BLOCK,
  component: CodeBlock,
}
