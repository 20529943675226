// import { IOption } from '../../../../../../../Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import * as React from 'react'
import { IPageComponentPlugin, IPluginRenderProps } from '../FrontendPageComponentPluginsInterface'
import { PAGE_COMPONENT_PLUGIN_TYPE } from '../FrontendPageComponentPluginTypes'
import { ElementType } from '../../interfaces'
import AnimationWrapper from './AnimationWrapper'

const renderPluginWrapperHook = (props: IPluginRenderProps): JSX.Element => {
  if (props.componentData.type === ElementType.ROW || props.componentData.type === ElementType.COLUMN) {
    return <AnimationWrapper data={props.componentData}>{props.children}</AnimationWrapper>
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

const plugin: IPageComponentPlugin = {
  enabled: true,
  type: PAGE_COMPONENT_PLUGIN_TYPE.ANIMATIONS,
  hooks: {
    renderPluginWrapperHook,
  },
}

export default plugin
