import * as React from 'react'
import { IOmniComponent } from '../../interfaces'
import AnimationIntersection from './AnimationIntersection'
import animations from './Animations.scss'

export interface IAnimationWrapper {
  children: any
  data: IOmniComponent
}

const AnimationWrapper = (props: IAnimationWrapper) => {
  const [viewed, setViewed] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const { targetAnimation } = props.data
  const hasAnimation: boolean = targetAnimation !== undefined && targetAnimation.value !== ''
  const applyAnimations = () => {
    if (targetAnimation && targetAnimation.value) {
      if (visible && !viewed) {
        setTimeout(() => {
          setViewed(true)
        }, 1000)
        return animations[targetAnimation.value]
      } else {
        return ''
      }
    }
    return ''
  }

  return (
    <AnimationIntersection
      onIntersect={() => {
        //todo: implement
      }}
      onShow={() => {
        setVisible(true)
      }}
      viewed={viewed}
      hasAnimation={hasAnimation}
    >
      <div className={`${applyAnimations()}`}>{props.children}</div>
    </AnimationIntersection>
  )
}

export default AnimationWrapper
