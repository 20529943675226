import * as React from 'react'
import ComponentFactory from '../OmniFactoryV2'
import { ElementType, IOmniComponent, ITagConfiguration } from '../../../interfaces'
import SharedDataContext, { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import { showContentBasedOnCurrentLocation } from '../../../Utils/geoTag'
import css from './RowV2.module.scss'
import {
  composeUserGeneratedClasses,
  composeAndChainClasses,
  composeIdentifier,
  composeResponsiveClasses,
  composeStylisticClassesByForce,
  setPluginStyleClass,
  applyTextAlignClasses,
} from '../../../Plugins/Styles/indexV2'
import { generateStyleSheet } from '../../../Utils/classUtils'
import { STYLE_PROPERTY_NAME } from '../../../Plugins/Styles/styleTypesEnumeration'
import { Col } from '../Column/Column'

interface IOption {
  value: string
  label: string
}

export interface IOmniElementRowProps extends IOmniComponent {
  spanData: string
  useBackgroundImage: IOption
  useRowAnimation: IOption | undefined
  backgroundImage: string
  overlayOpacity: number
  parallax: IOption
  verticalPadding: string
  backgroundColor:
    | string
    | {
        color: string
        value: string
        label: string
      }
  fontColor: string | IOption
  geotaggingOptions?: ITagConfiguration
  publishToday: IOption | undefined
  startDate: Date
  endDate: Date
  studioColor?: string
  studioTitle?: string
  customBackgroundColor?: string
  backgroundSize?: string
  spacing: number
  direction: { value: any; label: string }
  justifyContent: { value: any; label: string }
  alignItems: { value: any; label: string }
}
export interface IProps {
  data: IOmniElementRowProps
  children: any
  contextProps: IOmniPageSharedContextGlobalProps
}

/**
 * Initialize
 * @param props
 */
const OmniElementRow = (props: IProps) => {
  const comp: any = props.data
  const context: any = React.useContext(SharedDataContext.Context) as any
  // geotagging check ----------------------------------------------
  const { children, spanData, geotaggingOptions } = props.data
  const { contextProps } = props
  if (geotaggingOptions && geotaggingOptions.enabled) {
    if (contextProps?.locationData) {
      // then lets check it out
      const doShowBlock: boolean = showContentBasedOnCurrentLocation(contextProps.locationData, geotaggingOptions)
      if (!doShowBlock) {
        return <div />
      }
    }
  }

  // end geotagging check ----------------------------------------

  const getColSpan = (isFree: boolean, col: any, data: any) => {
    if (!isFree) {
      return {
        sm: col,
      }
    }
    if (data.width) {
      return {
        width: data.width,
      }
    }
    return
  }

  const columns: any = children
  const spans: any = spanData?.split('+') ?? ['1/1']
  const { publishToday: pT, startDate: sD, endDate: eD } = props.data
  const { previewDate: pD } = contextProps
  const publishToday = pT ? pT : { value: true }
  const previewDate = new Date(pD || new Date())
  const startDate = new Date(sD)
  const endDate = new Date(eD)
  const hideOrShowRow =
    (!publishToday.value && startDate <= previewDate && previewDate <= endDate) || publishToday.value ? true : false
  const isFreeMode: boolean = columns.find((c: any) => c.width !== undefined && c.width !== '100%') !== undefined

  if (hideOrShowRow) {
    return (
      <React.Fragment>
        <props.contextProps.RenderHead>
          <style
            dangerouslySetInnerHTML={{
              __html: generateStyleSheet(setPluginStyleClass(props.data, context)),
            }}
          />
        </props.contextProps.RenderHead>
        <div
          {...composeIdentifier(comp)}
          {...composeAndChainClasses(
            ['flex-row', css['flex-row'], composeUserGeneratedClasses, composeResponsiveClasses],
            comp,
            context,
          )}
          style={{
            display: comp.isChildRow ? 'flex' : 'block',
          }}
        >
          <div
            {...composeAndChainClasses(
              [
                'flex-row-inner',
                css['flex-row-inner'],
                composeStylisticClassesByForce(comp, context, [
                  STYLE_PROPERTY_NAME.MIN_HEIGHT,
                  // STYLE_PROPERTY_NAME.MAX_WIDTH,
                  STYLE_PROPERTY_NAME.ALIGN_ITEMS,
                  STYLE_PROPERTY_NAME.JUSTIFY_CONTENT,
                ]),
                applyTextAlignClasses,
              ],
              comp,
              context,
            )}
          >
            {columns.map((c: IOmniComponent, index: number) => {
              const split = spans[index].split('/')
              const result = parseInt(split[0], 10) / parseInt(split[1], 10)
              const bootstrap = Math.floor(result * 12)
              const widthStyle = { ...getColSpan(isFreeMode, bootstrap, c) }
              return (
                <Col key={c.id} data={c} contextProps={contextProps} widthStyle={widthStyle}>
                  <ComponentFactory data={c} />
                </Col>
              )
            })}
          </div>
        </div>
      </React.Fragment>
    )
  } else if (!publishToday.value) {
    return <React.Fragment></React.Fragment>
  } else {
    return null
  }
}

export default {
  type: ElementType.ROW,
  component: OmniElementRow,
}
