import * as React from 'react'
import { ElementType, IOmniComponent } from '../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'

export interface IProps {
  data: IOmniComponentRenderProps
  contextProps: IOmniPageSharedContextGlobalProps
}

export interface IOmniComponentRenderProps extends IOmniComponent {
  referenceId: string
  parameters?: object | string | undefined | null
}

const ReferenceProps = (props: IProps) => {
  const {
    contextProps,
    data: { referenceId, parameters },
  } = props
  if (referenceId) {
    if (contextProps && contextProps.renderHooks && contextProps.renderHooks[referenceId]) {
      let params: object | undefined | null
      try {
        if (typeof parameters === 'string') {
          params = JSON.parse(parameters)
        } else {
          params = parameters
        }
      } catch (e) {
        console.error('Invalid parameters.')
      }
      return contextProps.renderHooks[referenceId](params || {}, contextProps.locale, contextProps.events)
    }
  }
  return <div />
}

export default {
  type: ElementType.RENDER_PROPS,
  component: ReferenceProps,
}
