import * as React from 'react'

import Shared from '../Shared.context'
import PageComponentPluginFactory from '../Plugins/FrontendPageComponentPluginsFactory'
import { IPluginRenderProps } from '../Plugins/FrontendPageComponentPluginsInterface'
import Banner from './Banner/Banner'
import Row from './Row/Row'
import Column from './Column/Column'
import CodeBlock from './CodeBlock/CodeBlock'
import TextBlock from './TextBlock/TextBlock'
import SlideShow from './SlideShow/SlideShow'
import SlideshowTestimonial from './SlideshowTestimonial/SlideshowTestimonial'
import Separator from './Separator/Separator'
import SingleImage from './SingleImage/SingleImage'
import Video from './Video/Video'
import Button from './Button/Button'
import ImageRow from './ImageRow/ImageRow'
import Curalate from './Curalate/Curalate'
import ImagesWithOverlay from './ImagesWithOverlay/ImagesWithOverlay'
import DynamicAdWidget from './DynamicAdWidget/DynamicAdWidget'
import GeoTagContainer from './GeoTagContainer/GeoTagContainer'
import RenderProps from './RenderProps/RenderProps'
import OpenComponent from './OpenComponent/OpenComponent'
import BlockThumbnails from './BlockThumbnails/BlockThumbnails'
import AttributesList from './AttributesList/AttributesList'
import CategoryThumbnails from './CategoryThumbnails/CategoryThumbnails'
import ProductListing from './ProductListing/ProductListing'
import ImageGallery from './ImageGallery/ImageGallery'
import CartItem from './CartItem/CartItem'
import ProductGroupCarousel from './ProductGroupCarousel/ProductGroupCarousel'
import PDPItem from './PDPItem/PDPItem'
import TextBanner from './TextBanner/TextBanner'

const registry = [
  PDPItem,
  CodeBlock,
  ProductGroupCarousel,
  CartItem,
  Banner,
  SingleImage,
  CategoryThumbnails,
  ProductListing,
  OpenComponent,
  AttributesList,
  BlockThumbnails,
  SlideShow,
  SlideshowTestimonial,
  RenderProps,
  Separator,
  Button,
  Video,
  Row,
  Column,
  TextBlock,
  GeoTagContainer,
  ImageRow,
  DynamicAdWidget,
  Curalate,
  ImagesWithOverlay,
  ImageGallery,
  TextBanner,
]

const findItemByType = (type: any) => {
  let Comp = null
  for (const something of registry) {
    if (something.type === type) {
      Comp = something.component
    }
  }
  return Comp
}

/**
 * renderPluginWrapperHook will generate a div tag that gets wrapped around your component
 * for each plugin that is enabled
 * @param props
 */
const PluginWrapper = (props: IPluginRenderProps) => {
  return PageComponentPluginFactory.hooks.renderPluginWrapperHook(props)
}

export default class OmniComponent extends React.Component<any, any> {
  public render() {
    const Component: any = findItemByType(this.props.data.type)
    return (
      <Shared.Consumer>
        {(contextProps: any) => {
          if (Component) {
            return (
              <PluginWrapper componentData={this.props.data} contextProps={contextProps}>
                <Component data={this.props.data} contextProps={contextProps} />
              </PluginWrapper>
            )
          }
          return <div>Could not find component: {this.props.data.type}</div>
        }}
      </Shared.Consumer>
    )
  }
}
