import { find } from '../../../Common/Utils/lodash'
import { ElementType } from '../../interfaces'
import { STYLE_PROPERTY_NAME } from './styleTypesEnumeration'

export interface IStylePluginProp {
  id: STYLE_PROPERTY_NAME
  allowedTypes?: ElementType[]
  disallowedTypes?: ElementType[]
}

const allowedLookups: IStylePluginProp[] = [
  {
    id: STYLE_PROPERTY_NAME.WIDTH,
    disallowedTypes: [ElementType.COLUMN],
  },
  {
    id: STYLE_PROPERTY_NAME.REAL_WIDTH,
    allowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.HEIGHT,
    disallowedTypes: [ElementType.SPACER, ElementType.SEPARATOR, ElementType.IMAGEROW, ElementType.SINGLE_IMAGE],
  },
  {
    id: STYLE_PROPERTY_NAME.MIN_HEIGHT,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.PADDING,
    disallowedTypes: [ElementType.BUTTON, ElementType.SEPARATOR],
  },
  {
    id: STYLE_PROPERTY_NAME.MARGINS,
    disallowedTypes: [ElementType.SEPARATOR],
  },
  {
    id: STYLE_PROPERTY_NAME.BORDER_RADIUS,
    disallowedTypes: [ElementType.BUTTON, ElementType.SEPARATOR],
  },
  {
    id: STYLE_PROPERTY_NAME.BORDER,
    disallowedTypes: [ElementType.BUTTON, ElementType.SEPARATOR],
  },
  {
    id: STYLE_PROPERTY_NAME.BOX_SHADOW,
    disallowedTypes: [ElementType.BUTTON],
  },
  {
    id: STYLE_PROPERTY_NAME.BACKGROUND_COLOR,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.CUSTOM_BACKGROUND_COLOR,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.TEXT_ALIGN,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.ZINDEX,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.USE_BACKGROUND_IMAGE,
    allowedTypes: [ElementType.ROW, ElementType.COLUMN],
  },
  {
    id: STYLE_PROPERTY_NAME.BACKGROUND_IMAGE_URL,
    allowedTypes: [ElementType.ROW, ElementType.COLUMN],
  },
  {
    id: STYLE_PROPERTY_NAME.USE_PARALLAX,
    allowedTypes: [ElementType.ROW, ElementType.COLUMN],
  },
  {
    id: STYLE_PROPERTY_NAME.OVERFLOW,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.POSITION,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.POSITION_BOTTOM,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.POSITION_LEFT,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.POSITION_RIGHT,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.POSITION_TOP,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.TRANSFORM,
    disallowedTypes: [],
  },
  {
    id: STYLE_PROPERTY_NAME.ALIGN_ITEMS,
    allowedTypes: [ElementType.COLUMN, ElementType.ROW],
  },
  {
    id: STYLE_PROPERTY_NAME.JUSTIFY_CONTENT,
    allowedTypes: [ElementType.COLUMN],
  },
]

export const isPropertyAllowed = (type: ElementType, prop: STYLE_PROPERTY_NAME): boolean => {
  const lookup: IStylePluginProp | undefined = find(allowedLookups, { id: prop })
  if (lookup) {
    if (lookup.allowedTypes) {
      if (lookup.allowedTypes.indexOf(type) !== -1) {
        return true
      }
    }
    if (lookup.disallowedTypes) {
      if (lookup.disallowedTypes.indexOf(type) === -1) {
        return true
      }
    }
  }
  return false
}

export default []
