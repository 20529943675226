import * as React from 'react'
import { ElementType } from '../../interfaces'
import { generateClassName, generateStyleSheet } from '../../Utils/classUtils'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { IImageTransformationProps } from '../../Plugins/ImageTransformation/imageTransformationInterface'
import ImageRowItem from './ImageRowItem'
import css from './ImageRow.module.scss'

/// ------------------------------------------------ MISC INTERFACES -------------------------------------
export interface IOmniComponentImageRowProps extends IImageTransformationProps {
  id: string
  title: string
  titleAlign: {
    value: string
    label: string
  }
  textAlign: {
    value: string
    label: string
  }
  enableNavigation?: boolean
  imagerowelements: IImageRowElement[]
  globalCSS: string
  flexJustification:
    | {
        value: string
        label: string
      }
    | undefined
  border: string
  rolloverEffect: boolean
  backgroundImageSize?:
    | {
        value: string
        label: string
      }
    | undefined
  backgroundPositionX?:
    | {
        value: string
        label: string
      }
    | undefined
  backgroundPositionY?:
    | {
        value: string
        label: string
      }
    | undefined
  height?: string
  mobileDisplay: {
    value: string
    label: string
  }
  desktopDisplay: {
    value: string
    label: string
  }
  carouselGraphics: {
    value: string
    label: string
  }
  imageSpace: number
}

export interface IImageRowElement {
  id: string
  text: {
    html: string
    css: string
  }
  alt: string
  imageUrl: string
  url: string
  height: string
  width: string
  backgroundImageSize:
    | {
        value: string
        label: string
      }
    | undefined
  backgroundPositionX:
    | {
        value: string
        label: string
      }
    | undefined
  backgroundPositionY:
    | {
        value: string
        label: string
      }
    | undefined
}

// ------------------------------------------------------ CLASS ---------------------------------------------
export interface IImageRowProps {
  data: IOmniComponentImageRowProps
  contextProps: IOmniPageSharedContextGlobalProps
}
interface IImageRowState {
  currentImage: number
  stepFromWindowWidth: any
}
class ImageRow extends React.PureComponent<IImageRowProps, IImageRowState> {
  constructor(props: IImageRowProps) {
    super(props)

    this.state = {
      currentImage: 0,
      stepFromWindowWidth: typeof window !== 'undefined' && window.innerWidth > 768 ? 3 : 0,
    }
    typeof window !== 'undefined' ? window.addEventListener('resize', () => this.handleResize()) : null
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.handleResize())
  }

  private handleResize = () => {
    this.setState({ stepFromWindowWidth: typeof window !== 'undefined' && window.innerWidth > 768 ? 3 : 0 })
  }

  // ----------------------------------------------  HELPER METHODS ------------------------------------------
  public incrementSlide = (by: number) => {
    const { data } = this.props
    let { currentImage } = this.state
    const len = data.imagerowelements.length
    currentImage += by !== undefined ? by : 1
    if (currentImage < 0) {
      currentImage = len - 1
    }
    if (currentImage >= len) {
      currentImage = 0
    }
    this.setState({ currentImage })
  }
  public goToImage = (index: number) => {
    this.setState({
      currentImage: index,
    })
  }
  /// ----------------------------------------------- RENDER METHODS -----------------------------------------
  public applyFlexJustification = () => {
    const { flexJustification } = this.props.data
    if (flexJustification && flexJustification.value) {
      return {
        alignItems: `${flexJustification.value} !important`,
        justifyContent: `${flexJustification.value} !important`,
      }
    }
    return {}
  }
  public render() {
    const { data } = this.props
    if (!data) {
      return <div>Loading...</div>
    }

    const { currentImage } = this.state
    const titleAlign = css[data.titleAlign.value] || css.left
    const isDesktopCarousel = data.desktopDisplay && data.desktopDisplay.value === 'carousel'
    const isMobileCarousel = data.mobileDisplay && data.mobileDisplay.value === 'carousel'
    let isNavigation = false
    if (!data.desktopDisplay && !data.mobileDisplay) {
      isNavigation = data.enableNavigation ? data.enableNavigation : false
    }
    if (isDesktopCarousel || isMobileCarousel) {
      isNavigation = true
    }
    let isDotNavigation = false
    if (data.carouselGraphics && isNavigation) {
      isDotNavigation = data.carouselGraphics && data.carouselGraphics.value === 'dots'
    }
    let isArrowsNavigation = false
    if (data.carouselGraphics && isNavigation) {
      isArrowsNavigation = data.carouselGraphics && data.carouselGraphics.value === 'arrows'
    }
    const oneColumn = data.mobileDisplay && data.mobileDisplay.value === 'columm' ? css.stackedImageRow : ''
    let stackContainerClass = !data.enableNavigation ? css.stackedImageRow : ''
    const biColumn = data.mobileDisplay && data.mobileDisplay.value === 'bicolumn' ? css.biColumnContainer : ''
    if (biColumn) {
      stackContainerClass = ''
    }
    const inlineStyle: any = {}
    const flexJustClass = generateClassName(inlineStyle, `${this.props.data.id}-parallax-outer`, {
      ...this.applyFlexJustification(),
    })
    const dotNavigationClass = `
      ${data.enableNavigation && !data.carouselGraphics ? css.dotMobile : ''}
      ${isDotNavigation && isMobileCarousel ? css.dotMobile : ''}
      ${isDotNavigation && isDesktopCarousel ? css.dotDesktop : ''}
    `
    const arrowNavigationClass = `
      ${data.enableNavigation && !data.carouselGraphics ? css.arrowMobile : ''}
      ${isArrowsNavigation && isMobileCarousel ? css.arrowMobile : ''}
      ${isArrowsNavigation && isDesktopCarousel ? css.arrowDesktop : ''}
    `

    const translate =
      this.state.stepFromWindowWidth === 0
        ? {
            transform: `translateX(-${currentImage * 100}%) translateX(-${
              this.state.stepFromWindowWidth === 0 ? 0 : currentImage * 30
            }px)`,
          }
        : { undefined }

    return (
      <React.Fragment>
        <this.props.contextProps.RenderHead>
          <style
            dangerouslySetInnerHTML={{
              __html: generateStyleSheet(inlineStyle),
            }}
          />
          {this.props.data.globalCSS && (
            <style
              dangerouslySetInnerHTML={{
                __html: this.props.data.globalCSS,
              }}
            />
          )}
        </this.props.contextProps.RenderHead>
        <div className={css.topLevelContainer}>
          {data.title && (
            <div className={css.rowTitle}>
              <h2 className={titleAlign}>{data.title}</h2>
            </div>
          )}
          <div className={`${css.imageRowContainer}`}>
            <div className={`${css.innerFlex} ${flexJustClass} ${stackContainerClass} ${oneColumn} ${biColumn}`}>
              {data.imagerowelements.map((item: IImageRowElement, index: number) => {
                let showImage = true
                if (!data.desktopDisplay && !data.mobileDisplay) {
                  showImage = (data.enableNavigation && currentImage === index) || !data.enableNavigation
                }
                return (
                  <ImageRowItem
                    contextProps={this.props.contextProps}
                    key={index}
                    item={item}
                    isCurrentImage={currentImage === index}
                    show={showImage}
                    incrementSlide={this.incrementSlide}
                    translate={translate}
                    globalStyles={{
                      ...this.props.data,
                    }}
                  />
                )
              })}
            </div>
            {data.imagerowelements.length > 1 && isNavigation && (
              <React.Fragment>
                <a className={`${css.prev} ${arrowNavigationClass}`} onClick={this.incrementSlide.bind(this, -1)}>
                  &#10094;
                </a>
                <a className={`${css.next} ${arrowNavigationClass}`} onClick={this.incrementSlide.bind(this, 1)}>
                  &#10095;
                </a>
              </React.Fragment>
            )}
          </div>
          {data.imagerowelements.length > 1 && isNavigation && (
            <React.Fragment>
              <div className={`${css.dotContainer} ${dotNavigationClass}`}>
                {data.imagerowelements.map((_: IImageRowElement, index: number) => {
                  const activeClass = index === currentImage ? css.active : ''
                  return (
                    <span
                      key={index}
                      data-index={index}
                      className={`${css.dot} ${activeClass}`}
                      onClick={this.goToImage.bind(this, index)}
                    />
                  )
                })}
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default {
  type: ElementType.IMAGEROW,
  component: ImageRow,
}
