import * as React from 'react'
import { ElementType } from '../../../interfaces'

export interface IOmniElementVideoProps {
  videoUrl: string
  height: string
  width: string
}
export interface IProps {
  data: IOmniElementVideoProps
}
// interface IState {
//   isHover: boolean
// }

const IOmniElementVideo = (props: IProps) => {
  return (
    <div
      className="video-responsive"
      style={{
        overflow: 'hidden',
        paddingBottom: '56.25%',
        position: 'relative',
        height: 0,
      }}
    >
      <iframe
        width="100%"
        height="100%"
        src={props.data.videoUrl}
        frameBorder="0"
        style={{ left: 0, top: 0, height: '100%', width: '100%', position: 'absolute' }}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  )
}

export default {
  type: ElementType.VIDEO,
  component: IOmniElementVideo,
}
