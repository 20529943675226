// import { IOption } from '../../../../../../../Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import * as React from 'react'
import { IPageComponentPlugin, IPluginRenderProps } from '../FrontendPageComponentPluginsInterface'
import { PAGE_COMPONENT_PLUGIN_TYPE } from '../FrontendPageComponentPluginTypes'
import { IOmniComponent } from '../../interfaces'

const applyId = (comp: IOmniComponent) => {
  const { elementId } = comp
  if (elementId) {
    return {
      id: elementId,
    }
  }
  return {}
}

const renderPluginWrapperHook = (props: IPluginRenderProps): JSX.Element => {
  const { elementId, additionalClasses } = props.componentData
  if (elementId || additionalClasses) {
    return (
      <span {...applyId(props.componentData)} className={`identifier-plugin ${additionalClasses ?? ''}`}>
        {props.children}
      </span>
    )
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

const plugin: IPageComponentPlugin = {
  enabled: true,
  type: PAGE_COMPONENT_PLUGIN_TYPE.IDENTIFIERS,
  hooks: {
    renderPluginWrapperHook,
  },
}

export default plugin
