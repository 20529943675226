import React from 'react'

import { ISlideShowDotProps } from './interfaces'

const SlideShowDot = ({ index, className, onClick }: ISlideShowDotProps) => (
  <span
    key={index}
    data-index={index}
    className={className}
    onClick={() => {
      onClick(index)
    }}
  />
)

export default SlideShowDot
