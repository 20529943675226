import * as React from 'react'
import {
  IPageFrontendComponentPluginHooks,
  IPageComponentPlugin,
  IPluginRenderProps,
} from './FrontendPageComponentPluginsInterface'

// -------------------  plugins: load all your plugins here
import VisibilityBreakpoint from './VisibilityBreakpoints'
import Animations from './Animations'
import Identifiers from './Identifiers'
import Styles from './Styles'
// --------------------- end plugins

// register your plugins
const registeredPlugins: IPageComponentPlugin[] = [VisibilityBreakpoint, Animations, Identifiers, Styles]

/**
 * gets called for each component that is rendered on the webpage
 * If a plugin is enabled and allowed to work with the content type, a custom wrapper will be generated
 * @param props
 */
const renderPluginWrapperHook = (props: IPluginRenderProps): JSX.Element => {
  const enabledPlugins: IPageComponentPlugin[] = registeredPlugins.filter((p: IPageComponentPlugin) => {
    let bypass = props.bypassPlugins || []
    return p.enabled && bypass.indexOf(p.type) == -1
  })
  let i: number = enabledPlugins.length
  let snowball: JSX.Element = props.children
  while (i--) {
    snowball = enabledPlugins[i].hooks.renderPluginWrapperHook({ ...props, children: snowball })
    enabledPlugins.splice(i, 1)
  }
  return <React.Fragment>{snowball}</React.Fragment>
}

const PageComponentPluginFactory = {
  registeredPlugins,
  hooks: {
    renderPluginWrapperHook,
  } as IPageFrontendComponentPluginHooks,
}

export default PageComponentPluginFactory
