import * as React from 'react'
import OmniLayoutManager from './OmniLayoutManager'
import { IOmniComponentLookup } from './interfaces'
import Shared, { IOmniPageSharedConfigurationProps } from './Shared.context'

interface IProps extends IOmniPageSharedConfigurationProps {
  data: IOmniComponentLookup
  locale?: string
  version?: string
}

const OmniPage = (props: IProps) => {
  const {
    data,
    locationData,
    previewDate,
    renderHooks,
    transport,
    Head,
    page,
    cache,
    events,
    env,
    locale,
    imageTransformationProxyUrl,
    imageTransformationDefaultSettings,
    activeTheme,
    studioEditor,
    simulatedDevice,
    overrideImageTransformationUrlHook
  } = props
  return (
    <Shared.Provider
      previewDate={previewDate}
      locationData={locationData}
      renderHooks={renderHooks}
      transport={transport}
      page={page}
      env={env}
      events={events}
      cache={cache}
      Head={Head}
      locale={locale}
      imageTransformationProxyUrl={imageTransformationProxyUrl}
      imageTransformationDefaultSettings={imageTransformationDefaultSettings}
      activeTheme={activeTheme}
      studioEditor={studioEditor}
      simulatedDevice={simulatedDevice}
      overrideImageTransformationUrlHook={overrideImageTransformationUrlHook}
    >
      <OmniLayoutManager preLoadedData={data} version={props.version} />
    </Shared.Provider>
  )
}

export default OmniPage
