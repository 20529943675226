import * as React from 'react'
import { IPageComponentPlugin, IPluginRenderProps } from '../FrontendPageComponentPluginsInterface'
import { PAGE_COMPONENT_PLUGIN_TYPE } from '../FrontendPageComponentPluginTypes'
import { ElementType, IOmniComponent } from '../../interfaces'
import { generateStyleSheet, generateClassName } from '../../Utils/classUtils'
import Parallax from './BackgroundImage/ParallaxV2'
import css from './styles-plugin.scss'
import { isPropertyAllowed } from './columnRestrictionsV2'
import { STYLE_PROPERTY_NAME } from './styleTypesEnumeration'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { THEME_WRAPPER_TAGS } from '../../../Common/Theme/ThemeWrapper'
import { ISiteTheme } from '../../../Common/Interfaces/Strapi/Site'
import { applyVisibilityBreakpointClasses } from '../VisibilityBreakpoints'
import appendImageTransformationProxyUrl from '../ImageTransformation/imageTransformationUtils'

// ------------------------------------------------- CLASSES ------------------------------------------------

const applyUserGeneratedClasses = (comp: IOmniComponent) => {
  const { additionalClasses } = comp
  if (additionalClasses) {
    return additionalClasses
  }
  return ''
}
// ------------------------------------------------- ID ------------------------------------------------

const applyId = (comp: IOmniComponent) => {
  const { elementId } = comp
  if (elementId) {
    return {
      id: elementId,
    }
  }
  return {}
}

// ------------------------------------------------- WIDTH ------------------------------------------------
const applyWidth = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { width } = comp
  const styles: React.CSSProperties = {}
  if (comp.type === ElementType.SINGLE_IMAGE) {
    return {
      width: '100%',
    }
  }
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.WIDTH)) {
    styles.width = width === undefined ? '100%' : width
  }
  return styles
}

const applyRealWidth = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { realWidth } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.REAL_WIDTH)) {
    styles.width = realWidth === undefined ? '100%' : realWidth
  }
  return styles
}

// ------------------------------------------------- Height ------------------------------------------------
const applyHeight = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { height } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.HEIGHT) && height) {
    styles.maxHeight = height === '0px' || height === '0' ? 'initial' : height
    //styles.overflow = 'auto'
  }
  return styles
}

// ------------------------------------------------- Min Height ------------------------------------------------
const applyMinHeight = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { minHeight } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.MIN_HEIGHT) && minHeight) {
    styles.minHeight = minHeight === '0px' || minHeight === '0' ? 'initial' : minHeight
    //styles.overflow = 'auto'
  }
  return styles
}

// ------------------------------------------------- OVERFLOW ------------------------------------------------
const applyOverflow = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { overflowX, overflowY } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.OVERFLOW)) {
    if (overflowX?.value) {
      styles.overflowX = overflowX.value
    }
    if (overflowY?.value) {
      styles.overflowY = overflowY.value
    }
  }
  return styles
}

// ------------------------------------------------- Position ------------------------------------------------
const applyPosition = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { position, top, left, right, bottom, transform } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.POSITION)) {
    if (position?.value) {
      styles.position = position?.value
    }
    if (top !== undefined) {
      styles.top = top
    }
    if (left !== undefined) {
      styles.left = left
    }
    if (right !== undefined) {
      styles.right = right
    }
    if (bottom !== undefined) {
      styles.bottom = bottom
    }

    if (transform !== undefined) {
      styles.transform = `translate(${transform})`
    }
  }
  return styles
}

// ------------------------------------------------ BACKGROUND COLOR --------------------------------------
const applyCustomBackgroundColor = (
  comp: IOmniComponent,
  _allowedProps?: STYLE_PROPERTY_NAME[],
): React.CSSProperties => {
  const { backgroundColor, customBackgroundColor } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.BACKGROUND_COLOR)) {
    if (backgroundColor !== undefined) {
      if (typeof backgroundColor === 'string') {
        styles.backgroundColor = backgroundColor
      } else {
        if (
          backgroundColor.value === 'customColorBackground' &&
          customBackgroundColor !== undefined &&
          typeof customBackgroundColor === 'string'
        ) {
          styles.backgroundColor = customBackgroundColor
        }
      }
    }
  }
  return styles
}

const mapBackgroundColorOptionsToColor = (theme: ISiteTheme | null | undefined, tag: THEME_WRAPPER_TAGS): string => {
  if (!theme) {
    return ''
  }
  switch (tag) {
    case THEME_WRAPPER_TAGS.PRIMARY_BACKGROUND_COLOR:
      return theme.primaryColor || ''
    case THEME_WRAPPER_TAGS.SECONDARY_BACKGROUND_COLOR:
      return theme.secondaryColor || ''
    case THEME_WRAPPER_TAGS.TERTIARY_BACKGROUND_COLOR:
      return theme.tertiaryColor || ''
    case THEME_WRAPPER_TAGS.QUATERNARY_BACKGROUND_COLOR:
      return theme.quaternaryColor || ''
    case THEME_WRAPPER_TAGS.QUINARY_BACKGROUND_COLOR:
      return theme.quinaryColor || ''
    case THEME_WRAPPER_TAGS.SENARY_BACKGROUND_COLOR:
      return theme.senaryColor || ''
    default:
      return ''
  }
}

const applyBackgroundColor = (
  comp: IOmniComponent,
  contextProps: IOmniPageSharedContextGlobalProps,
  _allowedProps?: STYLE_PROPERTY_NAME[],
): React.CSSProperties => {
  const styles: React.CSSProperties = {}
  const { backgroundColor } = comp
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.BACKGROUND_COLOR)) {
    if (backgroundColor) {
      if (typeof backgroundColor !== 'string') {
        if (backgroundColor.value !== 'customColorBackground' && contextProps?.activeTheme) {
          let targetColor: string = mapBackgroundColorOptionsToColor(contextProps?.activeTheme, backgroundColor.value)
          if (targetColor) {
            styles.backgroundColor = targetColor
            return styles
          }
        }
        if (backgroundColor.color) {
          styles.backgroundColor = backgroundColor.color
        }
      }
    }
  }
  return styles
}

// ----------------------------------------------- Z-INDEX ------------------------------------------------
const applyZIndex = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { zIndex } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.ZINDEX)) {
    if (zIndex !== undefined && zIndex !== '') {
      styles.zIndex = parseInt(zIndex)
      styles.position = 'relative'
    }
  }
  return styles
}

// ----------------------------------------------- BORDER ------------------------------------------------
const applyBorder = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { border } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.BORDER)) {
    if (border !== undefined) {
      if (typeof border === 'string') {
        styles.border = border
      }
    }
  }
  return styles
}

// ----------------------------------------------- BORDER RADIUS ------------------------------------------------
const applyBorderRadius = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { borderRadius } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.BORDER_RADIUS)) {
    if (borderRadius !== undefined) {
      if (typeof borderRadius === 'string') {
        styles.borderRadius = borderRadius
      }
    }
  }
  return styles
}

// ----------------------------------------------- BOX SHADOW ------------------------------------------------
const applyBoxShadow = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { boxShadow } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.BOX_SHADOW)) {
    if (boxShadow) {
      if (typeof boxShadow === 'object') {
        styles.boxShadow = boxShadow?.value || 'none'
      } else if (typeof boxShadow === 'string') {
        styles.boxShadow = boxShadow
      }
    }
  }
  return styles
}

// -------------------------------------------- TEXT ALIGN ----------------------------------------------------
export const applyTextAlignClasses = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): string => {
  const { textAlign } = comp
  const compType = comp.type !== ElementType.ROW && comp.type !== ElementType.COLUMN ? 'ELEMENT' : comp.type
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.TEXT_ALIGN)) {
    if (textAlign && typeof textAlign === 'object') {
      if (textAlign?.value && textAlign?.value !== 'initial') {
        return css[`alignText_${compType}_${textAlign.value}`]
      }
    }
  }
  return ''
}

// --------------------------------------------PADDING ----------------------------------------------------
const applyPadding = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { padding } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.PADDING) && padding) {
    styles.padding = padding
  }
  return styles
}

// --------------------------------------------MARGIN ----------------------------------------------------
const applyMargin = (comp: IOmniComponent, _allowedProps?: STYLE_PROPERTY_NAME[]): React.CSSProperties => {
  const { margins } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.MARGINS) && margins) {
    styles.margin = margins
  }
  return styles
}

// --------------------------------------------VERTICAL ALIGNMENT ----------------------------------------------------
const verticalAlignment = (
  comp: IOmniComponent,
  contextProps: IOmniPageSharedContextGlobalProps,
  _allowedProps?: STYLE_PROPERTY_NAME[],
): React.CSSProperties => {
  const { alignItems } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.ALIGN_ITEMS) && alignItems) {
    if (comp.type === ElementType.COLUMN && !contextProps.studioEditor) {
      styles.justifyContent = alignItems.value
    } else {
      styles.alignItems = alignItems.value
    }
  }
  return styles
}

// --------------------------------------------Horizontal Justify ----------------------------------------------------
const horizontalJustify = (
  comp: IOmniComponent,
  contextProps: IOmniPageSharedContextGlobalProps,
  _allowedProps?: STYLE_PROPERTY_NAME[],
): React.CSSProperties => {
  const { justifyContent } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.JUSTIFY_CONTENT) && justifyContent) {
    if (comp.type === ElementType.COLUMN && !contextProps.studioEditor) {
      styles.alignItems = justifyContent.value
    } else {
      styles.justifyContent = justifyContent.value
    }
  }
  return styles
}

const applyBackgroundImage = (
  componentData: IOmniComponent,
  contextProps: IOmniPageSharedContextGlobalProps,
  _allowedProps?: STYLE_PROPERTY_NAME[],
): React.CSSProperties => {
  const {
    useBackgroundImage,
    backgroundImage,
    backgroundImagePositionX,
    backgroundImagePositionY,
    backgroundImageAttachment,
    backgroundImageRepeat,
    backgroundImageSize,
  } = componentData
  const formatBackgroundImage = (url: string) => {
    return appendImageTransformationProxyUrl({
      targetUrl: url as string,
      context: contextProps,
      data: componentData as any,
    })
  }
  if (useBackgroundImage && backgroundImage) {
    if (useBackgroundImage.value) {
      return {
        backgroundImage: `url('${formatBackgroundImage(backgroundImage)}')`,
        backgroundSize: backgroundImageSize?.value ?? 'initial',
        backgroundPositionX: backgroundImagePositionX?.value ?? 'initial',
        backgroundPositionY: backgroundImagePositionY?.value ?? 'initial',
        backgroundAttachment: backgroundImageAttachment?.value ?? 'initial',
        backgroundRepeat: backgroundImageRepeat?.value ?? 'initial',
      }
    }
  }
  return {}
}

// ------------------------------------------- END ------------------------------------------------------------

export const convertStylesIntoObject = (
  comp: IOmniComponent,
  contextProps: IOmniPageSharedContextGlobalProps,
  allowedProps?: STYLE_PROPERTY_NAME[],
): React.CSSProperties => {
  return {
    ...applyBoxShadow(comp, allowedProps),
    ...applyZIndex(comp, allowedProps),
    ...applyBackgroundColor(comp, contextProps, allowedProps),
    ...applyCustomBackgroundColor(comp, allowedProps),
    ...applyMargin(comp, allowedProps),
    ...applyPadding(comp, allowedProps),
    ...applyBorderRadius(comp, allowedProps),
    ...applyBorder(comp, allowedProps),
    ...applyWidth(comp, allowedProps),
    ...applyRealWidth(comp, allowedProps),
    ...applyHeight(comp, allowedProps),
    ...applyMinHeight(comp, allowedProps),
    ...applyPosition(comp, allowedProps),
    ...applyOverflow(comp, allowedProps),
    ...applyBackgroundImage(comp, contextProps),
    ...verticalAlignment(comp, contextProps, allowedProps),
    ...horizontalJustify(comp, contextProps, allowedProps),
  }
}

/**
 * Utility method to attach an ID to component based on input from a User in Studio
 * @param component
 * @returns
 */
export const composeIdentifier = (component: IOmniComponent) => {
  return applyId(component)
}

/**
 *
 * @param component
 * @returns
 */
export const composeUserGeneratedClasses = (component: IOmniComponent): string => {
  return applyUserGeneratedClasses(component)
}

/**
 *
 * @param component
 * @param contextProps
 * @returns
 */
export const composeResponsiveClasses = (
  component: IOmniComponent,
  contextProps: IOmniPageSharedContextGlobalProps,
): string => {
  const wrapperClass: string = applyVisibilityBreakpointClasses(
    component.visibilityBreakpoint,
    contextProps?.studioEditor || false,
  )
  if (wrapperClass) {
    return wrapperClass
  }
  return ''
}

/**
 *
 * @param component
 * @param contextProps
 * @returns
 */
export const composeStylisticClassesByType = (
  component: IOmniComponent,
  contextProps: IOmniPageSharedContextGlobalProps,
): string => {
  const innerStylesObject: React.CSSProperties = convertStylesIntoObject(component, contextProps)
  return convertStyleIntoClass(innerStylesObject, `style-${component.type}-${component.id}`)
}

/**
 *
 * @param component
 * @param contextProps
 * @param allowedProps
 * @returns
 */
export const composeStylisticClassesByForce = (
  component: IOmniComponent,
  contextProps: IOmniPageSharedContextGlobalProps,
  allowedProps: STYLE_PROPERTY_NAME[],
): string => {
  const innerStylesObject: React.CSSProperties = convertStylesIntoObject(component, contextProps, allowedProps)
  return convertStyleIntoClass(innerStylesObject, `style-${component.type}-${component.id}`)
}

export const setPluginStyleClass = (
  component: IOmniComponent,
  contextProps: IOmniPageSharedContextGlobalProps,
  // allowedProps: STYLE_PROPERTY_NAME[],
): any => {
  const innerStylesObject: React.CSSProperties = convertStylesIntoObject(component, contextProps)
  let pointerObject: any = {}
  generateClassName(pointerObject, `style-${component.type}-${component.id}`, innerStylesObject)
  return pointerObject
}

/**
 *
 * @param obj
 * @param className
 * @param context
 * @returns
 */
export const convertStyleIntoClass = (styles: React.CSSProperties, className: string): string => {
  let pointerObject: any = {}
  const innerClassFromStylesheet = generateClassName(pointerObject, className, styles)
  return `${innerClassFromStylesheet}`.trim()
}

/**
 *
 * @param params
 * @param component
 * @param contextProps
 * @returns
 */
export const composeAndChainClasses = (
  params: any[],
  component: IOmniComponent,
  contextProps: IOmniPageSharedContextGlobalProps,
) => {
  let classes: string[] = []
  for (const param of params) {
    if (typeof param === 'string') {
      classes.push(param)
    } else {
      let targetClass: string = param(component, contextProps)
      if (targetClass) {
        classes.push(targetClass)
      }
    }
  }
  let finalClass = classes.join(' ').trim()
  if (finalClass) {
    return {
      className: finalClass,
    }
  }
  return {}
}

const renderPluginWrapperHook = (props: IPluginRenderProps): JSX.Element => {
  const component: IOmniComponent = props.componentData
  const innerStylesObject: React.CSSProperties = convertStylesIntoObject(component, props.contextProps)
  //we don't want styles attached directly to the html elements so let's generate a style tag and attach to head
  const pointerStyles = {}
  const innerClassFromStylesheet = generateClassName(
    pointerStyles,
    `custom-inner-style-${component.type}-${component.id}`,
    innerStylesObject,
  )
  return (
    <React.Fragment>
      {props.contextProps?.RenderHead ? (
        <props.contextProps.RenderHead>
          <style
            dangerouslySetInnerHTML={{
              __html: generateStyleSheet(pointerStyles),
            }}
          />
        </props.contextProps.RenderHead>
      ) : (
        <style
          dangerouslySetInnerHTML={{
            __html: generateStyleSheet(pointerStyles),
          }}
        />
      )}

      <Parallax {...props}>
        <div
          {...applyId(component)}
          className={`styles-plugin-inner ${applyTextAlignClasses(
            component,
          )} ${innerClassFromStylesheet} ${applyUserGeneratedClasses(component)} `}
        >
          {props.children}
        </div>
      </Parallax>
    </React.Fragment>
  )
}

const plugin: IPageComponentPlugin = {
  enabled: true,
  type: PAGE_COMPONENT_PLUGIN_TYPE.STYLES,
  hooks: {
    renderPluginWrapperHook,
  },
}

export default plugin
