import * as React from 'react'
import Shared from '../../Shared.context'
import Row from './Row/Row'
import Column from './Column/Column'
import Button from './Button/Button'
import TextBlock from './TextBlock/TextBlock'
import SlideShow from './SlideShow/SlideShow'
import SingleImage from './SingleImage/SingleImage'
import ImageRow from './ImageRow/ImageRow'
import Banner from './Banner/Banner'
import DynamicAdWidget from './DynamicAdWidget/DynamicAdWidget'
import Video from './Video/Video'
import TextBanner from './TextBanner/TextBanner'
import SlideshowTestimonial from './SlideshowTestimonial/SlideshowTestimonial'
import ImagesWithOverlay from './ImagesWithOverlay/ImagesWithOverlay'
import RenderProps from './RenderProps/RenderProps'
import ImageGallery from './ImageGallery/ImageGallery'
import PDPItem from './PDPItem/PDPItem'
import Spacer from './Spacer/Spacer'
import Divider from './Divider/Divider'
import CodeBlock from './CodeBlock/CodeBlock'
import BlockThumbnails from './BlockThumbnails/BlockThumbnails'
import { ElementType } from '../../interfaces'
import { Element } from './BaseElementWrapper/BaseElementWrapper'

const registry = [
  Divider,
  CodeBlock,
  Video,
  TextBanner,
  SlideshowTestimonial,
  Row,
  PDPItem,
  ImagesWithOverlay,
  ImageGallery,
  RenderProps,
  Column,
  Button,
  TextBlock,
  SlideShow,
  SingleImage,
  ImageRow,
  Banner,
  DynamicAdWidget,
  Spacer,
  BlockThumbnails,
]

const findItemByType = (type: any) => {
  let Comp = null
  for (const something of registry) {
    if (something.type === type) {
      Comp = something.component
    }
  }
  return Comp
}

export default class OmniComponent extends React.Component<any, any> {
  public render() {
    const Component: any = findItemByType(this.props.data.type)
    return (
      <Shared.Consumer>
        {(contextProps: any) => {
          if ((this.props.data.type === ElementType.ROW || this.props.data.type === ElementType.COLUMN) && Component) {
            return <Component data={this.props.data} contextProps={contextProps} />
          } else if (Component) {
            return (
              <Element data={this.props.data} contextProps={contextProps}>
                <Component data={this.props.data} contextProps={contextProps} />
              </Element>
            )
          }
          return <div>Could not find component: {this.props.data.type}</div>
        }}
      </Shared.Consumer>
    )
  }
}
