/* global document */
import * as React from 'react'
import { ElementType, IOmniComponent } from '../../interfaces'

export interface IProps {
  data: IOmniComponentOpenComponentProps
}

export interface IOmniComponentOpenComponentProps extends IOmniComponent {
  registryEndpoint: string
  ocEndpoint: string
}

const loadScript = (registryEndpoint: string) => {
  if (process.browser) {
    const script = document.createElement('script')
    script.src = `${registryEndpoint}/oc-client/client.js`
    document.body.appendChild(script)
    /// script 2
    const script2 = document.createElement('script')
    script2.innerHTML = `window.oc=window.oc||{};oc.conf=oc.conf||{};oc.conf.templates=(oc.conf.templates||[]).concat([{"type":"oc-template-typescript-react","version":"1.0.1","externals":[{"global":["Object","assign"],"url":"https://unpkg.com/es6-object-assign@1.1.0/dist/object-assign-auto.min.js","name":"Object.assign"}]},{"type":"oc-template-es6","version":"1.0.1","externals":[]},{"type":"oc-template-jade","version":"6.0.13","externals":[{"global":"jade","url":"https://unpkg.com/jade-legacy@1.11.1/runtime.js","name":"jade"}]},{"type":"oc-template-handlebars","version":"6.0.14","externals":[{"global":"Handlebars","url":"https://unpkg.com/handlebars@4.0.13/dist/handlebars.runtime.min.js","name":"handlebars"}]},{"type":"oc-template-react","version":"2.0.16","externals":[{"global":["Object","assign"],"url":"https://unpkg.com/es6-object-assign@1.1.0/dist/object-assign-auto.min.js","name":"Object.assign"},{"global":"PropTypes","url":"https://unpkg.com/prop-types@15.7.2/prop-types.min.js","name":"prop-types"},{"global":"React","url":"https://unpkg.com/react@16.8.5/umd/react.production.min.js","name":"react"},{"global":"ReactDOM","url":"https://unpkg.com/react-dom@16.8.5/umd/react-dom.production.min.js","name":"react-dom"}]}]);`
    document.body.appendChild(script2)
  }
}

const OpenComponent = (props: IProps) => {
  const { registryEndpoint, ocEndpoint } = props.data
  return (
    <React.Fragment>
      <div>
        <oc-component href={`${ocEndpoint}`} />
        {loadScript(registryEndpoint)}
      </div>
    </React.Fragment>
  )
}

export default {
  type: ElementType.OPEN_COMPONENT,
  component: OpenComponent,
}
