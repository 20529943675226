import * as React from 'react'

import { ITestimonalSlideProps } from './interfaces'
import css from './TestimonialSlide.module.scss'

const TestimonialSlide = (props: ITestimonalSlideProps) => {
  const { item, fontSize } = props
  const style = fontSize ? { fontSize } : {}

  return (
    <div className={`${css.slideContent}`} style={style}>
      {!!item.url && <a href={item.url} rel="noopener noreferrer" target="_blank" aria-label={item.quote} />}
      <p className={css.slideQuote}>
        <i className={`${css.slideQuoteIcon} fa fa-quote-left`} aria-hidden="true" />
        {item.quote}
        <i className={`${css.slideQuoteIcon} fa fa-quote-right`} aria-hidden="true" />
      </p>
      <p className={css.slideQuotee}>- {item.quotee},</p>
      <p className={css.slideQuotee}>{item.quoteeInfo}</p>
      <img src={item.imageUrl} alt={item.alt} className={css.slideImage} />
    </div>
  )
}

export default TestimonialSlide
