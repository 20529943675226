import * as React from 'react'
import { chunk } from '../Utils/lodash'
import { Row, Col } from '../Layout'

interface IItem {
  [x: string]: any
}
export interface IMerceGridProps {
  items: IItem[]
  columns?: string
  renderProps: {
    renderThumbnail: (item: any) => JSX.Element
  }
}

const getDefaultColSpan = (rowCount: string): number => {
  if (rowCount) {
    switch (rowCount) {
      case '3':
        return 4
      case '4':
        return 3
      case '6':
        return 2
    }
  }
  return 4
}

const MerceGrid = (props: IMerceGridProps) => {
  const columns = props.columns !== undefined ? props.columns : '3'
  const { items } = props
  const rows: IItem[][] = chunk(items, parseInt(columns, 10))
  return (
    <div>
      {rows.map((rowItems: IItem[], index: number) => {
        return (
          <Row key={index} margins>
            {rowItems.map((item: any, index2: number) => {
              return (
                <Col key={index2} sm={getDefaultColSpan(columns)}>
                  {props.renderProps.renderThumbnail(item)}
                </Col>
              )
            })}
          </Row>
        )
      })}
    </div>
  )
}

export default MerceGrid
