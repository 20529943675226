import stringToSlug from './slugify'

const generateProductLink = (product: any, prefix?: string) => {
  if(product) {
    const { sku, modelnumber, name } = product
    
    const modalTag: string = modelnumber !== undefined ? `-${modelnumber}` : ''
    const nameTag: string = name !== undefined ? `-${stringToSlug(name)}` : ''
    return `/${prefix || 'pdp'}/${sku}${modalTag}${nameTag}`
  }

  return ''
}

export default generateProductLink
