import * as React from 'react'
import { generateStyleSheet, generateClassName } from '../../Utils/classUtils'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import appendImageTransformationProxyUrl from '../../Plugins/ImageTransformation/imageTransformationUtils'
import css from './ImageRow.module.scss'
import { IImageRowElement, IOmniComponentImageRowProps } from './ImageRow'

export interface IImageRowItemProps {
  item: IImageRowElement
  isCurrentImage: boolean
  show: boolean
  globalStyles: IOmniComponentImageRowProps
  incrementSlide: (by: number) => void
  translate: {}
  contextProps: IOmniPageSharedContextGlobalProps
}

const ImageRowItem = (props: IImageRowItemProps) => {
  const [touchMove, setTouchMove] = React.useState<number | null>(null)
  const [touchStart, setTouchStart] = React.useState<number | null>(null)

  const formatBackgroundImage = (url: string) => {
    return appendImageTransformationProxyUrl({
      targetUrl: url,
      context: props.contextProps,
      data: props.globalStyles,
    })
  }

  const { item, show, incrementSlide, translate } = props
  const {
    rolloverEffect,
    textAlign,
    backgroundPositionX,
    backgroundImageSize,
    backgroundPositionY,
    height,
    imageSpace,
    desktopDisplay,
    mobileDisplay,
  } = props.globalStyles

  const margins = `0px ${Math.floor(imageSpace / 2)}px`
  let targetHeight = '250px'
  if (height) {
    targetHeight = height
  } else if (item.height) {
    targetHeight = item.height
  }
  const backgroundPosition: {
    x: string
    y: string
  } = { x: 'left', y: 'top' }
  if (backgroundPositionX && backgroundPositionX.value) {
    backgroundPosition.x = backgroundPositionX.value
  } else if (item.backgroundPositionX && item.backgroundPositionX.value) {
    backgroundPosition.x = item.backgroundPositionX.value
  }
  if (backgroundPositionY && backgroundPositionY.value) {
    backgroundPosition.y = backgroundPositionY.value
  } else if (item.backgroundPositionY && item.backgroundPositionY.value) {
    backgroundPosition.y = item.backgroundPositionY.value
  }

  let imageMargins = '0px'
  if (imageSpace) {
    imageMargins = `0xp ${imageMargins}px`
  }
  let imageSize = backgroundImageSize
  if (item.backgroundImageSize) {
    imageSize = item.backgroundImageSize
  }
  const inlineItemStyle: any = {}
  const imageRowItemClass = generateClassName(inlineItemStyle, `${item.id}-imageRowItem`, {
    height: targetHeight,
    backgroundImage: `url('${formatBackgroundImage(item.imageUrl)}')`,
    backgroundSize: item.width ? item.width : imageSize ? imageSize.value : 'initial',
    backgroundPosition: `${backgroundPosition.x} ${backgroundPosition.y}`,
    margin: imageMargins,
  })
  const borderClass = generateClassName(inlineItemStyle, `${item.id}-border`, {
    padding: '15px !important',
    margin: margins,
  })

  const isDesktopCarousel = desktopDisplay && desktopDisplay.value === 'carousel'
  const isMobileCarousel = mobileDisplay && mobileDisplay.value === 'carousel'
  const oneColumn = mobileDisplay && mobileDisplay.value === 'columm' ? css.oneColumn : ''
  const biColumn = mobileDisplay && mobileDisplay.value === 'bicolumn' ? css.biColumn : ''

  let shouldShow = show
  if (isDesktopCarousel || isMobileCarousel) {
    shouldShow = props.isCurrentImage
  }

  const topLevelClasses = `image-row-background-image-top-container ${css.topLevelContainer} ${borderClass}
  ${css.imageRowItem}
  ${css.fade} ${css.rowDisplay} ${css[textAlign.value] || css.left}
  ${rolloverEffect ? css.rolloverEffect : ''} 
  ${!shouldShow && isDesktopCarousel ? css.hiddenDesktop : ''}
  ${oneColumn} ${biColumn} ${css.productDisplay}
  `

  const handleTouchStart = (e: any) => {
    const { touches } = e
    if (touches.length === 1) {
      setTouchStart(touches[0].clientX)
    } else {
      setTouchStart(null)
    }
  }

  const handleTouchMove = (e: any) => {
    const { touches } = e
    if (touches.length === 1) {
      setTouchMove(touches[0].clientX)
    }
  }

  const handleTouchEnd = () => {
    const isMobileSize = window.innerWidth <= 768

    if (isMobileCarousel && isMobileSize) {
      _slideImageItem()
    }
    if (isDesktopCarousel && !isMobileSize) {
      _slideImageItem()
    }
  }

  const _slideImageItem = () => {
    if (touchMove && touchStart && Math.abs(touchStart - touchMove) > 100) {
      if (touchStart - touchMove < 0) {
        incrementSlide(-1)
      } else {
        incrementSlide(1)
      }
      setTouchMove(null)
      setTouchStart(null)
    }
  }

  const renderItem = () => {
    return (
      <React.Fragment>
        <props.contextProps.RenderHead>
          <style
            dangerouslySetInnerHTML={{
              __html: generateStyleSheet(inlineItemStyle),
            }}
          />
        </props.contextProps.RenderHead>
        <div
          className={`image-row-background-image ${imageRowItemClass} ${css.backgroundImage}`}
          role="img"
          aria-label={item.alt}
        />
        {item.text?.html && (
          <div>
            {item.text?.css && (
              <style
                dangerouslySetInnerHTML={{
                  __html: item.text.css,
                }}
              />
            )}
            <div className={`image-row-item-title ${css.marginTop}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.text.html,
                }}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
  return (
    <div
      className={topLevelClasses}
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={() => handleTouchEnd()}
      style={translate}
    >
      {item.url && <a href={item.url}>{renderItem()}</a>}
      {!item.url && renderItem()}
    </div>
  )
}

export default ImageRowItem
