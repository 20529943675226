import * as React from 'react'
import { ElementType, IOmniComponent } from '../../interfaces'
import style from './TextBlock.module.scss'

export interface IProps {
  data: IOmniComponentTextBlockProps
}

export interface IOmniComponentTextBlockProps extends IOmniComponent {
  richText: {
    html: string
    css: string
  }
  fontColor:
    | string
    | {
        value: string
        label: string
      }
}

const TextBlock = (props: IProps) => {
  const applyColorByClass = () => {
    const { fontColor } = props.data
    if (fontColor !== undefined) {
      if (typeof fontColor !== 'string') {
        if (fontColor.value) {
          return fontColor.value
        }
      }
    }
    return ''
  }
  const { css, html } = props.data.richText
  return (
    <React.Fragment>
      {css && (
        <style
          dangerouslySetInnerHTML={{
            __html: css,
          }}
        />
      )}
      <div>
        <div className={`${applyColorByClass()} ${style.textBlockContainer}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default {
  type: ElementType.TEXTBLOCK,
  component: TextBlock,
}
