import * as React from 'react'
import css from './BlockThumbnailV2.module.scss'
import { IBlock } from './BlockThumbnails'

export interface IProps {
  block: IBlock
}
export default class BlockThumbnail extends React.Component<IProps, any> {
  public render() {
    // const classes = ['fadeIn col-lg-2 col-md-3 col-sm-4', css.BlockThumbnail]
    const { block } = this.props
    return (
      <div className={`${css.container} fadeIn`}>
        <img
          className={css.articleImage}
          src={block.thumbnail || block.heroImageUrl || 'https://via.placeholder.com/300x200'}
          alt={block.thumbnail || block.heroImageUrl || 'https://via.placeholder.com/300x200'}
        />
        <div className={css.articleTitle}>
          <h3 className={css.title}>{block.title}</h3>
        </div>
      </div>
    )
  }
}
