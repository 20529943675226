import * as React from 'react'
import ComponentFactory from '../OmniFactory'
import { ElementType, IOmniComponent, ITagConfiguration } from '../../interfaces'
import css from './GeoTagContainer.module.scss'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { showContentBasedOnCurrentLocation } from '../../Utils/geoTag'

interface IOption {
  value: string
  label: string
}

export interface IGeoTagContainerProps extends IOmniComponent {
  spanData: string
  width: string
  padding: string
  margins: string
  useBackgroundImage: IOption
  backgroundImage: string
  overlayOpacity: number
  parallax: IOption
  verticalPadding: string
  backgroundColor: string | IOption
  fontColor: string | IOption
  zIndex: string
  useDropshadow: boolean
  geotaggingOptions?: ITagConfiguration
  customBackgroundColor?: string
}

export interface IProps {
  data: IGeoTagContainerProps
  children: any
  contextProps: IOmniPageSharedContextGlobalProps
}

const GeoTagContainer = (props: IProps) => {
  const { children } = props.data
  const targetedChildren: any[] = []
  for (const child of children) {
    const castChild: IGeoTagContainerProps = child as IGeoTagContainerProps
    if (!castChild.geotaggingOptions || !castChild.geotaggingOptions.enabled) {
      if (targetedChildren.length === 0) {
        targetedChildren.push(child)
      }
    } else if (castChild.geotaggingOptions && castChild.geotaggingOptions.enabled) {
      if (props.contextProps.locationData) {
        // then lets check it out
        const doShowBlock: boolean = showContentBasedOnCurrentLocation(
          props.contextProps.locationData,
          castChild.geotaggingOptions,
        )
        if (doShowBlock && targetedChildren.length === 0) {
          targetedChildren.push(child)
          break
        }
      }
    }
  }
  return (
    <div className={`${css.global}`}>
      {targetedChildren.map((c: IOmniComponent, index: number) => {
        return <ComponentFactory key={index} data={c} />
      })}
    </div>
  )
}

export default {
  type: ElementType.GEOTAG_CONTAINER,
  component: GeoTagContainer,
}
