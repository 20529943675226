import {
  IOmniComponentLookup as IOmniComponentLookupAlias,
  IOmniElementStructure as IOmniElementStructureAlias,
  INavigationTree as INavigationTreeAlias,
  INavigationTreeItem as INavigationTreeItemAlias,
  INestedNavigationTreeItem as INestedNavigationTreeItemAlias,
  IPageContainer as IPageContainerAlias,
  IContentBlock as IContentBlockAlias,
  IPage as IPageAlias,
  IContentSnapshot as IContentSnapshotAlias,
  ITag as ITagAlias,
  ITagConfigurationGroup as ITagConfigurationGroupAlias,
  ITagConfiguration as ITagConfigurationAlias,
} from '../Common/Interfaces/Strapi/Page'
import { ILanguage as ILanguageAlias } from '../Common/Interfaces/Strapi/Translations'

import { ISharedStyles } from './Plugins/Styles/styleInterfaces'
import { IIdentifiersProperties } from './Plugins/Identifiers/identifiersInterface'

export enum ElementType {
  TEXTBLOCK = 'TEXTBLOCK',
  CODE_BLOCK = 'CODE_BLOCK',
  VIDEO = 'VIDEO',
  SLIDESHOW = 'SLIDESHOW',
  SLIDESHOW_TESTIMONIAL = 'SLIDESHOW_TESTIMONIAL',
  BANNER = 'BANNER',
  BUTTON = 'BUTTON',
  SEPARATOR = 'SEPARATOR',
  PRODUCT_GALLERY = 'PRODUCT_GALLERY',
  SINGLE_IMAGE = 'SINGLE_IMAGE',
  GEOTAG_CONTAINER = 'GEOTAG_CONTAINER',
  COLUMN = 'COLUMN',
  NAVIGATION = 'NAVIGATION',
  ROW = 'ROW',
  IMAGEROW = 'IMAGE_ROW',
  RENDER_PROPS = 'RENDER_PROPS',
  BLOCK_THUMBNAILS = 'BLOCK_THUMBNAILS',
  DYNAMICADWIDGET = 'DYNAMIC_AD_WIDGET',
  CURALATE = 'CURALATE',
  IMAGESWITHOVERLAY = 'IMAGES_WITH_OVERLAY',
  OPEN_COMPONENT = 'OPEN_COMPONENT',
  CATEGORY_THUMBNAILS = 'CATEGORY_THUMBNAILS',
  ATTRIBUTES_LIST = 'ATTRIBUTES_LIST',
  PRODUCT_LISTING = 'PRODUCT_LISTING',
  IMAGE_GALLERY = 'IMAGE_GALLERY',
  CART_ITEM = 'CART_ITEM',
  PRODUCT_GROUP_CAROUSEL = 'PRODUCT_GROUP_CAROUSEL',
  PDP_ITEM = 'PDP_ITEM',
  TEXT_BANNER = 'TEXT_BANNER',
  ACCORDION = 'ACCORDION',
  SPACER = 'SPACER',
  TABS = 'TABS',
}

export interface IOmniComponent extends ISharedStyles, IIdentifiersProperties {
  children: IOmniComponent[]
  id: string
  type: ElementType
  [y: string]: any
}

export interface IOption {
  value: string
  label: string
}

export interface ILocationData {
  state: string
  zip: string
  city: string
  code?: string
}

// ------------------------------------------------ ALIASES ------------------------------------------------------------

export type IOmniComponentLookup = IOmniComponentLookupAlias
export type IOmniElementStructure = IOmniElementStructureAlias
export type INavigationTree = INavigationTreeAlias
export type INavigationTreeItem = INavigationTreeItemAlias
export type INestedNavigationTreeItem = INestedNavigationTreeItemAlias
export type IPageContainer = IPageContainerAlias
export type ILanguage = ILanguageAlias
export type IContentBlock = IContentBlockAlias
export type IPage = IPageAlias
export type IContentSnapshot = IContentSnapshotAlias
export type ITag = ITagAlias
export type ITagConfigurationGroup = ITagConfigurationGroupAlias
export type ITagConfiguration = ITagConfigurationAlias
