import * as React from 'react'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import css from './TextBanner.module.scss'
import { IOmniComponentSlideShowProps, ITextSlideShowElement } from './TextBanner'

export interface ITextSlideProps {
  item: ITextSlideShowElement
  onChange?: any
  onDelete?: any
  show: boolean
  index?: number
  data?: IOmniComponentSlideShowProps
  contextProps?: IOmniPageSharedContextGlobalProps
}

const Slide = (props: any) => {
  const { item } = props

  const generateClasses = () => {
    const classes: string[] = []
    const { slideAnimation } = props.data
    const { index, currentSlide, previousSlide, numOfSlides } = props
    if (numOfSlides > 1) {
      if (slideAnimation?.value === 'verticalScroll') {
        classes.push(css.verticalScroll)
      }
      if (slideAnimation?.value === 'horizontalScroll') {
        classes.push(css.horizontalScroll)
      }
      if (slideAnimation?.value === 'fade') {
        classes.push(css.fade)
      }
      if (index === currentSlide) {
        const classIndex = classes.indexOf(css.animateOut)
        classIndex > -1 && classes.splice(classIndex, 1)
        classes.push(css.animateIn)
      }
      if (index === currentSlide - 1 || (previousSlide === index && previousSlide === numOfSlides - 1)) {
        classes.push(css.animateOut)
      }
      return classes.join(' ')
    } else {
      classes.push(css.show)
      return classes.join(' ')
    }
  }

  return (
    <div className={`${css.slide} ${generateClasses()}`}>
      <div key={item.id} className={css.blockStyle} style={{ textAlign: 'center' }}>
        {item.content?.css && (
          <style
            dangerouslySetInnerHTML={{
              __html: item.content?.css,
            }}
          />
        )}
        <span dangerouslySetInnerHTML={{ __html: item?.content?.html || '' }} />
      </div>
    </div>
  )
}

export default Slide
