// import { IOption } from '../../../../../../../Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import * as React from 'react'
import { IPageComponentPlugin, IPluginRenderProps } from '../FrontendPageComponentPluginsInterface'
import { PAGE_COMPONENT_PLUGIN_TYPE } from '../FrontendPageComponentPluginTypes'
import { IOmniComponent, ElementType } from '../../interfaces'
import { generateStyleSheet, generateClassName } from '../../Utils/classUtils'
import Parallax from './BackgroundImage/Parallax'
import css from './styles-plugin.scss'
import { isPropertyAllowed } from './columnRestrictions'
import { STYLE_PROPERTY_NAME } from './styleTypesEnumeration'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { THEME_WRAPPER_TAGS } from '../../../Common/Theme/ThemeWrapper'
import { ISiteTheme } from '../../../Common/Interfaces/Strapi/Site'

// ------------------------------------------------- WIDTH ------------------------------------------------
const applyWidth = (comp: IOmniComponent): React.CSSProperties => {
  const { width } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.WIDTH)) {
    styles.maxWidth = width === undefined ? '100%' : width
  }
  return styles
}

const applyCenterize = (comp: IOmniComponent) => {
  if (comp.type === ElementType.ROW) {
    return {
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  }
  return {}
}

// ------------------------------------------------ BACKGROUND COLOR --------------------------------------
const applyCustomBackgroundColor = (comp: IOmniComponent): React.CSSProperties => {
  const { backgroundColor, customBackgroundColor } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.BACKGROUND_COLOR)) {
    if (backgroundColor !== undefined) {
      if (typeof backgroundColor === 'string') {
        styles.backgroundColor = backgroundColor
      } else {
        if (
          backgroundColor.value === 'customColorBackground' &&
          customBackgroundColor !== undefined &&
          typeof customBackgroundColor === 'string'
        ) {
          styles.backgroundColor = customBackgroundColor
        }
      }
    }
  }
  return styles
}

const mapBackgroundColorOptionsToColor = (theme: ISiteTheme | null | undefined, tag: THEME_WRAPPER_TAGS): string => {
  if(!theme){
    return ""
  }
  switch(tag){
    case THEME_WRAPPER_TAGS.PRIMARY_BACKGROUND_COLOR:
      return theme.primaryColor || ""
    case THEME_WRAPPER_TAGS.SECONDARY_BACKGROUND_COLOR:
      return theme.secondaryColor || ""
    case THEME_WRAPPER_TAGS.TERTIARY_BACKGROUND_COLOR:
      return theme.tertiaryColor || ""
    case THEME_WRAPPER_TAGS.QUATERNARY_BACKGROUND_COLOR:
      return theme.quaternaryColor || ""
    case THEME_WRAPPER_TAGS.QUINARY_BACKGROUND_COLOR:
      return theme.quinaryColor || ""
    case THEME_WRAPPER_TAGS.SENARY_BACKGROUND_COLOR:
      return theme.senaryColor || ""
    default:
      return ""
  }
}

const applyBackgroundColor = (comp: IOmniComponent, contextProps:IOmniPageSharedContextGlobalProps): React.CSSProperties => {
  const styles: React.CSSProperties = {}
  const { backgroundColor } = comp
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.BACKGROUND_COLOR)) {
    if (backgroundColor) {
      if (typeof backgroundColor !== 'string') {
        if(backgroundColor.value !== "customColorBackground" && contextProps.activeTheme) {
          let targetColor: string = mapBackgroundColorOptionsToColor(contextProps.activeTheme, backgroundColor.value)
          if(targetColor){
            styles.backgroundColor = targetColor;
            return styles
          }
        }
        if (backgroundColor.color) {
          styles.backgroundColor = backgroundColor.color
        }
      }
    }
  }
  return styles
}

// ----------------------------------------------- Z-INDEX ------------------------------------------------
const applyZIndex = (comp: IOmniComponent): React.CSSProperties => {
  const { zIndex } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.ZINDEX)) {
    if (zIndex !== undefined && zIndex !== '') {
      styles.zIndex = parseInt(zIndex)
      styles.position = 'relative'
    }
  }
  return styles
}

// ----------------------------------------------- BORDER ------------------------------------------------
const applyBorder = (comp: IOmniComponent): React.CSSProperties => {
  const { border } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.BORDER)) {
    if (border !== undefined) {
      if (typeof border === 'string') {
        styles.border = border
      }
    }
  }
  return styles
}

// ----------------------------------------------- BORDER RADIUS ------------------------------------------------
const applyBorderRadius = (comp: IOmniComponent): React.CSSProperties => {
  const { borderRadius } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.BORDER_RADIUS)) {
    if (borderRadius !== undefined) {
      if (typeof borderRadius === 'string') {
        styles.borderRadius = borderRadius
      }
    }
  }
  return styles
}

// ----------------------------------------------- BOX SHADOW ------------------------------------------------
const applyBoxShadow = (comp: IOmniComponent): React.CSSProperties => {
  const { boxShadow } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.BOX_SHADOW)) {
    if (boxShadow) {
      if (typeof boxShadow === 'object') {
        styles.boxShadow = boxShadow?.value || 'none'
      } else if (typeof boxShadow === 'string') {
        styles.boxShadow = boxShadow
      }
    }
  }
  return styles
}

// -------------------------------------------- TEXT ALIGN ----------------------------------------------------
const applyTextAlignClasses = (comp: IOmniComponent): string => {
  const { textAlign } = comp
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.TEXT_ALIGN)) {
    if (textAlign && typeof textAlign === 'object') {
      if (textAlign?.value && textAlign?.value !== 'initial') {
        return css[`alignText_${textAlign.value}`]
      }
    }
  }
  return ''
}

// --------------------------------------------PADDING ----------------------------------------------------
const applyPadding = (comp: IOmniComponent): React.CSSProperties => {
  const { padding } = comp
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.PADDING)) {
    const targetPadding: string = padding === undefined ? '0px' : padding
    styles.padding = targetPadding
  }
  return styles
}

// --------------------------------------------MARGIN ----------------------------------------------------
const applyMargin = (comp: IOmniComponent): React.CSSProperties => {
  const { margins } = comp
  const targetMargins: string = margins === undefined ? (comp.type === ElementType.ROW ? '0 0 30px 0' : '0px') : margins
  const styles: React.CSSProperties = {}
  if (isPropertyAllowed(comp.type, STYLE_PROPERTY_NAME.MARGINS)) {
    styles.margin = targetMargins
  }
  return styles
}

// ------------------------------------------- END ------------------------------------------------------------

const composeInnerStyles = (comp: IOmniComponent, contextProps:IOmniPageSharedContextGlobalProps): React.CSSProperties => {
  return {
    ...applyBoxShadow(comp),
    ...applyZIndex(comp),
    ...applyBackgroundColor(comp,contextProps),
    ...applyCustomBackgroundColor(comp),
    ...applyMargin(comp),
    ...applyPadding(comp),
    ...applyBorderRadius(comp),
    ...applyBorder(comp),
  }
}

const composeOuterStyles = (comp: IOmniComponent): React.CSSProperties => {
  return {
    ...applyWidth(comp),
    ...applyCenterize(comp),
  }
}

interface IOuterWrapperProps {
  data: IOmniComponent
  children: any
  targetClass: any
}

const OuterWrapper = (props: IOuterWrapperProps) => {
  const { data, children, targetClass } = props
  if (data.type === ElementType.ROW) {
    return <div className={`styles-plugin-outer ${targetClass}`}>{children}</div>
  }
  return <React.Fragment>{children}</React.Fragment>
}

const renderPluginWrapperHook = (props: IPluginRenderProps): JSX.Element => {
  const component: IOmniComponent = props.componentData
  const innerStylesObject: React.CSSProperties = composeInnerStyles(component, props.contextProps)
  const outerStylesObject: React.CSSProperties = composeOuterStyles(component)
  //we don't want styles attached directly to the html elements so let's generate a style tag and attach to head
  const inlineStyles = {}
  const innerClassFromStylesheet = generateClassName(
    inlineStyles,
    `custom-inner-style-${component.type}-${component.id}`,
    innerStylesObject,
  )
  const outerClassFromStylesheet = generateClassName(
    inlineStyles,
    `custom-outer-style-${component.type}-${component.id}`,
    outerStylesObject,
  )
  return (
    <React.Fragment>
      <props.contextProps.RenderHead>
        <style
          dangerouslySetInnerHTML={{
            __html: generateStyleSheet(inlineStyles),
          }}
        />
      </props.contextProps.RenderHead>
      <OuterWrapper data={component} targetClass={outerClassFromStylesheet}>
        <Parallax {...props}>
          <div className={`styles-plugin-inner ${applyTextAlignClasses(component)} ${innerClassFromStylesheet}`}>
            {props.children}
          </div>
        </Parallax>
      </OuterWrapper>
    </React.Fragment>
  )
}

const plugin: IPageComponentPlugin = {
  enabled: true,
  type: PAGE_COMPONENT_PLUGIN_TYPE.STYLES,
  hooks: {
    renderPluginWrapperHook,
  },
}

export default plugin
