import * as React from 'react'
import bootstrap from '../../../../../Common/Layout/Layout.module.scss'
import { find } from '../../../../../Common/Utils/lodash'
import { MERCE_CLASS_NAMES } from '../../../../../interfaces'
import appendImageTransformationProxyUrl from '../../../../Plugins/ImageTransformation/imageTransformationUtils'
import { IOmniPageSharedContextGlobalProps } from '../../../../Shared.context'
import { IOmniComponentImageGalleryProps } from '../ImageGallery'
import css from './VerticalCarouselV2.module.scss'

enum IProductImageType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export interface ISliderItem {
  url: string
  alt: string
  type: IProductImageType
  thumbnail?: string
  sequenceNumber?: number
  content?: {
    html: string
    css: string
  }
}
interface IProps {
  sliderData: ISliderItem[]
  defaultImage?: string
  horizontal?: boolean
  data: IOmniComponentImageGalleryProps
  contextProps?: IOmniPageSharedContextGlobalProps
  screenMode?: any
}
interface IState {
  thumbnailStartIndex: number
  mainImgIndex: number
  sliderItemArray: ISliderItem[]
  hasNavigation: boolean
}

interface IDuplicateSortItem {
  sort: number
  slides: ISliderItem[]
}

/**
 * We ran into issues where sequenceNumbers could be the same and it sorts differently on client vs server
 * So we have to account for that in this method
 * @param slides
 */
const sortSliderItems = (slides: ISliderItem[] | null | undefined): ISliderItem[] => {
  if (!slides) {
    return []
  }
  let hasSequenceNumber: boolean = true
  //not all image sets will have a sequence number since this field was just introduced
  for (const slide of slides) {
    if (slide.sequenceNumber === undefined) {
      hasSequenceNumber = false
    }
  }
  if (!hasSequenceNumber) {
    return slides
  }
  const sequenceNumberGroups: IDuplicateSortItem[] = []
  for (const slide of slides) {
    const numberExists = find(sequenceNumberGroups, (n: IDuplicateSortItem) => {
      return n.sort == slide.sequenceNumber
    })
    //determine if the sequenceNumber has been used already
    if (!numberExists) {
      sequenceNumberGroups.push({
        sort: slide.sequenceNumber as number,
        slides: [slide],
      })
    } else {
      numberExists.slides.push(slide)
    }
  }
  const finalSortedItemList = sequenceNumberGroups.sort((a: IDuplicateSortItem, b: IDuplicateSortItem) => {
    const valueA: number = a.sort ?? 0
    const valueB: number = b.sort ?? 0
    return valueA > valueB ? 1 : -1
  })
  const sortedListes: ISliderItem[] = []
  for (const item of finalSortedItemList) {
    for (const slide of item.slides) {
      sortedListes.push(slide)
    }
  }
  return sortedListes
}

class VerticalCarousel extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const sortedItems: ISliderItem[] = sortSliderItems(this.props.sliderData || [])
    this.state = {
      thumbnailStartIndex: 0,
      mainImgIndex: 0,
      sliderItemArray: sortedItems || [],
      hasNavigation: sortedItems && sortedItems.length > 5,
    }
  }

  studioScreenMode = this.props.screenMode && this.props.screenMode === 'PHONE' ? css.studioScreenMode : ''

  UNSAFE_componentWillReceiveProps = async (_props: any) => {
    const sortedItems: ISliderItem[] = sortSliderItems(this.props.sliderData || [])
    this.setState({
      thumbnailStartIndex: 0,
      mainImgIndex: 0,
      sliderItemArray: sortedItems || [],
      hasNavigation: sortedItems && sortedItems.length > 5,
    })
  }

  public setMainImg = (index: number) => () => {
    this.setState({
      mainImgIndex: index,
    })
  }
  public onClickUp = () => {
    const { sliderItemArray } = this.state
    const index = this.state.mainImgIndex === 0 ? sliderItemArray.length - 1 : this.state.mainImgIndex - 1
    let thumbnailStartIndex =
      index < this.state.thumbnailStartIndex && this.state.thumbnailStartIndex > 0
        ? this.state.thumbnailStartIndex - 1
        : this.state.thumbnailStartIndex
    thumbnailStartIndex = index === sliderItemArray.length - 1 ? sliderItemArray.length - 5 : thumbnailStartIndex
    this.setState({
      mainImgIndex: index,
      thumbnailStartIndex,
    })
  }
  public onClickDown = () => {
    const { sliderItemArray, thumbnailStartIndex } = this.state
    const index = this.state.mainImgIndex === sliderItemArray.length - 1 ? 0 : this.state.mainImgIndex + 1
    let thumbnailStartIndexLocal =
      index === thumbnailStartIndex + 5 && thumbnailStartIndex < sliderItemArray.length + 1
        ? thumbnailStartIndex + 1
        : thumbnailStartIndex
    thumbnailStartIndexLocal = index === 0 ? 0 : thumbnailStartIndexLocal
    this.setState({
      mainImgIndex: index,
      thumbnailStartIndex: thumbnailStartIndexLocal,
    })
  }

  public formatBackgroundImage = (url: string) => {
    return appendImageTransformationProxyUrl({
      targetUrl: url,
      context: this.props.contextProps as any,
      data: this.props.data as any,
    })
  }

  private getThumbnailMargins = () => {
    let spacing = this.props.data.thumbnailSpacing || '5px'
    if (this.isHorizontal()) {
      return `0px ${spacing} 0px 0px`
    }
    return `0px 0px ${spacing} 0px`
  }

  private isHorizontal = () => {
    return this.props.horizontal || this.props.data.horizontal
  }

  // ------------------------------------------------------------ RENDER ---------------------------------------------------------------------------------------------
  public renderThumbnails() {
    if (this.state.sliderItemArray && this.state.sliderItemArray.length > 0) {
      return this.state.sliderItemArray.map((imgSrc: ISliderItem, index: number) => {
        const inactiveClass = index !== this.state.mainImgIndex ? css.inactiveImgContainer : ''
        const isVideo = imgSrc.type === IProductImageType.VIDEO
        const placeholder = this.props.defaultImage || 'https://via.placeholder.com/800x800'
        const defaultThumbnail =
          (isVideo && imgSrc.thumbnail && imgSrc.thumbnail.length <= 0) || !imgSrc.thumbnail
            ? placeholder
            : imgSrc.thumbnail
        const isHidden = index < this.state.thumbnailStartIndex || index >= this.state.thumbnailStartIndex + 5
        const hide = isHidden ? css.hidden : ''
        return (
          <div
            key={index}
            className={`${
              this.isHorizontal()
                ? `${css.carouselImgContainer} ${css.horizontalImgContainer}`
                : css.carouselImgContainer
            } ${inactiveClass} ${hide} ${this.studioScreenMode ? css.hideThumbnails : ''}`}
            style={{
              height: this.props.data?.thumbnailHeight || '200px',
              width: this.props.data?.thumbnailWidth || '200px',
              margin: this.getThumbnailMargins(),
            }}
            onClick={this.setMainImg(index)}
            role="presentation"
          >
            <div className={`${inactiveClass} ${css.thumbnailContainer}`}>
              {isVideo && <i className={`fas fa-play-circle ${css.playIcon}`} />}
              <div
                className={`${css.imageThumbnail} fa`}
                role="img"
                aria-label={imgSrc.alt}
                style={{
                  backgroundSize: this.props.data?.thumbnailSizing || 'contain',
                  backgroundImage: `url("${this.formatBackgroundImage(isVideo ? defaultThumbnail : imgSrc.url)}")`,
                }}
              />
            </div>
          </div>
        )
      })
    } else {
      return <div />
    }
  }

  public renderContentBlock = (item: ISliderItem) => {
    if (item) {
      if (item.type === IProductImageType.IMAGE) {
        return (
          <div className={`${css.mainImgBlock} ${css.fade} ${this.studioScreenMode}`}>
            {/* <div className={`${css.mainImg} ${css.fade}`} style={{
            background: `url("${item.thumbnail}") no-repeat center / contain`
          }} /> */}
            <img
              className={`${css.mainImg} ${MERCE_CLASS_NAMES.IMAGE_GALLERY_IMG} ${this.studioScreenMode}`}
              src={item.url}
              alt={item.alt}
              style={{ maxWidth: this.props.data?.maxMainImageWidth || '100%' }}
            />
            {item.content?.html && <div dangerouslySetInnerHTML={{ __html: item.content.html }} />}
          </div>
        )
      } else if (item.type === IProductImageType.VIDEO) {
        return (
          // Does not support YouTube or similar streaming sources.
          <div className={css.videoContainer}>
            <video width="100%" height="100%" controls={true} preload="metadata">
              <source src={item.url} type="video/ogg" />
              <source src={item.url} type="video/mp4" />
              <source src={item.url} type="video/webm" />
              Your browser does not support the video tag.
            </video>
            {item.content?.html && <div dangerouslySetInnerHTML={{ __html: item.content.html }} />}
          </div>
        )
      }
    }
    return (
      <div className={`${css.mainImgBlock} ${css.fade} ${this.studioScreenMode}`}>
        <img
          className={`${css.mainImg} ${MERCE_CLASS_NAMES.IMAGE_GALLERY_IMG}`}
          src={this.props.defaultImage || 'https://via.placeholder.com/800x400'}
          alt="No images present"
          style={{ maxWidth: this.props.data?.maxMainImageWidth || '100%' }}
        />
      </div>
    )
  }

  public render() {
    return (
      <div
        className={`${MERCE_CLASS_NAMES.IMAGE_GALLERY_CONTAINER} ${
          this.isHorizontal() ? css.horizontalCarousel : css.verticalCarousel
        }`}
      >
        <div
          style={{
            marginTop: this.isHorizontal() ? this.props.data?.thumbnailRightMargin || '5px' : '0px',
            marginRight: !this.isHorizontal() ? this.props.data?.thumbnailRightMargin || '5px' : '0px',
          }}
          className={this.isHorizontal() ? `${css.carousel} ${css.horizontal}` : css.carousel}
        >
          <div className={`${bootstrap['hidden-sm']} ${bootstrap['hidden-xs']}`}>
            {this.state.hasNavigation && (
              <div role="presentation" onClick={this.onClickUp}>
                {/* Chevron Up */}
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={css.chevron}
                >
                  <path d="M12.2362 9.74998L7.26365 14.7225C6.84115 15.145 6.84115 15.8275 7.26365 16.25C7.68615 16.6725 8.36865 16.6725 8.79115 16.25L13.0053 12.0466L17.2087 16.25C17.6312 16.6725 18.3137 16.6725 18.7362 16.25C19.1587 15.8275 19.1587 15.145 18.7362 14.7225L13.7637 9.74998C13.352 9.32748 12.6587 9.32748 12.2362 9.74998Z" />
                </svg>
              </div>
            )}
            {this.renderThumbnails()}
            {this.state.hasNavigation && (
              <div role="presentation" onClick={this.onClickDown}>
                {/* Chevron down */}
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  xmlns="http://www.w3.org/2000/svg"
                  className={css.chevron}
                >
                  <path d="M17.1978 9.74997L12.9945 13.9533L8.79115 9.74997C8.58875 9.54711 8.31396 9.43311 8.0274 9.43311C7.74084 9.43311 7.46605 9.54711 7.26365 9.74997C6.84115 10.1725 6.84115 10.855 7.26365 11.2775L12.2362 16.25C12.6587 16.6725 13.3412 16.6725 13.7637 16.25L18.7362 11.2775C19.1587 10.855 19.1587 10.1725 18.7362 9.74997C18.3137 9.3383 17.6203 9.32746 17.1978 9.74997Z" />
                </svg>
              </div>
            )}
          </div>
        </div>
        <div
          className={`${MERCE_CLASS_NAMES.PDP_PRODUCT_PHOTO_MAIN} ${css.mainImageContainer} ${
            this.isHorizontal() ? css.horizontalImageContainer : ''
          } ${this.studioScreenMode}`}
        >
          {/* remove next div for div with image background */}
          <div>
            <div
              className={`${bootstrap['visible-sm']} ${bootstrap['visible-xs']} ${css.centerNavigation} ${this.studioScreenMode}`}
            >
              <div role="presentation" onClick={this.onClickUp}>
                {/* Chevron Left */}
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={css.chevron}
                >
                  <path d="M16.25 7.26376C16.0476 7.0609 15.7728 6.9469 15.4862 6.9469C15.1997 6.9469 14.9249 7.0609 14.7225 7.26376L9.74998 12.2363C9.32748 12.6588 9.32748 13.3413 9.74998 13.7638L14.7225 18.7363C15.145 19.1588 15.8275 19.1588 16.25 18.7363C16.6725 18.3138 16.6725 17.6313 16.25 17.2088L12.0466 12.9946L16.25 8.79126C16.6725 8.36876 16.6616 7.67543 16.25 7.26376Z" />
                </svg>
              </div>
            </div>
          </div>
          {this.renderContentBlock(this.state.sliderItemArray[this.state.mainImgIndex])}

          {/* remove next div for div with image background */}
          <div>
            <div
              className={`${bootstrap['visible-sm']} ${bootstrap['visible-xs']} ${css.centerNavigation} ${this.studioScreenMode}`}
            >
              <div role="presentation" onClick={this.onClickDown}>
                {/* Chevron Right */}
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={css.chevron}
                >
                  <path d="M9.74998 7.26984C9.32748 7.69234 9.32748 8.37484 9.74998 8.79734L13.9533 13.0007L9.74998 17.204C9.32748 17.6265 9.32748 18.309 9.74998 18.7315C10.1725 19.154 10.855 19.154 11.2775 18.7315L16.25 13.759C16.6725 13.3365 16.6725 12.654 16.25 12.2315L11.2775 7.25901C10.8658 6.84734 10.1725 6.84734 9.74998 7.26984Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default VerticalCarousel
