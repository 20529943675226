export enum STYLE_PROPERTY_NAME {
  ID = 'id',
  CLASSES = 'classes',
  WIDTH = 'width',
  REAL_WIDTH = 'realWidth',
  HEIGHT = 'height',
  MIN_HEIGHT = 'minHeight',
  MAX_WIDTH = 'maxWidth',
  MARGINS = 'margins',
  PADDING = 'padding',
  ZINDEX = 'zIndex',
  BORDER = 'border',
  BORDER_RADIUS = 'borderRadius',
  BOX_SHADOW = 'boxShadow',
  BACKGROUND_COLOR = 'backgroundColor',
  CUSTOM_BACKGROUND_COLOR = 'customBackgroundColor',
  TEXT_ALIGN = 'textAlign',
  FONT_COLOR = 'fontColor',
  USE_BACKGROUND_IMAGE = 'useBackgroundImage',
  BACKGROUND_IMAGE_URL = 'backgroundImage',
  USE_PARALLAX = 'parallax',
  COLUMN_LAYOUTS = 'spanData',
  SPACING = 'spacing',
  VISIBILILTY_BREAKPOINT = 'visibilityBreakpoint',
  ANIMATION = 'targetAnimation',
  IDENTIFIERS = 'identifiers',
  IMAGE_TRANSFORMATION = 'imageTransformation',
  SCHEDULE = 'publishToday',
  GEOTAGGING = 'geotaggingOptions',
  ALIGN_ITEMS = 'alignItems',
  FLEX = 'flex',
  DIRECTION = 'direction',
  JUSTIFY_CONTENT = 'justifyContent',
  POSITION = 'position',
  POSITION_TOP = 'top',
  POSITION_LEFT = 'left',
  POSITION_RIGHT = 'right',
  POSITION_BOTTOM = 'bottom',
  TRANSFORM = 'transform',
  OVERFLOW = 'overflow',
  TYPOGRAPHY = 'styleTypography',
  OVERRIDE_FONT_COLOR = 'overrideFontColor',
}
