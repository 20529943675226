import * as React from 'react'
import { ElementType } from '../../interfaces'

export interface IOmniElementVideoProps {
  videoUrl: string
  videoTitle?: string
  height: string
  width: string
}
export interface IProps {
  data: IOmniElementVideoProps
}
// interface IState {
//   isHover: boolean
// }

const IOmniElementVideo = (props: IProps) => {
  return (
    <div>
      <iframe
        width="100%"
        height="650px"
        src={props.data.videoUrl}
        frameBorder="0"
        title={props.data.videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  )
}

export default {
  type: ElementType.VIDEO,
  component: IOmniElementVideo,
}
