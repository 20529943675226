import * as React from 'react'
import { ElementType, IOmniComponent } from '../../interfaces'

export interface IProps {
  data: IOmniComponentCuralateProps
}

export interface IOmniComponentCuralateProps extends IOmniComponent {
  siteName: string
}

export class Curalate extends React.Component<IProps, any> {
  public componentDidMount() {
    const siteName = this.props.data.siteName
    const script = document.createElement('script')
    script.innerHTML = `
      var CRL8_SITENAME = '${siteName}'
      !function(){var e=window.crl8=window.crl8||{},n=!1,i=[];e.ready=function(e){n?e():i.push(e)},e.pixel=e.pixel||function(){e.pixel.q.push(arguments)},e.pixel.q=e.pixel.q||[];var t=window.document,o=t.createElement("script"),c=e.debug||-1!==t.location.search.indexOf("crl8-debug=true")?"js":"min.js";o.src=t.location.protocol+"//cdn.curalate.com/sites/"+CRL8_SITENAME+"/site/latest/site."+c,o.onload=function(){n=!0,i.forEach(function(e){e()})};var r=t.getElementsByTagName("script")[0];r.parentNode.insertBefore(o,r.nextSibling)}();
    `
    document.body.appendChild(script)
  }
  public render() {
    return (
      <React.Fragment>
        <div data-crl8-container-id="homepage" />
      </React.Fragment>
    )
  }
}

export default {
  type: ElementType.CURALATE,
  component: Curalate,
}
