import * as React from 'react'
import { ElementType } from '../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { find, findIndex, has, isEmpty } from '../../../Common/Utils/lodash'
import CatalogNavigation, { IFilterItem } from '../../../Catalog/Navigation/Navigation'
import CACHE_CONTSTANT from '../../../Common/Utils/cache/constants'

interface IAttributeOptionsItemValue {
  value: string
  count: number
}

interface IAttributeOptionsItem {
  id: string
  code: string
  label: string
  values: IAttributeOptionsItemValue[]
}

interface IOption {
  value: any
  label: string
}

export interface IOmniElementAttributesListProps {
  category?: IOption
  priorityOrder?: IOption[]
  exclude?: IOption[]
}
export interface IProps {
  data: IOmniElementAttributesListProps
  contextProps: IOmniPageSharedContextGlobalProps
}

const getQueryStringsAsObject = () => {
  try {
    const search = location.search.substring(1)
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}',
    )
  } catch (e) {
    return {}
  }
}

const IOmniElementAttributesList = (props: IProps) => {
  const [attributeOptions, setAttributeOptions] = React.useState<IAttributeOptionsItem[]>([])
  const [loaded, setLoaded] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const { category } = props.data
  const { events } = props.contextProps

  // only get the cache if we don't have search parameters
  const getCacheKey = (targetKey: any) => {
    const query = getQueryStringsAsObject()
    if (!isEmpty(query)) {
      return {}
    }
    return {
      cacheKey: `${CACHE_CONTSTANT.FRONTEND.PIM.CATEGORY}-${targetKey}-association-attributes`,
    }
  }

  const filterAttributes = (attributes: any[]) => {
    const { priorityOrder, exclude } = props.data
    const orderedAttributes: any[] = priorityOrder !== undefined ? [] : attributeOptions
    let filteredAttributes: any[] = []
    if (priorityOrder) {
      for (const order of priorityOrder) {
        const targetIndex: number = findIndex(attributes, { id: order.value })
        if (targetIndex !== -1) {
          orderedAttributes.push(attributes[targetIndex])
          attributes.splice(targetIndex, 1)
        }
      }
      filteredAttributes = [...orderedAttributes, ...attributes]
    }
    const returnedAttributes: any[] = []
    if (exclude) {
      for (const attr of filteredAttributes) {
        const isExcluded: IOption | undefined = find(exclude, {
          value: attr.id,
        })
        if (!isExcluded) {
          returnedAttributes.push(attr)
        }
      }
    }
    setAttributeOptions(returnedAttributes)
  }

  const loadAttributes = async () => {
    const { transport } = props.contextProps
    if (category && category.value) {
      if (transport) {
        const productAssociations = await transport.post('/categories/attributes', {
          ...getCacheKey(category.value),
          categoryQuery: {
            _id: category.value,
          },
          matchingFields: {
            ...getQueryStringsAsObject(),
          },
        })
        if (productAssociations.data) {
          const attributes: any[] = productAssociations.data
          filterAttributes(attributes)
        }
      }
    }
    setLoading(false)
  }

  React.useEffect(() => {
    if (!loaded) {
      setLoaded(true)
      setLoading(true)
      let hasPreloadedAttributes: boolean = false
      if (props.contextProps.cache) {
        if (props.contextProps.cache.attributes) {
          hasPreloadedAttributes = true
          filterAttributes(props.contextProps.cache.attributes)
        }
      }
      if (!hasPreloadedAttributes) {
        loadAttributes()
      }
    }
  })

  if (loading) {
    return <div>Loading...</div>
  }

  if (!attributeOptions || !attributeOptions.length) {
    return <div>No attributes to load</div>
  }

  const targetFilterList: IFilterItem[] | undefined = has(props, ['contextProps', 'cache', 'filtersList'])

  return (
    <CatalogNavigation
      category={category as any}
      attributes={attributeOptions}
      filtersList={targetFilterList || []}
      configuration={{
        events: { ...events } as any,
      }}
    />
  )
}

export default {
  type: ElementType.ATTRIBUTES_LIST,
  component: IOmniElementAttributesList,
}
