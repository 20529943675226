import * as React from 'react'
import ComponentFactory from '../OmniFactory'
import { ElementType, IOmniComponent, ITagConfiguration } from '../../interfaces'
import { Row, Col } from '../../../Common/Layout'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { showContentBasedOnCurrentLocation } from '../../Utils/geoTag'
import css from './Row.module.scss'

interface IOption {
  value: string
  label: string
}

export interface IOmniElementRowProps extends IOmniComponent {
  spanData: string
  useBackgroundImage: IOption
  useRowAnimation: IOption | undefined
  backgroundImage: string
  overlayOpacity: number
  parallax: IOption
  verticalPadding: string
  backgroundColor:
    | string
    | {
        color: string
        value: string
        label: string
      }
  fontColor: string | IOption
  geotaggingOptions?: ITagConfiguration
  publishToday: IOption | undefined
  startDate: Date
  endDate: Date
  studioColor?: string
  studioTitle?: string
  customBackgroundColor?: string
  backgroundSize?: string
}
export interface IProps {
  data: IOmniElementRowProps
  children: any
  contextProps: IOmniPageSharedContextGlobalProps
}

/**
 * Initialize
 * @param props
 */
const OmniElementRow = (props: IProps) => {
  // geotagging check ----------------------------------------------
  const { children, spanData, geotaggingOptions } = props.data
  const { contextProps } = props
  if (geotaggingOptions && geotaggingOptions.enabled) {
    if (contextProps.locationData) {
      // then lets check it out
      const doShowBlock: boolean = showContentBasedOnCurrentLocation(contextProps.locationData, geotaggingOptions)
      if (!doShowBlock) {
        return <div />
      }
    }
  }

  // end geotagging check ----------------------------------------
  const columns: any = children
  const spans: any = spanData.split('+')
  const { publishToday: pT, startDate: sD, endDate: eD } = props.data
  const { previewDate: pD } = contextProps
  const publishToday = pT ? pT : { value: true }
  const previewDate = new Date(pD || new Date())
  const startDate = new Date(sD)
  const endDate = new Date(eD)
  const hideOrShowRow =
    (!publishToday.value && startDate <= previewDate && previewDate <= endDate) || publishToday.value ? true : false
  if (hideOrShowRow) {
    return (
      <React.Fragment>
        <div className={`${css.global} merce-row-container`}>
          <Row>
            {columns.map((c: IOmniComponent, index: number) => {
              const split = spans[index].split('/')
              const result = parseInt(split[0], 10) / parseInt(split[1], 10)
              const bootstrap = Math.floor(result * 12)
              return (
                <Col key={index} sm={bootstrap} noPadding={c.v2 ? true : false}>
                  <ComponentFactory data={c} />
                </Col>
              )
            })}
          </Row>
        </div>
      </React.Fragment>
    )
  } else if (!publishToday.value) {
    return (
      <React.Fragment>
        <div
          style={{
            border: '1px solid #ccc',
            margin: '15px',
            padding: '15px',
            textAlign: 'center',
          }}
        >
          Scheduled for: {startDate.toDateString()} - {endDate.toDateString()}
        </div>
        <div />
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default {
  type: ElementType.ROW,
  component: OmniElementRow,
}