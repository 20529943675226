import * as React from 'react'
import { ElementType, IOmniComponent } from '../../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'

interface IProps {
  data: IOmniPDPItemProps
  contextProps: IOmniPageSharedContextGlobalProps
}

export interface IOmniPDPItemProps extends IOmniComponent {
  referenceId: string
  parameters?: Record<string, any>
  additionalParameters?: Record<string, any>
}

/**
 * Will render one of many different block elements of a PDP
 * This can be controlled from studio
 * @param props
 */
const PDPItem = (props: IProps) => {
  const {
    contextProps,
    data: { referenceId, parameters, additionalParameters },
  } = props

  if (referenceId) {
    if (contextProps?.renderHooks?.[referenceId]) {
      let params: object | undefined | null
      try {
        if (typeof parameters === 'string') {
          params = JSON.parse(parameters)
        } else {
          params = parameters
        }

        if (!params) {
          params = {}
        }

        Object.assign(params, additionalParameters)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Invalid parameters.')
      }
      return contextProps.renderHooks[referenceId](params || {}, contextProps.locale)
    }
  }
  return <div />
}

export default {
  type: ElementType.PDP_ITEM,
  component: PDPItem,
}
