import { IOmniPageSharedContextGlobalProps } from '../Shared.context'

export interface IStyleSheetLookup {
  [x: string]: {
    uuid: string
    items: React.CSSProperties[]
  }
}

const generateClassName = (
  styleSheetLookup: IStyleSheetLookup,
  targetClassName: string,
  value: React.CSSProperties,
): string => {
  if (!styleSheetLookup[targetClassName]) {
    styleSheetLookup[targetClassName] = {
      uuid: `${targetClassName.replace(/[0-9]/g, '')}`,
      items: [],
    }
  }
  styleSheetLookup[targetClassName].items.push(value)
  return styleSheetLookup[targetClassName].uuid
}

const camelToDash = (str: string) => {
  return str
    .replace(/(^[A-Z])/, (first: string) => first.toLowerCase())
    .replace(/([A-Z])/g, (letter: string) => `-${letter.toLowerCase()}`)
}

const generateItems = (props: any[]) => {
  const convertedItems: string[] = []
  for (const prop of props) {
    for (const key in prop) {
      if (prop[key]) {
        convertedItems.push(`${camelToDash(key)}: ${prop[key]};`)
      }
    }
  }
  return convertedItems.join('\n')
}

const generateStyleSheet = (styleSheetLookup: IStyleSheetLookup): string => {
  let classProps: string = ''
  const props: string[] = []
  for (const className in styleSheetLookup) {
    if (styleSheetLookup[className]) {
      const templateString: string = `
        .${styleSheetLookup[className].uuid} \{
            ${generateItems(styleSheetLookup[className].items)}
        \}
      `
      props.push(templateString)
    }
  }
  classProps = props.join(' ')
  return classProps
}

/**
 * Simple method to search through a css string and replace REM with actual px units.
 * @param css 
 * @param contextProps 
 * @returns 
 */
const convertRemToPx = (css: any, contextProps: IOmniPageSharedContextGlobalProps) => {
  if (!css) {
    return css
  }
  try {
    if (contextProps?.studioEditor) {
      let bodyFontSize: any = contextProps?.activeTheme?.bodyFontSize
      if (bodyFontSize?.indexOf('px') !== -1) {
        let size = bodyFontSize.split('px')[0]
        if (!isNaN(size)) {
          if (css.indexOf('rem') !== -1) {
            const matches: any = [...css.matchAll(/"[^"]+"|'[^']+'|url\([^\)]+\)|(\d*\.?\d+)rem/g)]
            let root = size
            let map: any = {}
            for (const found of matches) {
              let toReplace = found[0]
              let remValue = found[1]
              if (remValue) {
                let pxValue = `${remValue * root}px`
                map[toReplace] = {
                  key: toReplace,
                  value: pxValue,
                }
              }
            }
            let items: any[] = Object.values(map).sort((a: any, b: any) => {
              return a.key.length < b.key.length ? 1 : -1
            })
            let copy = css
            for (const item of items) {
              let key = item.key
              let value = item.value
              copy = copy.replaceAll(key, value)
            }
            return copy
          }
        }
      }
    }
    return css
  } catch (e) {
    console.error(e)
    return css
  }
}

export { generateClassName, generateStyleSheet, convertRemToPx }
