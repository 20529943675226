import * as React from 'react'
import { ICatalogConfig } from '../Catalog'
import { has } from '../../Common/Utils/lodash'
import RangeFilter, { IRangeFilterOption } from '../../Common/PriceRange/RangeFilter'
import { IUnbxdFacetType } from '../../Common/Utils/unbxd/unbxdInterfaces'
import css from './Navigation.scss'

export interface ICatalogNavigationItem {
  label: string
  code: string
  values: ICatalogNavigationItemValue[]
  type?: IUnbxdFacetType
  start?: number
  end?: number
  position?: number
}

export interface ICatalogNavigationItemValue {
  value: string
  code?: string
  count: number
}

export interface IFilterItem {
  title: string
  key: string
  value: string
}

export interface IProps {
  attributes: ICatalogNavigationItem[]
  category: any
  configuration: ICatalogConfig
  attributeLimit?: number
  filtersList?: IFilterItem[]
}

interface IState {
  toggledStates: {
    [x: string]: boolean
  }
  toggledAccordion: {
    [x: string]: boolean
  }
  mobileShowFilters: boolean
}

export default class CatalogNavigation extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const localState: {
      [x: string]: any
    } = {}
    for (const attribute of props.attributes) {
      localState[attribute.code] = false
    }
    this.state = {
      toggledStates: localState,
      toggledAccordion: localState,
      mobileShowFilters: false,
    }
  }

  public setToggleState = (section: string) => {
    const states = {
      ...this.state.toggledStates,
    }
    states[section] = !states[section]
    this.setState({
      toggledStates: states,
    })
  }

  public mobileToggleFiltersView = (evt: any) => {
    evt.preventDefault()
    const { mobileShowFilters } = this.state
    this.setState({
      mobileShowFilters: !mobileShowFilters,
    })
  }

  public toggleFiltersOpen = (section: string) => {
    const states = {
      ...this.state.toggledAccordion,
    }
    states[section] = !states[section]
    this.setState({
      toggledAccordion: states,
    })
  }

  public onAttributeClickEvent = (query: string) => {
    const { configuration, category } = this.props
    if (configuration.events && configuration.events.onAttributeFilterClick) {
      configuration.events.onAttributeFilterClick(query, category)
      return
    }
  }

  public getURLAttributeFilter = (query: string): string => {
    const { configuration, category } = this.props
    if (configuration.events && configuration.events.getURLAttributeFilter) {
      return configuration.events.getURLAttributeFilter(query, category)
    }
    return ''
  }

  public onRangeFilter = (rangeFilter: IRangeFilterOption) => {
    const { configuration } = this.props
    if (configuration.events && configuration.events.onRangeFilter) {
      configuration.events.onRangeFilter(rangeFilter)
      return
    }
  }

  public filterItems = (items: ICatalogNavigationItemValue[]) => {
    // const copy: ICatalogNavigationItemValue[] = [...items]
    // return copy.sort((a: ICatalogNavigationItemValue, b: ICatalogNavigationItemValue) => {
    //   return b.count - a.count
    // })
    return items
  }

  public renderAttribute = (item: ICatalogNavigationItemValue, section: ICatalogNavigationItem) => {
    const query = `${section.code}=${item.value}`
    return (
      <a href={this.getURLAttributeFilter(query)} onClick={this.onAttributeClickEvent.bind(this, query)}>
        {item.value} {item.count ? `(${item.count})` : ''}
      </a>
    )
  }

  public onClearFilter = (key: string, value?: string) => {
    const { category } = this.props
    const onRemoveFilter: any = has(this.props, ['configuration', 'events', 'onRemoveFilter'])
    if (onRemoveFilter) {
      onRemoveFilter(key, category, value)
    }
  }

  public renderActiveFilters = (filters: IFilterItem[], attributesCategory: ICatalogNavigationItem[]) => {
    // This change is needed to show the Display Name on the applied filters section instead of the field name.
    attributesCategory.forEach(filterRanges => {
      filters.forEach(filter => {
        if (filter.key === filterRanges.code) {
          filter.title = filterRanges.label
        }
      })
    })

    return (
      <div>
        {filters.map((filter: IFilterItem, index: number) => {
          return (
            <div key={index} className={css.filterContainer}>
              <div className={css.removeFilter} onClick={this.onClearFilter.bind(this, filter.key, filter.value)}>
                <i className="fas fa-times" />
              </div>
              <b>{filter.title}:</b>
              <span className={css.filterValue}>
                {filter.value
                  .replace('\\', '')
                  .replace('[', '$')
                  .replace(']', '')
                  .replace('TO ', '- $')}
              </span>
            </div>
          )
        })}
        <div className={css.clearFiltersButton}>
          <a
            style={{
              cursor: 'pointer',
            }}
            onClick={this.onClearFilter.bind(this, 'all')}
          >
            Clear All
          </a>
        </div>
      </div>
    )
  }

  public renderAttributesBody = (attributesCategory: ICatalogNavigationItem[]) => {
    const limit: number = this.props.attributeLimit || 8
    if (attributesCategory.length === 0) {
      return <div>Not found...</div>
    }

    return attributesCategory.map((attributeCategory: ICatalogNavigationItem, topLevelIndex: number) => {
      const isSectionExpanded: boolean = this.state.toggledStates[attributeCategory.code]
      const filteredItems: ICatalogNavigationItemValue[] = this.filterItems(attributeCategory.values)
      return (
        <div key={topLevelIndex} className={css.marginMe}>
          <div className={css.categoryItemHeader}>{attributeCategory.label}</div>
          <div className={css.categoryItemBody}>
            {filteredItems.map((item: ICatalogNavigationItemValue, index) => {
              return (
                <div
                  key={`${item.value}-${index}`}
                  className={`${index + 1 > limit && !isSectionExpanded ? css.hidden : css.shown} `}
                >
                  {this.renderAttribute(item, attributeCategory)}
                </div>
              )
            })}
          </div>
          {filteredItems.length > limit && (
            <div
              className={css.toggleVisibilityButton}
              onClick={this.setToggleState.bind(this, attributeCategory.code)}
            >
              {isSectionExpanded ? (
                <React.Fragment>Show Less</React.Fragment>
              ) : (
                <React.Fragment>Show More</React.Fragment>
              )}
            </div>
          )}
        </div>
      )
    })
  }

  public renderAttrFilter = (attributeCategory: ICatalogNavigationItem, topLevelIndex: number, limit: number) => {
    const isSectionOpen: boolean = this.state.toggledAccordion[attributeCategory.code]
    const isSectionExpanded: boolean = this.state.toggledStates[attributeCategory.code]
    const filteredItems: ICatalogNavigationItemValue[] = this.filterItems(attributeCategory.values)

    return (
      <div key={topLevelIndex} className={css.marginMe}>
        <div className={css.categoryItemHeader} onClick={this.toggleFiltersOpen.bind(this, attributeCategory.code)}>
          {attributeCategory.label}
          <span className={`${css.shopByToggleIcon} ${!isSectionOpen && css.plusIcon}`}>
            <i className="fas fa-plus" />
          </span>
          <span className={`${css.shopByToggleIcon} ${isSectionOpen && css.minusIcon}`}>
            <i className="fas fa-minus" />
          </span>
        </div>
        <div className={`${!isSectionOpen ? css.sectionHidden : css.categoryItemBody}`}>
          {attributeCategory.type === IUnbxdFacetType.RANGES ? (
            <div key={topLevelIndex}>
              <RangeFilter
                onRangeFilter={this.onRangeFilter}
                fieldFilter={attributeCategory.code}
                min={attributeCategory.start}
                max={attributeCategory.end}
              />
            </div>
          ) : (
            filteredItems.map((item: ICatalogNavigationItemValue, index) => {
              return (
                <div
                  key={`${item.value}-${index}`}
                  className={`${index + 1 > limit && !isSectionExpanded ? css.hidden : css.shown} `}
                >
                  {this.renderAttribute(item, attributeCategory)}
                </div>
              )
            })
          )}
        </div>
        {filteredItems.length > limit && (
          <div
            className={`${css.toggleVisibilityButton} ${!isSectionOpen ? css.sectionHidden : css.categoryItemBody}`}
            onClick={this.setToggleState.bind(this, attributeCategory.code)}
          >
            {isSectionExpanded ? (
              <React.Fragment>Show Less</React.Fragment>
            ) : (
              <React.Fragment>Show More</React.Fragment>
            )}
          </div>
        )}
      </div>
    )
  }

  public renderAttributesItem = (attributesCategory: ICatalogNavigationItem[]) => {
    const limit: number = this.props.attributeLimit || 8
    if (attributesCategory.length === 0) {
      return <div>Not found...</div>
    }
    return attributesCategory.map((attributeCategory: ICatalogNavigationItem, topLevelIndex: number) => {
      return this.renderAttrFilter(attributeCategory, topLevelIndex, limit)
    })
  }
  public render() {
    if (this.props.attributes.length === 0) {
      return <div>No attribute filters to render...</div>
    }
    return (
      <div className={css.navContainer}>
        <div className={css.filterActiveContainer}>
          {this.props.filtersList && this.props.filtersList.length > 0 && (
            <React.Fragment>
              <b className={css.categoryTopHeader}>CURRENTLY SHOPPING BY:</b>
              <div className={css.activeFilters}>
                {this.renderActiveFilters(this.props.filtersList, this.props.attributes)}
              </div>
            </React.Fragment>
          )}
        </div>
        <div onClick={this.mobileToggleFiltersView}>
          <hr className={css.hrVisible} />
          <b className={css.categoryTopHeader}>
            SHOP BY
            <span className={`${css.mobileShopByIcon} ${!this.state.mobileShowFilters && css.mobilePlusIcon}`}>
              <i className="fas fa-plus" />
            </span>
            <span className={`${css.mobileShopByIcon} ${this.state.mobileShowFilters && css.mobileMinusIcon}`}>
              <i className="fas fa-minus" />
            </span>
          </b>
          <hr
            className={`${this.state.mobileShowFilters ? css.hrVisible : css.hrNotVisible}`}
            style={{
              marginBottom: '0',
            }}
          />
        </div>
        <div className={`${css.categories} ${this.state.mobileShowFilters && css.filtersOpen}`}>
          {this.renderAttributesItem(this.props.attributes)}
        </div>
      </div>
    )
  }
}
