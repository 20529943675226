import * as React from 'react'
import { ICatalogConfig } from '../../Catalog'
import { IProduct } from '../../../PDP'
import ThemedButton from '../../../Common/Button/Button'
import PriceDisplay from '../../../PDP/PriceDisplay'

import css from './Thumbnail.scss'

export interface IProps {
  product: IProduct
  configuration: ICatalogConfig
  defaultThumbnail?: string
  usePriceDivider?: boolean
}

class Thumbnail extends React.Component<IProps, any> {
  public goToProduct = (product: IProduct) => {
    const { configuration } = this.props
    this.onUnbxdProductClick(product)
    if (configuration.events && configuration.events.goToProduct) {
      configuration.events.goToProduct(product)
      return
    }
  }

  public getURLToProduct = (product: IProduct): string => {
    const { configuration } = this.props
    if (configuration.events && configuration.events.getURLToProduct) {
      return configuration.events.getURLToProduct(product)
    }
    return ''
  }

  public onUnbxdProductClick = (product: IProduct) => {
    const { configuration } = this.props
    if (configuration.events && configuration.events.onUnbxdProductClick) {
      configuration.events.onUnbxdProductClick(product)
      return
    }
  }

  public addToCart = (product: IProduct) => {
    const { configuration } = this.props
    if (configuration.events && configuration.events.addToCart) {
      configuration.events.addToCart(product)
      return
    }
  }

  public renderAtcButton = () => {
    const { configuration, product } = this.props
    if (configuration && configuration.renderProps && configuration.renderProps.renderAtcButton) {
      return configuration.renderProps.renderAtcButton(product)
    }
    return (
      <ThemedButton fullWidth={true} onClick={this.addToCart.bind(this, this.props.product)}>
        ADD TO CART
      </ThemedButton>
    )
  }

  public getDefaultThumbnail = (): string => {
    return this.props.product.thumbnail || this.props.defaultThumbnail || 'https://via.placeholder.com/266x151'
  }

  public render() {
    const classes = [css.productThumbnail]
    const { product } = this.props
    if (this.props.configuration?.renderProps?.renderProductThumbnailContainer) {
      return this.props.configuration.renderProps.renderProductThumbnailContainer(product)
    }

    return (
      <div className={classes.join(' ')}>
        <div className={css.thumbnailInner}>
          <a href={this.getURLToProduct(product)} onClick={this.goToProduct.bind(this, product)}>
            <div
              className={css.imageContainer}
              style={{
                backgroundImage: `url(${this.getDefaultThumbnail()})`,
              }}
            />
            <div className={css.thumbnailDetails}>
              {product.brand && <div className={css.thumbnailBrand}>{product.brand}</div>}
              <div className={css.thumbnailTitle}>
                <div>{product.name}</div>
              </div>
              {product.modelnumber && (
                <div className={css.thumbnailModel}>Model: {product.modelnumber.toUpperCase()}</div>
              )}
              <div className={css.thumbnailPrice}>
                <PriceDisplay product={product} usePriceDivider={this.props.usePriceDivider} />
              </div>
            </div>
          </a>
          <div className={css.atcButton}>{this.renderAtcButton()}</div>
        </div>
      </div>
    )
  }
}

export default Thumbnail
