import * as React from 'react'
import { ElementType } from '../../../interfaces'

export interface IOmniElementDividerProps {
  thickness: string
  width: string
  style: any
  color: any
  verticalSpacing: string
}
export interface IProps {
  data: IOmniElementDividerProps
}

const IOmniElementDivider = (props: IProps) => {
  let thickness = props.data.thickness || '1px'
  let width = '100%'
  let style = props.data.style?.value || 'solid'
  let color = props.data.color || '#ccc'
  let verticalSpacing = props.data.verticalSpacing || '15px'
  return (
    <div
      style={{
        margin: `${verticalSpacing} auto`,
        width: width,
        borderBottom: `${thickness} ${style} ${color}`,
      }}
    ></div>
  )
}

export default {
  type: ElementType.SEPARATOR,
  component: IOmniElementDivider,
}
