export enum PAGE_COMPONENT_PLUGIN_TYPE {
  VISIBILITY_BREAKPOINT_PLUGIN = 'VISIBILITY_BREAKPOINT_PLUGIN',
  COLUMN_LAYOUTS = 'COLUMN_LAYOUTS',
  DRAG_AND_DROP = 'DRAG_AND_DROP',
  ANIMATIONS = 'ANIMATIONS',
  STYLES = 'STYLES',
  IDENTIFIERS = 'IDENTIFIERS',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
  IMAGE_TRANSFORMATION = 'IMAGE_TRANSFORMATION',
}
