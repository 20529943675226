import * as React from 'react'
import { ElementType, IOmniComponent, IPageContainer } from '../../../interfaces'
// import style from './BlockThumbnails.module.scss'
import { Col, Row } from '../../../../Common/Layout'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import { has } from '../../../../Common/Utils/lodash'
import CACHE_CONTSTANT from '../../../../Common/Utils/cache/constants'
import css from './BlockThumbnailsV2.module.scss'
import BlockThumbnail from './BlockThumbnail'

export interface IProps {
  data: IOmniComponentBlockThumbnailsProps
  contextProps: IOmniPageSharedContextGlobalProps
}

export interface IOmniComponentBlockThumbnailsProps extends IOmniComponent {
  categoryIds: IOption[]
  onClickEndpoint: string
  endpoint: string
  pageIds: IOption[]
  onPageClickEndpoint: string
  pageType: IOption
  customRenderHook: string
  rowCount: IOption | undefined
  renderType: IOption
}

interface IOption {
  value: string
  label: string
}

export interface IBlock {
  id: string
  title: string
  thumbnail: string
  slug: string
  route: string
  pagecontainer: IPageContainer
  heroImageUrl: string
}

interface IState {
  blocks: IBlock[]
}

const chunk = (arr: any, chunkSize = 1, cache: any[] = []) => {
  const tmp = [...arr]
  if (chunkSize <= 0) {
    return cache
  }
  while (tmp.length) {
    cache.push(tmp.splice(0, chunkSize))
  }
  return cache
}

class BlockThumbnails extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { blocks: [] }
  }
  public componentDidMount() {
    this.getThumbnails()
  }

  public componentDidUpdate(_prevProps: any, prevState: any) {
    if (this.props.data.pageIds && this.props.data.pageIds?.length !== prevState?.blocks?.length) {
      this.getThumbnails()
    }
  }

  public getThumbnails = async () => {
    const transport: any = has(this.props, ['contextProps', 'transport'])
    if (transport) {
      const { renderType } = this.props.data
      const targetIds: IOption[] = renderType.value === 'pages' ? this.props.data.pageIds : this.props.data.categoryIds
      const results: IBlock[] = []
      if (targetIds && targetIds.length) {
        const ids = targetIds.map(id => id.value)
        const idsCacheKey = ids.map(id => id.slice(-7)).join('-')
        try {
          if (renderType.value !== 'pages') {
            const pageQuery: string = `

            {
              categories (where: {_id_in: ["${ids.join('", "')}"]}) {
                id
                _id
                title
                slug
                heroBanner
                thumbnail
              }
            }
            `
            const response = await transport.post(`/graphql`, {
              query: pageQuery,
              cacheKey: `${CACHE_CONTSTANT.FRONTEND.CONTENT.PAGE}-categories-${idsCacheKey}`,
            })
            const categories = response.data.data.categories as IBlock[]
            const sortedCategories = ids.map(id => categories.find(cat => cat.id === id)) as IBlock[]
            results.push(...sortedCategories)
          } else {
            const catQuery: string = `
            {
              pages (where: {_id_in: ["${ids.join('", "')}"]}) {
                id
                _id
                title
                route
                heroImageUrl
                blogBannerImageUrl
                thumbnail
                type
                metaImageUrl
                heroText
                description
                publishDate
                pagecontainer {
                  route
                  title
                }
              }
            }
          `
            const response = await transport.post(`/graphql`, {
              query: catQuery,
              cacheKey: `${CACHE_CONTSTANT.FRONTEND.CONTENT.PAGE}-pages-${idsCacheKey}`,
            })
            const pages = response.data.data.pages as IBlock[]
            const sortedPages = ids.map(id => pages.find(page => page.id === id)) as IBlock[]
            results.push(...sortedPages)
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e.toString())
        }
      }
      this.setState({ blocks: results })
    }
  }

  public getDefaultColSpan = (): number => {
    const { rowCount } = this.props.data
    if (rowCount) {
      switch (rowCount.value) {
        case '3':
          return 4
        case '4':
          return 3
        case '6':
          return 2
      }
    }
    return 4
  }

  public navigateTo = (link: string) => {
    location.href = link
  }

  public renderBlockContent = (block: IBlock[]) => {
    const { contextProps } = this.props
    const { renderType, rowCount, customRenderHook } = this.props.data
    const targetRowCount: number = rowCount !== undefined ? parseInt(rowCount.value, 10) : 3
    if (!block.length) {
      return (
        <div style={{ textAlign: 'center', padding: '15px', border: '1px solid #eee' }}>
          <span style={{ color: 'black', marginLeft: '10px' }}>No grid items found...</span>
        </div>
      )
    }
    const rows: IBlock[][] = chunk(block, targetRowCount)
    return rows.map((row: IBlock[], index2: number) => {
      const customMethod: any = has(contextProps, ['renderHooks', customRenderHook])
      return (
        <Row key={index2}>
          {row.map((innerBlock: IBlock | undefined, index: number) => {
            if (innerBlock) {
              const finalEndpoint =
                renderType.value === 'pages'
                  ? this.props.data.onPageClickEndpoint.replace(
                      '${slug}',
                      innerBlock?.pagecontainer?.route || innerBlock.route,
                    )
                  : this.props.data.onClickEndpoint.replace('${slug}', innerBlock.slug)
              return (
                <Col sm={this.getDefaultColSpan()} key={index}>
                  <div onClick={this.navigateTo.bind(this, finalEndpoint)} className={css.anchorTag}>
                    {customMethod ? customMethod(innerBlock) : <BlockThumbnail block={innerBlock} />}
                  </div>
                </Col>
              )
            } else {
              return <div key={index} />
            }
          })}
        </Row>
      )
    })
  }
  public render() {
    return <React.Fragment>{this.renderBlockContent(this.state.blocks)}</React.Fragment>
  }
}

export default {
  type: ElementType.BLOCK_THUMBNAILS,
  component: BlockThumbnails,
}
