import * as React from 'react'
import { ElementType } from '../../../interfaces'
import { IImageTransformationProps } from '../../../Plugins/ImageTransformation/imageTransformationInterface'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import Slide from './BannerSlide'
import css from './TextBannerV2.module.scss'

/// ------------------------------------------------ MISC INTERFACES -------------------------------------
export interface IOmniComponentsTextBannerProps extends IImageTransformationProps {
  title: string
  interval: number
  sticky: boolean
  slideAnimation: { value: string }
  backgroundColor: string
  enableNavigation: boolean
  slideshowelements: ITextSlideShowElement[]
  target?: { value: string }
}

export interface ITextSlideShowElement {
  id: string
  content: any
}
// ------------------------------------------------------ CLASS ---------------------------------------------
export interface ISlideShowProps {
  data: IOmniComponentsTextBannerProps
  contextProps: IOmniPageSharedContextGlobalProps
}
interface ISlideShowState {
  currentSlide: number
  height?: number
  previousSlide?: number
}
class SlideShow extends React.PureComponent<ISlideShowProps, ISlideShowState> {
  public interval: any
  constructor(props: ISlideShowProps) {
    super(props)
    this.state = { currentSlide: 0 }
  }
  public componentDidMount(): void {
    const { interval } = this.props.data
    if (interval !== undefined && interval > 0) {
      this.interval = setInterval(this.incrementSlide, interval)
    }

    if (process.browser) {
      //timeout required - when refreshing the page or nagivating to different pages the height comes back as double of what it should be
      // to fix - set timeout of 250ms and the correct height gets applied
      setTimeout(() => {
        this.setState({ height: document?.getElementsByClassName(css.slide)[0]?.getBoundingClientRect().height })
      }, 250)
    }
  }

  // public componentDidUpdate() {
  //   console.log('updating')
  // }

  // ----------------------------------------------  HELPER METHODS ------------------------------------------
  public incrementSlide = (by: number) => {
    const { data } = this.props
    let { currentSlide } = this.state
    const len = data.slideshowelements.length
    currentSlide += by !== undefined ? by : 1
    this.setState({ previousSlide: currentSlide - 1 })
    if (currentSlide < 0) {
      currentSlide = len - 1
    }
    if (currentSlide >= len) {
      currentSlide = 0
      this.setState({ previousSlide: len - 1 })
    }
    if (currentSlide === 1) {
      this.setState({ previousSlide: 0 })
    }
    this.setState({ currentSlide })
  }

  public goToSlide = (index: number) => {
    this.setState({
      currentSlide: index,
    })
  }

  public generateClasses = (): string => {
    const classes: string[] = []
    const { sticky } = this.props.data
    classes.push(css.slideContainer)
    if (sticky && !this.props.contextProps.studioEditor) {
      classes.push(css.sticky)
    }
    return classes.join(' ')
  }

  /// ----------------------------------------------- RENDER METHODS -----------------------------------------
  public render(): any {
    const { data } = this.props
    if (!data) {
      return <div>Loading...</div>
    }
    const { currentSlide, previousSlide, height } = this.state
    const { backgroundColor, sticky } = this.props.data
    return (
      <div style={{ marginBottom: sticky && !this.props.contextProps.studioEditor ? `${height}px` : '' }}>
        <div
          className={this.generateClasses()}
          style={{
            height: height,
            overflow: 'hidden',
            backgroundColor: backgroundColor,
          }}
        >
          {data.slideshowelements.map((item: ITextSlideShowElement, index: number) => {
            return (
              <Slide
                key={index}
                item={item}
                index={index}
                currentSlide={currentSlide}
                numOfSlides={data.slideshowelements.length}
                previousSlide={previousSlide}
                data={this.props.data}
                contextProps={this.props.contextProps}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default {
  type: ElementType.TEXT_BANNER,
  component: SlideShow,
}
