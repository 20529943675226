import * as React from 'react'

import { ElementType } from '../../interfaces'
import SlideShow from '../../Common/SlideShow/SlideShow'
import { ITestimonialSlideShowElement, IOmniComponentTestimonalSlideShowProps } from './interfaces'
import TestimonialSlide from './TestimonialSlide'

const SlideshowTestimonial = ({ data }: { data: IOmniComponentTestimonalSlideShowProps }) => {
  const { interval, slides, enableNavigation, enableDotNavigation, maxItemsPerSlide, fontSize } = data

  const customSlides = slides.map((item: ITestimonialSlideShowElement, index: number) => (
    <TestimonialSlide key={`testimonialSlide-${index}`} item={item} fontSize={fontSize} />
  ))

  return (
    <SlideShow
      interval={interval}
      enableDotNavigation={enableDotNavigation}
      enableNavigation={enableNavigation}
      maxItemsPerSlide={maxItemsPerSlide}
      slides={customSlides}
    />
  )
}

export default {
  type: ElementType.SLIDESHOW_TESTIMONIAL,
  component: SlideshowTestimonial,
}
