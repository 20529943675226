interface IExtractElementsByTagNameProps {
  transitionNode: HTMLElement
  tagName: string
  onScriptLoad?: () => void
}

interface IAppendArrayOfElementsProps {
  parentNode: HTMLElement
  elements: HTMLElement[]
}

/**
 * A function to extract elements by tag name from a transition node
 * @param {Object} arguments
 * @param {HTMLElement} arguments.transitionNode An intermediate node created to be stripped from the nodes that need special treatment to be rendered like script or style
 * @param {string} arguments.tagName The name of the tag to extract
 * @param {Function} arguments.onLoad A function to execute on external scripts to trigger when it finished loading
 * @returns {{HTMLElement, HTMLElements[]}}
 */
export const extractElementsByTagName = ({ transitionNode, tagName, onScriptLoad }: IExtractElementsByTagNameProps) => {
  const elementsByTag = transitionNode.getElementsByTagName(tagName)
  const extractedElements = []
  const vipExtractedElements = []

  /**
   * Get all matching elements found in the transition node
   * The lack of an increment on the loop may be counter intuitive
   * As we are looping through a list of HTML elements taken from a node
   * but we are also removing the same element from the same node
   * the array itself is mutating and it will reach a point where it is empty
   * triggering the exit condition.
   */
  for (let i = 0, elementByTag; (elementByTag = elementsByTag[i]); ) {
    const elementContent = elementByTag.innerHTML

    // Creating an element that will be interpreted completely. Needed for script and style tags
    const executableElement = document.createElement(tagName)
    // Re add attributes
    const attributes = elementByTag?.attributes ?? []
    for (let j = 0, attribute; (attribute = attributes[j]); j++) {
      executableElement.setAttribute(attribute.name, attribute.value)
    }

    /**
     * Add content and append to the vip scripts or the normal ones
     * A vip script could include external scripts or external css files that needs to be loaded first.
     */
    if (elementContent) {
      executableElement.innerHTML = elementContent
      extractedElements.push(executableElement)
    } else {
      if (onScriptLoad) {
        executableElement.onload = onScriptLoad
      }
      vipExtractedElements.push(executableElement)
    }

    /**
     * Remove from the transition node all matching elements to have it clean for the next step.
     * This will mutate the array allowing the loop to not need an `i++` statement.
     */
    elementByTag?.parentElement?.removeChild(elementByTag)
  }

  return { transitionNode, extractedElements, vipExtractedElements }
}

/**
 * A function to extract elements by tag name from a transition node
 * @param {Object} arguments
 * @param {HTMLElement} arguments.parentNode The real node where the extracted elements will be appended.
 * @param {HTMLElement[]} arguments.elements A list of elements to be added one by one
 */
export const appendArrayOfElements = ({ parentNode, elements }: IAppendArrayOfElementsProps) => {
  for (let i = 0, element; (element = elements[i]); i++) {
    parentNode?.append(element)
  }
}
