import * as React from 'react'
import { ElementType } from '../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { IProductGroup } from '../../../PDP'
import css from './ProductGroupCarousel.scss'
import ProductGroupCarouselItem from './ProductGroupCarouselItem'

export interface IProductGroupCarousel {
  title: string
  endpoint: string
  layout: {
    value: string
    label: string
  }
  groups: IProductGroup[]
  customComponentRefId: string | undefined
}

export interface IProductGroupCarouselProps {
  data: IProductGroupCarousel
  contextProps: IOmniPageSharedContextGlobalProps
}

interface IState {
  startProduct: number
  productsPerLine: number
  navigateLeft: boolean
  navigateRight: boolean
}

class ProductGroupCarousel extends React.Component<IProductGroupCarouselProps, IState> {
  constructor(props: IProductGroupCarouselProps) {
    super(props)
    this.state = {
      productsPerLine: 6,
      startProduct: 0,
      navigateLeft: false,
      navigateRight: this.props.data.groups.length > 6,
    }
  }
  public navigateProducts = (by: number) => {
    const { data } = this.props
    let { startProduct, navigateLeft, navigateRight } = this.state
    const { productsPerLine } = this.state
    const len = data.groups.length
    startProduct += by !== undefined ? by * productsPerLine : 0
    navigateLeft = true
    navigateRight = true

    if (by < 0 && startProduct < 0) {
      startProduct = 0
      navigateLeft = false
    }
    if (by > 0 && startProduct + productsPerLine >= len) {
      startProduct = len - productsPerLine
      navigateRight = false
    }
    this.setState({
      startProduct,
      navigateLeft,
      navigateRight,
    })
  }

  public render() {
    const { data, contextProps } = this.props

    if (!data) {
      return <div>Loading...</div>
    }
    return (
      <React.Fragment>
        <div className={css.productsContainerWrapper}>
          {data.title && (
            <div className={css.hdrTextCenter}>
              <span>{data.title}</span>
              <div className={css.border} />
            </div>
          )}

          <div className={`${css.productRowContainer}`}>
            <ProductGroupCarouselItem
              title={`Dynamic Product Carousel`}
              enableNavigation={true}
              carouselElements={data.groups}
              contextProps={contextProps}
              customComponentRefId={this.props.data.customComponentRefId}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default {
  type: ElementType.PRODUCT_GROUP_CAROUSEL,
  component: ProductGroupCarousel,
}
