import * as React from 'react'
import { ElementType } from '../../../interfaces'
import { IImageTransformationProps } from '../../../Plugins/ImageTransformation/imageTransformationInterface'
import appendImageTransformationProxyUrl from '../../../Plugins/ImageTransformation/imageTransformationUtils'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import css from './BannerV2.module.scss'

export interface IOmniElementBannerProps extends IImageTransformationProps {
  id: string
  url: string
  parallax: boolean
  verticalPadding: string
  backgroundSize: string
  overlayOpacity: boolean
  title: any
  backgroundPosition: string
  backgroundColor: string
  body: any
  alt: string
  overrideFontColor?: boolean
  fontColor?: string
}

interface IProps {
  data: IOmniElementBannerProps
  contextProps: IOmniPageSharedContextGlobalProps
}

const OmniElementBanner = (props: IProps) => {
  const { title, body, alt } = props.data
  const getBackgroundSize = () => {
    const targetSize: string = props.data.backgroundSize !== undefined ? props.data.backgroundSize : 'cover'
    return targetSize
  }
  const hasOpacity = () => {
    const giveOpacity: boolean = props.data.overlayOpacity !== undefined ? props.data.overlayOpacity : false
    return giveOpacity ? css.masked : css.unmasked
  }
  const hasParallax = () => {
    const { parallax } = props.data
    const giveParallax: boolean = parallax !== undefined ? parallax : true
    return giveParallax ? css.parallax : ''
  }
  const hasPadding = () => {
    const { verticalPadding } = props.data
    const doesHavePadding: boolean = verticalPadding !== undefined
    return {
      padding: `${doesHavePadding ? verticalPadding : '150px'} 0`,
    }
  }
  const getBackgroundPosition = () => {
    const { backgroundPosition } = props.data
    const hasPosition: boolean = backgroundPosition !== undefined
    return hasPosition ? backgroundPosition : 'initial'
  }
  const formatBackgroundImage = () => {
    return appendImageTransformationProxyUrl({
      targetUrl: props.data.url,
      context: props.contextProps,
      data: props.data,
    })
  }

  const isHtml = (text: any) => {
    if (text.html !== undefined) {
      return true
    }
    return false
  }

  const renderBody = () => {
    let { body } = props.data
    if (!isHtml(body)) {
      return <p className={`${css.bodyText} banner-body`}>{body}</p>
    }
    return <div className={`${css.bodyText} banner-body`} dangerouslySetInnerHTML={{ __html: body.html }} />
  }

  const renderHeader = () => {
    let { title } = props.data
    if (!isHtml(title)) {
      return <h1 className="banner-header">{title}</h1>
    }
    return <div className="banner-header" dangerouslySetInnerHTML={{ __html: title.html }} />
  }

  return (
    <React.Fragment>
      {props.data.overrideFontColor && (
        <style
          dangerouslySetInnerHTML={{
            __html: `
                #frontend-${props.data.id} .banner-body *,  #frontend-${props.data.id} .banner-body {
                  color: ${props.data.fontColor} !important;
                }
                #frontend-${props.data.id} .banner-header *, #frontend-${props.data.id} .banner-header {
                  color: ${props.data.fontColor} !important;
                }
            `,
          }}
        />
      )}
      <div
        id={`frontend-${props.data.id}`}
        className={`${hasOpacity()} ${hasParallax()}`}
        role="img"
        aria-label={alt}
        title={alt}
        style={{
          backgroundImage: `url('${formatBackgroundImage()}')`,
          backgroundPosition: getBackgroundPosition(),
          backgroundSize: getBackgroundSize(),
        }}
      >
        <div className={css.opener} style={hasPadding()}>
          <div className={css.inner}>
            {title && renderHeader()}
            {body && renderBody()}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default {
  type: ElementType.BANNER,
  component: OmniElementBanner,
}
