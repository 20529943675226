import * as React from 'react'
import { ElementType } from '../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import css from './DynamicAdWidget.module.scss'
import DynamicAdWidgetItem from './DynamicAdWidgetItem'

export interface IAdWidget {
  title: string
  endpoint: string
  layout: {
    value: string
    label: string
  }
  products: IDynamicProduct[]
  hideATC?: boolean
}

export interface IAdWidgetProps {
  data: IAdWidget
  contextProps: IOmniPageSharedContextGlobalProps
}

export interface IDynamicProduct {
  id: string
  sku: string
  name: string
  thumbnail: string
  imagesArray: string[]
  alt: string
  description?: string
  price: number
  ourPrice: number
  salePrice: number
  map: number
  marketPromoTitle: string
  quantity?: number
  brand: string
  modelnumber: string
}
interface IState {
  startProduct: number
  productsPerLine: number
  navigateLeft: boolean
  navigateRight: boolean
  preload: boolean
  products: IDynamicProduct[]
}

class DynamicAdWidget extends React.Component<IAdWidgetProps, IState> {
  constructor(props: IAdWidgetProps) {
    super(props)
    this.state = {
      productsPerLine: 6,
      startProduct: 0,
      navigateLeft: false,
      navigateRight: this.props.data.products.length > 6,
      preload: props.contextProps.events?.onPreloadDynamicAdItems !== undefined,
      products: this.props.data.products || [],
    }
  }

  componentDidMount = async () => {
    try {
      if (this.props.contextProps.events?.onPreloadDynamicAdItems) {
        const updatedProducts: IDynamicProduct[] = await this.props.contextProps.events.onPreloadDynamicAdItems(
          this.props.data,
        )
        this.setState({ products: updatedProducts, preload: false })
      }
    } catch (e) {
      this.setState({ products: [], preload: false })
    }
  }

  public navigateProducts = (by: number) => {
    const { data } = this.props
    let { startProduct, navigateLeft, navigateRight } = this.state
    const { productsPerLine } = this.state
    const len = data.products.length
    startProduct += by !== undefined ? by * productsPerLine : 0
    navigateLeft = true
    navigateRight = true

    if (by < 0 && startProduct < 0) {
      startProduct = 0
      navigateLeft = false
    }
    if (by > 0 && startProduct + productsPerLine >= len) {
      startProduct = len - productsPerLine
      navigateRight = false
    }
    this.setState({
      startProduct,
      navigateLeft,
      navigateRight,
    })
  }

  public render() {
    const { data, contextProps } = this.props
    const { preload, products } = this.state
    if (!data || preload) {
      return <div>Loading...</div>
    }

    if (products.length <= 0) {
      return (
        <div style={{ textAlign: 'center' }}>
          <p>No products could be found...</p>
        </div>
      )
    }

    if (this.props?.contextProps?.renderHooks?.renderCustomCarousel) {
      return this.props?.contextProps?.renderHooks?.renderCustomCarousel(data, undefined, contextProps)
    }

    return (
      <React.Fragment>
        <div className={css.productsContainerWrapper}>
          {data.title && (
            <div className={css.hdrTextCenter}>
              <span>{data.title}</span>
              <div className={css.border} />
            </div>
          )}

          <div className={`${css.productRowContainer}`}>
            <DynamicAdWidgetItem
              title={`Dynamic Product Carousel`}
              enableNavigation={true}
              carouselElements={products}
              contextProps={contextProps}
              hideATC={this.props.data.hideATC}
              data={this.props.data}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default {
  type: ElementType.DYNAMICADWIDGET,
  component: DynamicAdWidget,
}
