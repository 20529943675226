import * as React from 'react'
import { ElementType } from '../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { IImageTransformationProps } from '../../Plugins/ImageTransformation/imageTransformationInterface'
import ImagesWithOverlayItem from './ImagesWithOverlayItem'
import css from './ImagesWithOverlay.module.scss'

/// ------------------------------------------------ MISC INTERFACES -------------------------------------
export interface IOmniComponentImagesOverlayProps extends IImageTransformationProps {
  title: string
  titleAlign: {
    value: any
    label: any
  }
  textAlignHoriz: {
    value: any
    label: any
  }
  textAlignVert: {
    value: any
    label: any
  }
  imagerowelements: IImagesWithOverlayElement[]
}

export interface IImagesWithOverlayElement {
  id: string
  text: {
    html: string
    css: string
  }
  imageWidth?: string
  height?: string
  backgroundSize?: {
    value: any
    label: any
  }
  backgroundImagePosition?: string
  padding?: string
  buttonText: string
  buttonBackgroundColor: string
  buttonFontColor: string
  buttonBorder: string
  alt: string
  imageUrl: string
  url: string
  textAlignHoriz: {
    value: any
    label: any
  }
  textAlignVert: {
    value: any
    label: any
  }
}

// ------------------------------------------------------ CLASS ---------------------------------------------
export interface IImagesOverlayProps {
  data: IOmniComponentImagesOverlayProps
  contextProps: IOmniPageSharedContextGlobalProps
}
interface IImageOverlayState {
  windowWidth: number
}
class ImagesWithOverlay extends React.PureComponent<IImagesOverlayProps, IImageOverlayState> {
  public render() {
    const { data } = this.props
    if (!data) {
      return <div>Loading...</div>
    }
    const titleAlign =
      data.titleAlign.value === 'left'
        ? css.textLeft
        : data.titleAlign.value === 'center'
        ? css.textCenter
        : css.textRight
    return (
      <React.Fragment>
        {data.title && (
          <div className={`${css.rowTitle} ${titleAlign}`}>
            <b>{data.title}</b>
          </div>
        )}

        <div className={`${css.imagesWithOverlayContainer} ${css.stacked}`}>
          {data.imagerowelements.map((item: IImagesWithOverlayElement, index: number) => {
            return (
              <ImagesWithOverlayItem
                key={index}
                item={item}
                textAlignHoriz={item.textAlignHoriz || data.textAlignHoriz}
                textAlignVert={item.textAlignVert || data.textAlignVert}
                contextProps={this.props.contextProps}
                data={this.props.data}
              />
            )
          })}
        </div>
      </React.Fragment>
    )
  }
}

export default {
  type: ElementType.IMAGESWITHOVERLAY,
  component: ImagesWithOverlay,
}
