import * as React from 'react'
import OmniFactory from './OmniFactory/OmniFactory'
import OmniFactoryV2 from './OmniFactory/v2/OmniFactoryV2'
import { IOmniComponent, IOmniComponentLookup } from './interfaces'

export interface ILayoutProps {
  preLoadedData: IOmniComponentLookup
  version?: string
}

const OmniLayoutManager = (props: ILayoutProps) => {
  const rows: IOmniComponent[] = []
  const { preLoadedData } = props
  for (const id in preLoadedData) {
    if (preLoadedData[id]) {
      rows.push(preLoadedData[id])
    }
  }

  const renderPageBasedOnVersion = (version: string): any => {
    if (version === '2') {
      return rows.map((row, index) => {
        return <OmniFactoryV2 key={index} data={row} />
      })
    } else {
      return rows.map((row, index) => {
        return <OmniFactory key={index} data={row} />
      })
    }
  }

  return <React.Fragment>{renderPageBasedOnVersion(props.version ?? '1')}</React.Fragment>
}

export default OmniLayoutManager
