import * as React from 'react'
import { ElementType, IOmniComponent } from '../../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import { convertRemToPx } from '../../../Utils/classUtils'
import style from './TextBlockV2.module.scss'

export interface IProps {
  data: IOmniComponentTextBlockProps
  contextProps: IOmniPageSharedContextGlobalProps
}

export interface IOmniComponentTextBlockProps extends IOmniComponent {
  richText: {
    html: string
    css: string
  }
  overrideFontColor?: boolean
  fontColor: any
}

const TextBlock = (props: IProps) => {
  const { css, html } = props.data.richText
  
  return (
    <React.Fragment>
      {css && (
        <style
          dangerouslySetInnerHTML={{
            __html: convertRemToPx(css, props.contextProps),
          }}
        />
      )}
      {props.data.overrideFontColor && (
        <style
          dangerouslySetInnerHTML={{
            __html: `
                #text-block-${props.data.id} * {
                  color: ${props.data.fontColor} !important;
                }
                #text-block-${props.data.id} * {
                  color: ${props.data.fontColor} !important;
                }
            `,
          }}
        />
      )}
      <div className={`${style.textBlockContainer}`} id={`text-block-${props.data.id}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </div>
    </React.Fragment>
  )
}

export default {
  type: ElementType.TEXTBLOCK,
  component: TextBlock,
}
