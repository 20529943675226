import * as React from 'react'
import { ElementType } from '../../interfaces'
import { IImageTransformationProps } from '../../Plugins/ImageTransformation/imageTransformationInterface'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import VerticalCarousel, { ISliderItem } from './VerticalCarousel/VerticalCarousel'

export interface IImageGalleryItem extends ISliderItem {
  id: string
}
export interface IOmniComponentImageGalleryProps extends IImageTransformationProps {
  imagegalleryelements: IImageGalleryItem[]
}

export interface IImageGalleryProps {
  data: IOmniComponentImageGalleryProps
  contextProps: IOmniPageSharedContextGlobalProps
}

class ImageGallery extends React.PureComponent<IImageGalleryProps> {
  // public goToImage = (index: number) => {
  //   this.setState({
  //     currentImage: index,
  //   })
  // }
  public render() {
    const { data } = this.props
    if (!data) {
      return <div>Loading...</div>
    }
    return (
      <React.Fragment>
        <VerticalCarousel
          sliderData={data.imagegalleryelements}
          data={this.props.data}
          contextProps={this.props.contextProps}
        />
      </React.Fragment>
    )
  }
}

export default {
  type: ElementType.IMAGE_GALLERY,
  component: ImageGallery,
}
