import * as React from 'react'
import { ElementType } from '../../../interfaces'
import PageContext, { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import { MERCE_CLASS_NAMES } from '../../../../interfaces'
import css from './ButtonV2.module.scss'

export interface IOmniElementButtonProps {
  text: string
  padding: string
  margins: string
  buttonColor: string
  buttonColorHover: string
  buttonFontColor: string
  buttonFontColorHover: string
  buttonClassSet: {
    value: any
    label: any
  }
  useDropshadow: boolean
  border: string
  borderRadius?: string
  textAlign?: { value: string; label: string }
  boxShadow?: { value: string; label: string }
  width: string
  url: string
  target: {
    value: any
    label: any
  }
  icon?: string
  clickAction?: {
    value: string
    label: string
  }
  eventTrigger?: string
}

export interface IProps {
  data: IOmniElementButtonProps
  contextProps: IOmniPageSharedContextGlobalProps
}

interface IState {
  isHover: boolean
}

class IOmniElementButton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { isHover: false }
  }
  public onHover = () => {
    this.setState({ isHover: true })
  }
  public onHoverOut = () => {
    this.setState({ isHover: false })
  }
  public applyLayout = () => {
    const { padding, margins, buttonClassSet, textAlign } = this.props.data
    // const targetWidth: string = width === undefined ? '100%' : width
    const targetMargins: string = margins === undefined ? '0 0 0 0' : margins
    if (buttonClassSet && buttonClassSet.value) {
      return {
        // width: targetWidth,
        margin: targetMargins,
      }
    }
    const targetPadding: string = padding === undefined ? '10px 10px 10px 10px' : padding
    const styles = {
      textAlign: textAlign?.value ? textAlign?.value : 'center',
      // width: targetWidth,
      margin: targetMargins,
      padding: targetPadding,
    }
    return styles
  }
  public getButtonClasses = () => {
    const { buttonClassSet } = this.props.data
    if (buttonClassSet?.value === 'text-only-button') {
      return css.textOnlyButton
    }
    return buttonClassSet?.value || ''
  }
  public applyColors = () => {
    const { buttonClassSet } = this.props.data
    if (buttonClassSet && buttonClassSet.value) {
      return {}
    }
    const { buttonColor, buttonColorHover, buttonFontColor, buttonFontColorHover } = this.props.data
    const { isHover } = this.state
    return {
      backgroundColor: isHover ? buttonColorHover : buttonColor,
      color: isHover ? buttonFontColorHover : buttonFontColor,
    }
  }
  public applyMisc = () => {
    const { buttonClassSet } = this.props.data
    if (buttonClassSet && buttonClassSet.value) {
      return {}
    }
    const { border, borderRadius, boxShadow } = this.props.data
    const styles: any = {}
    if (border) {
      styles.border = border
    }
    if (borderRadius) {
      styles.borderRadius = borderRadius
    }
    if (boxShadow?.value) {
      styles.boxShadow = boxShadow.value
    }
    return styles
  }

  public getHrefData = () => {
    const clickActionValue: string | undefined | null = this.props?.data?.clickAction?.value
    if (clickActionValue === 'link' || !clickActionValue) {
      return {
        href: this.props.data.url || '#',
      }
    }

    if (clickActionValue === 'event' && this.props.data.eventTrigger) {
      if (PageContext?.PageLayoutEventEmitter?.emit) {
        return {
          onClick: () => {
            PageContext.PageLayoutEventEmitter.emit(this.props.data.eventTrigger)
          },
        }
      }
    }

    return {}
  }

  public render() {
    const { target, text, icon } = this.props.data
    if (this.props.contextProps.renderHooks?.renderCustomButton) {
      return this.props.contextProps.renderHooks.renderCustomButton(this.props.data)
    }
    return (
      <div className={`${css.buttonWrapper} ${this.getButtonClasses()} ${MERCE_CLASS_NAMES.BUTTON_WRAPPER}`}>
        <a
          className={`${MERCE_CLASS_NAMES.BUTTON_A_TAG}`}
          {...this.getHrefData()}
          target={target && target.value ? target.value : '_blank'}
        >
          <div
            style={{
              ...this.applyLayout(),
              ...this.applyColors(),
              ...this.applyMisc(),
            }}
            onMouseEnter={this.onHover}
            onMouseLeave={this.onHoverOut}
            className={`${MERCE_CLASS_NAMES.BUTTON} button ${css.button}`}
          >
            {icon && <i className={icon} style={{ marginRight: '10px' }} />}
            {text}
          </div>
        </a>
      </div>
    )
  }
}

export default {
  type: ElementType.BUTTON,
  component: IOmniElementButton,
}
