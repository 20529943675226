import * as React from 'react'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { has } from '../../../Common/Utils/lodash'
import { IProductGroup } from '../../../PDP'
import css from './ProductGroupCarousel.scss'

export interface ICarouselProps {
  title: string
  enableNavigation: boolean
  carouselElements: IProductGroup[]
  contextProps: IOmniPageSharedContextGlobalProps
  customComponentRefId: string | undefined
}
interface ICarouselState {
  currentSlide: number
  currentItemInGroup: number
  firstSlide: boolean
  lastSlide: boolean
  touchStart: number | null
  touchMove: number | null
  stepFromWindowWidth: number
}
export default class ProductGroupCarouselItem extends React.PureComponent<ICarouselProps, ICarouselState> {
  constructor(props: ICarouselProps) {
    super(props)
    this.state = {
      currentSlide: 0,
      currentItemInGroup: 0,
      firstSlide: true,
      lastSlide: false,
      touchStart: null,
      touchMove: null,
      stepFromWindowWidth: typeof window !== 'undefined' && window.innerWidth > 767 ? 3 : 0,
    }
    typeof window !== 'undefined' ? window.addEventListener('resize', () => this.handleResize()) : null
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.handleResize())
  }

  private goToSlide(index: number) {
    this.setState({ currentSlide: index })
  }

  private goToPrevSlide(e: React.FormEvent | null) {
    if (e) {
      e.preventDefault()
    }
    let { currentSlide } = this.state
    const { firstSlide, stepFromWindowWidth } = this.state
    const { carouselElements } = this.props
    if (firstSlide) {
      return
    }
    if (currentSlide < 1) {
      currentSlide = currentSlide + 1
    }
    --currentSlide
    this.setState({
      currentSlide: currentSlide,
      firstSlide: currentSlide === 0 ? true : false,
      lastSlide: currentSlide + stepFromWindowWidth === carouselElements.length ? true : false,
    })
  }

  private setProductInGroup = (productIndex: number) => {
    this.setState({ currentItemInGroup: productIndex })
  }

  private navigateToProduct = (e: React.FormEvent, index: number) => {
    const getURLToProduct = has(this.props, ['contextProps', 'events', 'getURLToProduct'])
    const goToProduct = has(this.props, ['contextProps', 'events', 'goToProduct'])
    if (!getURLToProduct && goToProduct && e) {
      e.preventDefault()
    }
    const { currentItemInGroup } = this.state
    if (goToProduct) {
      goToProduct(this.props.carouselElements[index]?.products?.[currentItemInGroup])
    }
  }

  private goToNextSlide(e: React.FormEvent | null) {
    if (e) {
      e.preventDefault()
    }
    let { currentSlide } = this.state
    const { lastSlide, stepFromWindowWidth } = this.state
    const { carouselElements } = this.props
    if (lastSlide || currentSlide + stepFromWindowWidth + 1 === carouselElements.length) {
      this.setState({ lastSlide: true })
      return
    }
    if (currentSlide + stepFromWindowWidth + 1 > carouselElements.length) {
      currentSlide -= 1
    }
    ++currentSlide
    this.setState({
      currentSlide: currentSlide,
      firstSlide: currentSlide === 0 ? true : false,
      lastSlide: currentSlide + stepFromWindowWidth + 1 === carouselElements.length ? true : false,
    })
  }

  private handleResize = () => {
    this.setState({ stepFromWindowWidth: typeof window !== 'undefined' && window.innerWidth > 767 ? 3 : 0 })
  }

  private handleTouchStart(e: any) {
    e.preventDefault()
    const { touches } = e

    if (touches.length === 1) {
      this.setState({ touchStart: touches[0].clientX })
    } else {
      this.setState({ touchStart: null })
    }
  }

  private handleTouchMove(e: any) {
    const { touches } = e

    if (touches.length === 1) {
      this.setState({ touchMove: touches[0].clientX })
    }
  }

  private handleTouchEnd(e: any, index: number) {
    e.preventDefault()
    const { touchMove, touchStart } = this.state

    if (!touchMove) {
      this.navigateToProduct(e, index)
    } else if (touchStart && Math.abs(touchStart - touchMove) > 100) {
      if (touchStart - touchMove < 0) {
        this.goToPrevSlide(null)
      } else {
        this.goToNextSlide(null)
      }
      this.setState({ touchMove: null, touchStart: null })
    } else {
      this.setState({ touchMove: null, touchStart: null })
    }
  }

  private renderCustomProductGroupThumbnail = (group: IProductGroup) => {
    const { contextProps, customComponentRefId } = this.props
    if (customComponentRefId && contextProps?.renderHooks?.[customComponentRefId]) {
      return contextProps.renderHooks[customComponentRefId]({ group, setProductInGroup: this.setProductInGroup })
    }
    return <div>Default View Not Yet Implemented...</div>
  }

  /// ----------------------------------------------- RENDER METHODS -----------------------------------------
  public render() {
    const { carouselElements } = this.props
    const { currentSlide, stepFromWindowWidth, firstSlide, lastSlide, currentItemInGroup } = this.state
    const getURLToProduct = has(this.props, ['contextProps', 'events', 'getURLToProduct'])
    const translate = {
      transform: `translateX(-${currentSlide * 100}%) translateX(-${
        stepFromWindowWidth === 0 ? 0 : currentSlide * 30
      }px)`,
    }
    if (!carouselElements) {
      return <div>Loading...</div>
    }
    return (
      <div className={css.carouselContainer}>
        <div className={css.carousel}>
          {stepFromWindowWidth + 1 < carouselElements.length && !firstSlide ? (
            <a href="#" aria-label="Left" className={css.carouselArrowLeft} onClick={e => this.goToPrevSlide(e)}>
              <i className="fas fa-chevron-left" />
            </a>
          ) : null}

          <ul className={css.carouselList}>
            {carouselElements.map((productGroup: IProductGroup, index: number) => (
              <li className={`${css.productDisplay} productSlide`} style={translate} key={index}>
                <div>
                  <a
                    onClick={e => this.navigateToProduct(e, index)}
                    className={css.productContainer}
                    onTouchStart={e => this.handleTouchStart(e)}
                    onTouchMove={e => this.handleTouchMove(e)}
                    onTouchEnd={e => this.handleTouchEnd(e, index)}
                    href={
                      getURLToProduct
                        ? getURLToProduct(this.props.carouselElements[index]?.products?.[currentItemInGroup])
                        : ''
                    }
                  >
                    <div>{this.renderCustomProductGroupThumbnail(productGroup)}</div>
                  </a>
                </div>
              </li>
            ))}
          </ul>
          {stepFromWindowWidth + 1 < carouselElements.length && !lastSlide ? (
            <a href="#" aria-label="Right" className={css.carouselArrowRight} onClick={e => this.goToNextSlide(e)}>
              <i className="fas fa-chevron-right" />
            </a>
          ) : null}
          <ul className={css.carouselIndicatorContainer}>
            {carouselElements.map((_slide, index) => (
              <li className={currentSlide === index ? css.carouselIndicatorActive : css.carouselIndicator} key={index}>
                <a onClick={() => this.goToSlide(index)} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}
