import * as React from 'react'
import { ElementType } from '../../interfaces'

export interface IOmniElementSeparator {
  width: string
  margin?: string
  color:
    | string
    | {
        value: string
        label: string
        color?: string
      }
}
export interface IProps {
  data: IOmniElementSeparator
}

const Separator = (props: IProps) => {
  const applyColorByStyle = () => {
    const { color } = props.data
    if (color) {
      if (typeof color === 'string') {
        return {
          borderTop: `1px solid ${color}`,
        }
      }
    }
    return {}
  }
  const applyCustomMargin = () => {
    const { margin } = props.data
    if (margin) {
      if (typeof margin === 'string') {
        return {
          margin: margin,
        }
      }
    }
    return {}
  }

  const applyColorByClass = () => {
    const { color } = props.data
    if (color !== undefined) {
      if (typeof color !== 'string') {
        if (color.value) {
          return color.value
        }
      }
    }
    return ''
  }
  return (
    <div className={applyColorByClass()}>
      <hr
        style={{
          ...applyCustomMargin(),
          ...applyColorByStyle(),
        }}
      />
    </div>
  )
}

export default {
  type: ElementType.SEPARATOR,
  component: Separator,
}
