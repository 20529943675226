// import { IOption } from '../../../../../../../Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import * as React from 'react'
import { IPluginRenderProps } from '../../FrontendPageComponentPluginsInterface'
import { generateClassName, generateStyleSheet } from '../../../Utils/classUtils'
import { isPropertyAllowed } from '../columnRestrictions'
import { STYLE_PROPERTY_NAME } from '../styleTypesEnumeration'
import appendImageTransformationProxyUrl from '../../ImageTransformation/imageTransformationUtils'
import css from './Parallax.scss'

/**
 * If the user wishes to have a background image with parallax effect
 * @param props
 */
const Parallax = (props: IPluginRenderProps) => {
  const { useBackgroundImage, parallax, backgroundImage } = props.componentData
  const allowed: boolean = isPropertyAllowed(props.componentData.type, STYLE_PROPERTY_NAME.USE_BACKGROUND_IMAGE)
  const formatBackgroundImage = (url: string) => {
    return appendImageTransformationProxyUrl({
      targetUrl: url as string,
      context: props.contextProps,
      data: props.componentData as any,
    })
  }
  if (allowed && useBackgroundImage && backgroundImage) {
    // const targetPadding: string = props.componentData.verticalPadding ? props.componentData.verticalPadding : '0px'
    if (useBackgroundImage.value) {
      const inlineStyle: any = {}
      const parallaxOuterClass = generateClassName(inlineStyle, `${props.componentData.id}-parallax-outer`, {
        backgroundImage: `url('${formatBackgroundImage(backgroundImage)}')`,
        backgroundSize: 'cover',
      })
      return (
        <React.Fragment>
          <props.contextProps.RenderHead>
            <style
              dangerouslySetInnerHTML={{
                __html: generateStyleSheet(inlineStyle),
              }}
            />
          </props.contextProps.RenderHead>
          {parallax?.value ? (
            <div className={`${css.masked} ${css.parallax} ${parallaxOuterClass} parallax-outer`}>
              <div className={`${css.opener} parallax-inner`}>{props.children}</div>
            </div>
          ) : (
            <div className={`${parallaxOuterClass} parallax-outer`}>
              <div className={`parallax-inner`}>{props.children}</div>
            </div>
          )}
        </React.Fragment>
      )
    }
  }
  return props.children
}

export default Parallax
