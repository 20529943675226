import * as React from 'react'
import { ElementType } from '../../interfaces'
import { generateClassName, generateStyleSheet } from '../../Utils/classUtils'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import { IImageTransformationProps } from '../../Plugins/ImageTransformation/imageTransformationInterface'
import appendImageTransformationProxyUrl from '../../Plugins/ImageTransformation/imageTransformationUtils'
import imageCss from './SingleImage.module.scss'

export interface IOmniComponentSingleImage extends IImageTransformationProps {
  id: string
  url: string
  targetUrl?: string
  caption: string
  richText: {
    html: string
    css: string
  }
  width: string
  height: string
  maxHeight: string
  alt: string
  imageClass: string
  css: string
  border: string
  useBackgroundImage:
    | {
        value: string
        label: any
      }
    | undefined
  imageAlignment:
    | {
        value: string
        label: any
      }
    | undefined
  backgroundImageSize:
    | {
        value: any
        label: any
      }
    | undefined
  backgroundPositionX:
    | {
        value: any
        label: any
      }
    | undefined
  backgroundPositionY:
    | {
        value: any
        label: any
      }
    | undefined
}
export interface IProps {
  data: IOmniComponentSingleImage
  contextProps: IOmniPageSharedContextGlobalProps
}

const OmniComponentSingleImage = (props: IProps) => {
  const {
    height,
    alt,
    width,
    imageClass,
    border,
    backgroundImageSize,
    backgroundPositionX,
    backgroundPositionY,
  } = props.data

  // ------------------------------------------ HEIGHT -----------------------------------------------------------------
  const targetHeight: string = height ? height : '250px'
  const targetWidth: string = width ? width : 'initial'
  const targetBorder: string = border ? border : 'none'
  // ------------------------------------------BACKGROUND POSITION ------------------------------------------------------
  const targetBackgroundPosition: {
    x: string
    y: string
  } = { x: 'left', y: 'top' }
  if (backgroundPositionX && backgroundPositionX.value) {
    targetBackgroundPosition.x = backgroundPositionX.value
  }
  if (backgroundPositionY && backgroundPositionY.value) {
    targetBackgroundPosition.y = backgroundPositionY.value
  }
  // ----------------------------------------- ALIGNMENT ---------------------------------------------------------------
  const targetAlignment: string = props.data.imageAlignment ? props.data.imageAlignment.value : 'left'

  // ---------------------------------------- GENERATE DYNAMIC CLASSES --------------------------------------------------
  const inlineStyle: any = {}
  const alignmentClassName = generateClassName(inlineStyle, `${props.data.id}-singleImageAlignment`, {
    textAlign: targetAlignment as any,
  })
  const formatBackgroundImage = (url: string) => {
    return appendImageTransformationProxyUrl({
      targetUrl: url,
      context: props.contextProps,
      data: props.data,
    })
  }
  const backgroundImageClassName = generateClassName(inlineStyle, `${props.data.id}-singleImage`, {
    height: targetHeight,
    border: targetBorder,
    backgroundImage: `url('${formatBackgroundImage(props.data.url)}')`,
    backgroundSize: backgroundImageSize ? backgroundImageSize.value : 'cover',
    backgroundPosition: `${targetBackgroundPosition.x} ${targetBackgroundPosition.y}`,
  })

  const imageClassName = generateClassName(inlineStyle, `${props.data.id}-image`, {
    border: targetBorder,
    width: targetWidth,
    maxHeight: props.data.maxHeight || 'none',
  })
  const ImageWrapper = (props: any) => {
    if (props.targetUrl) {
      return (
        <div className={props.className}>
          <a href={`${props.targetUrl}`}>{props.children}</a>
        </div>
      )
    } else {
      return <div className={props.className}>{props.children}</div>
    }
  }
  // ---------------------------------------------- RENDER ------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ImageWrapper
        className={`${imageCss.singleImage} ${alignmentClassName}`}
        targetUrl={`${props.data.targetUrl || ''}`}
      >
        <props.contextProps.RenderHead>
          <style
            dangerouslySetInnerHTML={{
              __html: generateStyleSheet(inlineStyle),
            }}
          />
        </props.contextProps.RenderHead>
        {props.data.useBackgroundImage && props.data.useBackgroundImage.value ? (
          <div
            className={`${backgroundImageClassName} ${imageCss.backgroundImage} ${imageClass}`}
            title={alt}
            role="img"
            aria-label={alt}
          />
        ) : (
          <img className={imageClassName} src={formatBackgroundImage(props.data.url)} alt={alt} title={alt} />
        )}
      </ImageWrapper>
    </React.Fragment>
  )
}

export default {
  type: ElementType.SINGLE_IMAGE,
  component: OmniComponentSingleImage,
}
