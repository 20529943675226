import * as React from 'react'
import { IImageTransformationProps } from '../../Plugins/ImageTransformation/imageTransformationInterface'
import { IOmniPageSharedContextGlobalProps } from '../../Shared.context'
import Slide from './Slide'
import css from './SlideShow.module.scss'

/// ------------------------------------------------ MISC INTERFACES -------------------------------------
export interface IOmniComponentSlideShowProps extends IImageTransformationProps {
  title: string
  interval: number
  enableNavigation: boolean
  slideshowelements: ISlideShowElement[]
  target?: { value: string }
}

export interface ISlideShowElement {
  id: string
  title: string
  alt: string
  imageUrl: string
  url: string
  hotmap: object | null
  customAttributes?: string
}
// ------------------------------------------------------ CLASS ---------------------------------------------
export interface ISlideShowProps {
  data: IOmniComponentSlideShowProps
  contextProps: IOmniPageSharedContextGlobalProps
}
interface ISlideShowState {
  currentSlide: number
}
class SlideShowDesktop extends React.PureComponent<ISlideShowProps, ISlideShowState> {
  public interval: any
  constructor(props: ISlideShowProps) {
    super(props)
    this.state = { currentSlide: 0 }
  }
  public componentDidMount() {
    const { interval } = this.props.data
    if (interval !== undefined && interval > 0) {
      this.interval = setInterval(this.incrementSlide, interval)
    }
  }
  // ----------------------------------------------  HELPER METHODS ------------------------------------------
  public incrementSlide = (by: number) => {
    const { data } = this.props
    let { currentSlide } = this.state
    const len = data.slideshowelements.length
    currentSlide += by !== undefined ? by : 1
    if (currentSlide < 0) {
      currentSlide = len - 1
    }
    if (currentSlide >= len) {
      currentSlide = 0
    }
    this.setState({ currentSlide })
  }
  public goToSlide = (index: number) => {
    this.setState({
      currentSlide: index,
    })
  }
  /// ----------------------------------------------- RENDER METHODS -----------------------------------------
  public render() {
    const { data } = this.props
    const { enableNavigation } = data
    if (!data) {
      return <div>Loading...</div>
    }
    const { currentSlide } = this.state
    return (
      <React.Fragment>
        <div className={css.slideContainer}>
          {data.slideshowelements.map((item: ISlideShowElement, index: number) => {
            return (
              <Slide
                key={index}
                item={item}
                index={index + 1}
                show={currentSlide === index}
                data={this.props.data}
                contextProps={this.props.contextProps}
              />
            )
          })}
          {data.slideshowelements.length > 1 && enableNavigation && (
            <React.Fragment>
              <a className={css.prev} onClick={this.incrementSlide.bind(this, -1)}>
                &#10094;
              </a>
              <a className={css.next} onClick={this.incrementSlide.bind(this, 1)}>
                &#10095;
              </a>
            </React.Fragment>
          )}
        </div>
        {data.slideshowelements.length > 1 && enableNavigation && (
          <React.Fragment>
            <div className={css.dotContainer}>
              {data.slideshowelements.map((_: ISlideShowElement, index: number) => {
                const activeClass = index === currentSlide ? css.active : ''
                return (
                  <span
                    key={index}
                    data-index={index}
                    className={`${css.dot} ${activeClass}`}
                    onClick={this.goToSlide.bind(this, index)}
                  />
                )
              })}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default SlideShowDesktop
