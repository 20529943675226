import stripTrailingSlash from '../../../Common/Utils/stripTrailingSlash'
import { IOmniPageSharedConfigurationProps } from '../../Shared.context'
import { IImageTransformationProps } from './imageTransformationInterface'

export interface IImageTransformationUrlArguments {
  targetUrl: string
  context: IOmniPageSharedConfigurationProps
  data: IImageTransformationProps
}

const appendImageTransformationProxyUrl = (props: IImageTransformationUrlArguments) => {

  /**
  * Gives the client the ability to add their own transformation hooks 
  * vs default to the RevCommerce transformation
  */
  if(props.context?.overrideImageTransformationUrlHook){
    return props.context.overrideImageTransformationUrlHook(props)
  }

  if (
    props.context?.imageTransformationProxyUrl &&
    (props.context.imageTransformationDefaultSettings || props.data.enableImageProxy?.value)
  ) {
    /**
     * We can pass in a global value that gets applied to everything
     */
    const globalQuality: string | undefined = props.context.imageTransformationDefaultSettings?.quality
    const globalDimensions: string | undefined = props.context.imageTransformationDefaultSettings?.dimensions
    const size: string = props.data.imageDimensions || globalDimensions || '0x0'
    const quality: string = props.data.imageQuality?.value || globalQuality || 'q80'
    const type: string = props.data.imageType?.value || '' //default to whatever the image was originally
    const rotation: number = props.data.imageRotation?.value || 0
    const safeProxyUrl: string = stripTrailingSlash(props.context?.imageTransformationProxyUrl)
    return `${safeProxyUrl}/${size},${quality},r${rotation},${type}/${props.targetUrl}`
  }
  return props.targetUrl
}

export default appendImageTransformationProxyUrl
