import * as React from 'react'
import { ElementType } from '../../../interfaces'
import { IOmniPageSharedContextGlobalProps } from '../../../Shared.context'
import { IImageTransformationProps } from '../../../Plugins/ImageTransformation/imageTransformationInterface'
import SlideShowMobile from './SlideShowMobile'
import SlideShowDesktop from './SlideShowDesktop'

/// ------------------------------------------------ MISC INTERFACES -------------------------------------
export interface IOmniComponentSlideShowProps extends IImageTransformationProps {
  title: string
  interval: number
  enableNavigation: boolean
  slideshowelements: ISlideShowElement[]
  target?: { value: string }
}

export interface ISlideShowElement {
  id: string
  title: string
  alt: string
  imageUrl: string
  url: string
  hotmap: object | null
  customAttributes?: string
}
// ------------------------------------------------------ CLASS ---------------------------------------------
export interface ISlideShowProps {
  data: IOmniComponentSlideShowProps
  contextProps: IOmniPageSharedContextGlobalProps
}

const SlideShow = (props: any) => {
  const [renderMobile, setRenderModile] = React.useState<boolean>(false)

  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  React.useEffect(() => {
    const device: string = deviceType()
    if (device !== 'desktop') {
      setRenderModile(true)
    }
  }, [])

  return (
    <React.Fragment>{renderMobile ? <SlideShowMobile {...props} /> : <SlideShowDesktop {...props} />}</React.Fragment>
  )
}

export default {
  type: ElementType.SLIDESHOW,
  component: SlideShow,
}
