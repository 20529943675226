import * as React from 'react'

import { ISlideShowProps } from './interfaces'
import Slide from './Slide'
import css from './SlideShow.module.scss'
import SlideShowDot from './SlideShowDot'

const SlideShow = ({
  interval,
  slides,
  enableNavigation,
  enableDotNavigation,
  maxItemsPerSlide = 1,
  enableNavigationCaption,
}: ISlideShowProps) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [shouldRemoveInterval, setShouldRemoveInterval] = React.useState(false)
  const [intervalID, setIntervalID] = React.useState<any>(0)
  /**
   * fixes the last slide that should be act as the `last slide`
   * Allows us to have multiple slides shown and disable the buttons to increase the current slide
   * and end up with 2 slides when we want to show 3 by 3, for example.
   */
  const maxCurrentSlide = slides.length - maxItemsPerSlide

  const incrementSlide = (event?: React.SyntheticEvent, by: number = 1) => {
    event?.preventDefault()
    const slidesNumber = slides.length

    let newCurrentSlide = currentSlide + by

    if (newCurrentSlide < 0) {
      newCurrentSlide = slidesNumber - 1
    }

    if (newCurrentSlide >= slidesNumber) {
      newCurrentSlide = 0
    }

    setShouldRemoveInterval(true)
    setCurrentSlide(newCurrentSlide)
  }

  const decrementSlide = (event: React.SyntheticEvent) => {
    incrementSlide(event, -1)
  }

  const handleSetCurrentSlide = (index: number) => {
    setCurrentSlide(index > maxCurrentSlide ? maxCurrentSlide : index)
  }

  React.useEffect(() => {
    if (!intervalID && interval !== undefined && interval > 0) {
      const myIntervalID = setInterval(incrementSlide, interval)
      setIntervalID(myIntervalID)
    }
  }, [intervalID])

  React.useEffect(() => {
    if (shouldRemoveInterval) {
      clearInterval(intervalID)
      setShouldRemoveInterval(false)
      setIntervalID(0)
    }
  }, [shouldRemoveInterval])

  if (!slides || !slides.length) {
    return <div>Loading...</div>
  }

  const slidesToShow: number[] = []
  for (let relativeSlideToShow = 0; relativeSlideToShow < maxItemsPerSlide; relativeSlideToShow++) {
    const nextSlide = currentSlide + relativeSlideToShow

    slidesToShow.push(nextSlide)
  }

  let navigationArrows = null
  let navigationDots = null
  let navigationCaption = null

  if (slides.length > 1) {
    const shouldDisableDecrement = currentSlide === 0
    let shouldDisableIncrement = false

    if (currentSlide >= maxCurrentSlide) {
      shouldDisableIncrement = true
    }
    if (enableNavigation) {
      navigationArrows = (
        <>
          <button
            className={css.prev}
            onClick={decrementSlide}
            disabled={shouldDisableDecrement}
            aria-label="Decrement slide"
          >
            &#10094;
          </button>
          <button
            className={css.next}
            onClick={incrementSlide}
            disabled={shouldDisableIncrement}
            aria-label="Increment slide"
          >
            &#10095;
          </button>
        </>
      )
    }

    if (enableDotNavigation) {
      navigationDots = (
        <>
          <div className={css.dotContainer}>
            {slides.map((_: any, index: number) => {
              const activeClass = slidesToShow.includes(index) ? css.active : ''
              const _className = `${css.dot} ${activeClass}`

              return (
                <SlideShowDot
                  key={`dot-${index}`}
                  index={index}
                  className={_className}
                  onClick={handleSetCurrentSlide}
                />
              )
            })}
          </div>
        </>
      )
    }

    if (enableNavigationCaption) {
      navigationCaption = (
        <div className={css.captionContainer}>
          <button
            className={css.captionPrev}
            onClick={decrementSlide}
            disabled={shouldDisableDecrement}
            aria-label="Decrement slide"
          >
            &#10094;
          </button>
          Slide {currentSlide + 1} of {slides.length}
          <button
            className={css.captionNext}
            onClick={incrementSlide}
            disabled={shouldDisableIncrement}
            aria-label="Increment slide"
          >
            &#10095;
          </button>
        </div>
      )
    }
  }

  let containerClassNames = css.slideShowContainer

  if (maxItemsPerSlide > 1) {
    containerClassNames = `${css.slideShowContainer} ${css.slideShowContainerMulti}`
  }

  return (
    <>
      <div className={containerClassNames}>
        {slides.map((customSlide: any, index: number) => (
          <Slide key={`slide-${index}`} show={slidesToShow.includes(index)}>
            {customSlide}
          </Slide>
        ))}

        {navigationArrows}
      </div>
      {navigationDots}
      {navigationCaption}
    </>
  )
}

export default SlideShow
